<template>
  <div>
    <table class="table">
      <tbody>
        <tr>
          <td>
            <strong># {{ $t('annotations') }}</strong>
          </td>
          <td>
            {{ annotations.length }}
          </td>
        </tr>

        <!-- TERMS -->
        <tr v-if="isPropDisplayed('terms') && ontology">
          <td colspan="2">
            <h5 class="mb-2 weight-6">
              {{ $t('terms') }}
            </h5>
            <div class="flex gap-4 flex-wrap mb-1">
              <BTag
                v-for="{ term, occurances } in selectedTerms"
                :key="term.id"
                :title="term.name + '(' + occurances + ')'"
                class="bg-gray-1"
              >
                <CytomineTerm :term="term" :occurances="occurances" />
                <button
                  v-if="canEditTerms"
                  :title="$t('delete')"
                  class="delete is-small"
                  @click="removeTerm(term.id)"
                />
              </BTag>
            </div>
            <div
              v-if="canEditTerms"
              v-click-outside="() => (showTermSelector = false)"
              class="relative"
            >
              <BField>
                <BInput
                  v-model="addTermString"
                  :placeholder="$t('add-term')"
                  size="is-small"
                  expanded
                  @focus="showTermSelector = true"
                />
              </BField>

              <div
                v-show="showTermSelector"
                class="
                  ontology-tree-container
                  absolute
                  z-100
                  top-full
                  w-full
                  overflow-auto
                  radius-4
                  bg-white
                "
              >
                <OntologyTree
                  :ontology="ontology"
                  :search-string="addTermString"
                  :selected-nodes="[]"
                  allow-new
                  class="ontology-tree"
                  @newTerm="newTerm"
                  @select="addTerm"
                  @unselect="removeTerm"
                />
              </div>
            </div>
            <em v-else-if="!selectedTerms.length">{{ $t('no-term') }}</em>
          </td>
        </tr>

        <!-- Tags hidden until scoped access per user per project -->
        <!-- <tr v-if="isPropDisplayed('tags')">
          <td colspan="2">
            <h5 class="mb-2 weight-6">
              {{ $t('tags') }}
            </h5>
            <CytomineTags :object="annotation" :can-edit="canEdit" />
          </td>
        </tr> -->
      </tbody>
    </table>

    <div class="flex gap-4 flex-wrap" style="padding-left:0.75em">
      <v-popover :trigger="!canDeleteAll ? 'hover' : 'none'">
        <IdxBtn
          small
          color="danger"
          :disabled="!canDeleteAll"
          @click="confirmDeletion"
        >
          <!-- TODO: Determine use for v-if="canEdit" -->
          {{ $t('delete-all') }}
        </IdxBtn>
        <template v-if="!canDeleteAll" #popover>
          <p>
            You may only delete annotations created by yourself, unless you are
            a project manager.
          </p>
        </template>
      </v-popover>

      <IdxBtn small @click="clearSelectedFeatures">
        {{ $t('clear-selected') }}
      </IdxBtn>
    </div>
  </div>
</template>

<script>
import copyToClipboard from 'copy-to-clipboard';
import noteApi, {
  bulkDeleteAnnotations,
  addTermToAnnotations,
  deleteTermFromAnnotations,
} from '../../services/noteApi.js';
import ImageName from '@/components/image/ImageName.vue';
import CytomineDescription from '@/components/description/CytomineDescription.vue';
import CytomineProperties from '@/components/property/CytomineProperties.vue';
import CytomineTags from '@/components/tag/CytomineTags.vue';
import CytomineTerm from '@/components/ontology/CytomineTerm.vue';
import AttachedFiles from '@/components/attached-file/AttachedFiles.vue';
import OntologyTree from '@/components/ontology/OntologyTree.vue';
import { Unique } from '@/utils/array-utils.js';

export default {
  name: 'AnnotationsDetails',
  components: {
    ImageName,
    CytomineDescription,
    CytomineTerm,
    OntologyTree,
    CytomineTags,
    CytomineProperties,
    AttachedFiles,
  },
  props: {
    index: { type: String, default: '' },
    annotations: { type: Array, required: true },
    allTerms: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
  },
  data() {
    return {
      addTermString: '',
      showTermSelector: false,
    };
  },
  computed: {
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    /** @returns {object} */
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    projectId() {
      return this.$store.state.currentProject.project.id;
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /**
     * @param annotation
     * @returns {boolean}
     */
    canEdit(annotation) {
      return this.$store.getters['currentProject/canEditAnnot'](annotation);
    },
    canDeleteAll() {
      return (
        this.isAdminsOrManagerOrRep ||
        this.annotations.some((annot) => annot.userId === this.currentUser.id)
      );
    },
    isAdminsOrManagerOrRep() {
      const memberRecord = this.users.find(
        (a) => a.id === this.currentUser.user.id
      );
      // Admin/Superadmin level user has visibility
      return (
        this.isAdmin ||
        (memberRecord &&
          (memberRecord.role === 'manager' ||
            memberRecord.role === 'representative'))
      );
    },
    isAdmin() {
      return this.currentUser.admin;
    },
    /** @returns {boolean} */
    canEditTerms() {
      // HACK: because core prevents from modifying term of algo annot (https://github.com/cytomine/Cytomine-core/issues/1138 & 1139)
      // + term modification forbidden for reviewed annotation
      // TODO: determine editting permissions
      return true;
      //return this.canEdit && this.annotation.type === AnnotationType.USER;
    },
    currentViewerPath() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    /** @returns {object[]} */
    selectedTerms() {
      let termIds = [];
      const occurancesDict = {};

      this.annotations.forEach((annot) => {
        if (
          annot &&
          annot.properties &&
          annot.properties.annot &&
          annot.properties.annot.term
        ) {
          termIds = termIds.concat(annot.properties.annot.term);
        }
      });

      // count how many time each term is found
      termIds.forEach((termId) => {
        if (!occurancesDict[termId]) occurancesDict[termId] = 1;
        else occurancesDict[termId]++;
      });

      termIds = Unique(termIds, false);
      const selectedTerms = termIds.map((termId) => {
        return {
          term: this.allTerms.find((term) => term.id.toString() === termId),
          occurances: occurancesDict[termId],
        };
      });
      return selectedTerms.filter((a) => a); // insure none are null
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    imageWrapper() {
      return this.viewerWrapper.images[this.index];
    },
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    activeTool() {
      return this.imageWrapper.draw.activeTool;
    },
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    noActiveLayer() {
      return !this.layers.find((layer) => layer.drawOn);
    },
  },
  // mounted() {
  //   this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  // },
  // beforeDestroy() {
  //   this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  // },
  methods: {
    // async iterateTerm(index) {
    //   const terms = this.allTerms;
    //   if (index < 0) {
    //     const lastTerm = terms[terms.length - 1];
    //     this.addTerm(lastTerm.id);
    //   } else if (index >= terms.length) {
    //     this.addTerm(terms[0].id);
    //   } else {
    //     this.addTerm(terms[index].id);
    //   }
    //   this.$notify({
    //     type: 'success',
    //     text: this.$t('term-changes-success'),
    //   });
    // },

    // TODO: Removes all terms? Do we want this?
    // async shortkeyHandler(key) {
    //   if (!this.isActiveImage) {
    //     // shortkey should only be applied to active map
    //     return;
    //   }
    //   if (!['tool-switch-terms-next', 'tool-switch-terms-prev'].includes(key)) {
    //     return;
    //   }
    //   if (this.noActiveLayer || this.activeTool !== 'select') {
    //     return;
    //   } else if (this.allTerms?.length == 0) {
    //     // Error message no terms available
    //     this.$notify({
    //       type: 'error',
    //       text: this.$t('terms-not-available'),
    //     });
    //   } else {
    //     const annot = this.annotation;
    //     const lastAssociatedTermId = this.selectedTermIds[
    //       this.selectedTermIds.length - 1
    //     ];
    //     // Get index of last associated term
    //     const termIndex = this.allTerms.findIndex(
    //       (term) => term.id === lastAssociatedTermId
    //     );
    //     let index = termIndex + 1;
    //     if (key === 'tool-switch-terms-prev') {
    //       index = termIndex - 1;
    //     }
    //     annot.term.forEach((termId) => this.removeTerm(termId));
    //     this.iterateTerm(index);
    //   }
    // },

    isPropDisplayed(prop) {
      return (
        this.isAdmin || this.configUI[`project-explore-annotation-${prop}`]
      );
    },

    setIsLoading(value) {
      this.$store.commit(this.currentViewerPath + 'setIsLoading', value);
    },

    copyURL() {
      copyToClipboard(window.location.origin + '/#' + this.annotationURL);
      this.$notify({
        type: 'success',
        text: this.$t('notif-success-annot-URL-copied'),
      });
    },

    async newTerm(term) {
      // a new term was added to the ontology
      this.$emit('addTerm', term);
      this.$store.dispatch('currentProject/fetchOntology');
      this.addTerm(term.id);
    },

    async addTerm(idTerm) {
      if (idTerm) {
        try {
          // TODO: handle algo terms
          this.setIsLoading(true);
          await addTermToAnnotations({
            projectId: this.projectId,
            termId: idTerm,
            annotationIds: this.annotations.map((a) => a.id),
          });
          this.$emit('updateTerm', idTerm);
          this.showTermSelector = false;
        } catch (error) {
          this.$notify({
            type: 'error',
            text: this.$t('notif-error-add-term'),
          });
        } finally {
          this.addTermString = '';
          this.setIsLoading(false);
        }
      }
    },
    /**
     * @param {number} termId
     */
    async removeTerm(termId) {
      try {
        // TODO: handle algo terms
        this.setIsLoading(true);

        await deleteTermFromAnnotations({
          projectId: this.projectId,
          termId: termId,
          annotationIds: this.annotations.map((a) => a.id),
        });
        this.$emit('deleteTerm', termId);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-remove-term'),
        });
      } finally {
        this.setIsLoading(false);
      }
    },

    confirmDeletion() {
      const self = this;
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-annotations'),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => self.deleteAnnots(),
      });
    },

    async deleteAnnots() {
      try {
        this.setIsLoading(true);

        const success = await bulkDeleteAnnotations({
          projectId: this.projectId,
          imageId: this.imageWrapper.imageInstance.id,
          annotationIds: this.annotations.map((annot) => annot.id),
          userIds: this.isAdminsOrManagerOrRep ? null : [this.currentUser.id],
        });
        if (success) {
          const annotIds = this.getDeletedAnnotations();
          this.$emit('deletion', annotIds);
          this.$notify({
            type: 'success',
            text: this.$t('notif-success-annotation-deletion'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notif-error-annotation-deletion'),
          });
        }
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-deletion'),
        });
      } finally {
        this.setIsLoading(false);
      }
    },

    // If user has permission to delete any annotation, return all annotation ids
    // Otherwise, return only the ids of the annotations that the user has permission to delete (own annotations)
    // Backend does not return these because there could potentially be hundreds of thousands of annotations
    getDeletedAnnotations() {
      if (this.isAdminsOrManagerOrRep) {
        return this.annotations.map((annot) => annot.id);
      } else {
        return this.annotations
          .filter((annot) => annot.userId === this.currentUser.id)
          .map((annot) => annot.id);
      }
    },

    clearSelectedFeatures() {
      this.$store.commit(this.imageModule + 'clearSelectedFeatures');
    },
  },
};
</script>

<style scoped>
.table th,
.table td {
  vertical-align: middle;
}

.ontology-tree-container {
  max-height: 30vh;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

>>> .sl-vue-tree-node-item {
  font-size: 0.9em;
}
</style>
