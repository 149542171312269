<template>
  <div
    ref="sidebar"
    :class="[
      'sidebar-wrapper flex color-white bg-gray-5 overflow-x-hidden',
      expanded ? 'w-256' : 'w-64',
      { expanded: expanded },
    ]"
  >
    <div class="w-256 flex flex-shrink-0">
      <nav
        :aria-label="$t('sidebar')"
        class="flex-grow flex flex-column"
        @click="clickHandler"
      >
        <h1
          :class="[
            'p-4 size-20 weight-6 overflow-break-word w-224',
            { 'color-transparent': !expanded },
          ]"
        >
          {{ `${$t('study')}: ${project.name}` }}
        </h1>
        <ul class="flex-grow">
          <li v-for="(item, index) in navItems" :key="index">
            <RouterLink
              :to="item.to"
              class="flex align-center h-48 color-inherit"
              active-class="is-active bg-gray-5"
            >
              <i :class="['w-64 size-20 text-center', item.iconClass]" />
              {{ item.text }}
            </RouterLink>
          </li>
        </ul>

        <ul>
          <li
            v-if="!!configUI[`project-configuration-tab`] || canManageProject"
          >
            <RouterLink
              :to="`/project/${project.id}/configuration`"
              class="flex align-center h-48 color-inherit"
              active-class="is-active bg-gray-5"
            >
              <i class="fas fa-cogs w-64 size-20 text-center" />
              {{ $t('configuration') }}
            </RouterLink>
          </li>
        </ul>
      </nav>

      <button
        class="grid place-center border-0 border-l-1 border-gray-4 p-1 size-16 color-inherit bg-transparent"
        @click="expanded = false"
      >
        <i class="fas fa-angle-left" />
        <span class="visually-hidden">{{ $t('sidebar-toggle') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectSidebar',
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    expanded: {
      /** @returns {boolean} */
      get() {
        return this.$store.state.currentUser.expandedSidebar;
      },
      set(value) {
        this.$store.commit('currentUser/setExpandedSidebar', value);
      },
    },
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {typeof allItems} */
    navItems() {
      const { project, configUI } = this;

      /**
       * @type {Array<{
       * configUiKey?: string
       * to: string
       * text: string | import('vue-i18n').TranslateResult
       * iconClass: string
       * }>}
       */
      const allItems = [
        {
          configUiKey: 'images',
          to: `/project/${project.id}/images`,
          text: this.$t('images'),
          iconClass: 'far fa-images',
        },
        {
          configUiKey: 'annotations',
          to: `/project/${project.id}/annotations`,
          text: this.$t('annotations'),
          iconClass: 'far fa-edit',
        },
        {
          configUiKey: 'jobs',
          to: `/project/${project.id}/analysis`,
          text: this.$t('analysis'),
          iconClass: 'fas fa-tasks',
        },
        {
          configUiKey: 'activities',
          to: `/project/${project.id}/activity`,
          text: this.$t('activity'),
          iconClass: 'fas fa-tachometer-alt',
        },
        {
          configUiKey: 'information',
          to: `/project/${project.id}/information`,
          text: this.$t('information'),
          iconClass: 'fas fa-info-circle',
        },
        {
          configUiKey: 'results',
          to: `/project/${project.id}/results`,
          text: this.$t('results'),
          iconClass: 'fas fa-file-invoice',
        },
        {
          configUiKey: 'visualizations',
          to: `/project/${project.id}/visualizations`,
          text: this.$t('visualizations'),
          iconClass: 'fas fa-chart-bar',
        },
      ];

      const filteredItems = this.currentUser.admin
        ? allItems
        : allItems.filter((item) => {
            return !!configUI[`project-${item.configUiKey}-tab`];
          });

      return filteredItems;
    },
  },
  mounted() {
    this.$refs.sidebar.addEventListener(
      'transitionend',
      this.transitionEndHandler
    );
  },
  beforeDestroy() {
    this.$refs.sidebar.removeEventListener(
      'transitionend',
      this.transitionEndHandler
    );
  },
  methods: {
    clickHandler(event) {
      const el = event.target;
      if (!this.expanded && el.tagName !== 'I' && el.tagName !== 'A') {
        // if user clicked on icon, navigation purpose => do not expand the nav bar
        this.expanded = true;
      }
    },
    transitionEndHandler() {
      // led to a change of the size of the content div => need to reload OL map
      this.$eventBus.$emit('updateMapSize');
    },
  },
};
</script>

<style lang="scss" scoped>
$hoverBackground: #82aad8;

.sidebar-wrapper {
  transition: width 0.2s linear;
}

li a:hover {
  color: #fff;
  background: $hoverBackground !important;
}

a.is-active {
  box-shadow: inset 5px 0 0 $hoverBackground;
}
</style>
