<template>
  <div v-if="permissionError || notFoundError" class="box error">
    <h1 class="mb-3">
      {{ $t(permissionError ? 'access-denied' : 'not-found') }}
    </h1>
    <p>
      {{ $t(permissionError ? 'insufficient-permission' : 'not-found-error') }}
    </p>
  </div>
  <div v-else class="grid h-full" style="grid-template-columns: auto 1fr">
    <ProjectSidebar v-if="!loading" :key="idProject" />

    <div id="content-container" class="overflow-auto">
      <BLoading v-if="loading" :is-full-page="false" active />
      <RouterView v-else />
    </div>
  </div>
</template>

<script>
import ProjectSidebar from './ProjectSidebar.vue';
import projectModuleModel from '@/store/modules/project';

export default {
  name: 'CytomineProject',
  components: { ProjectSidebar },
  props: {
    idProject: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      permissionError: false,
      notFoundError: false,
    };
  },
  computed: {
    /** @returns {object} */
    projectModule() {
      return this.$store.state.projects[this.idProject];
    },
  },
  watch: {
    async idProject() {
      this.loading = true;
      await this.loadProject();
    },
    projectModule() {
      if (!this.projectModule) {
        console.log('Project closed from external source');
        this.$store.commit('currentProject/resetState');
        this.$router.push('/projects');
      }
    },
  },
  async created() {
    await this.loadProject();
  },
  methods: {
    async loadProject() {
      try {
        if (!this.$store.state.projects[this.idProject]) {
          // module does not exist yet
          this.$store.registerModule(
            ['projects', this.idProject],
            projectModuleModel
          );
        }
        await this.$store.dispatch(
          'currentProject/loadProject',
          this.idProject
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 403) {
          this.permissionError = true;
        } else {
          this.notFoundError = true;
        }
      }
    },
  },
};
</script>
