<template>
  <div class="project-softwares-wrapper">
    <BLoading :is-full-page="false" :active="loading" />
    <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>
    <template v-else-if="!loading">
      <div class="flex">
        <BInput
          v-model="searchString"
          :placeholder="$t('search')"
          class="search-field"
          type="search"
          icon="search"
          @input="debounceSearchString"
        />
      </div>

      <IdxTable
        :await="request"
        :data="algorithms"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :sort-by.sync="sortBy"
        :sort-direction.sync="sortDirection"
        :total="total"
      >
        <template #default>
          <BTableColumn
            v-slot="props"
            :label="$t('name')"
            field="name"
            sortable
            width="100"
          >
            <a
              :href="`${baseUrl}/napi/algorithm/${props.row.id}/download`"
              target="_blank"
            >
              {{ props.row.name }}
            </a>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('version')"
            :custom-sort="sortByAlgorithmStatus"
            field="version"
            centered
            sortable
            width="100"
          >
            <SoftwareStatus :software="props.row" />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('status')"
            :custom-sort="sortByAlgorithmSelected"
            field="selected"
            sortable
            width="100"
          >
            <BDropdown v-model="activeIds" multiple trap-focus>
              <template #trigger="{ active }">
                <BButton
                  :label="
                    $t(props.row.selected ? 'enabled' : 'disabled') +
                      (getVersionsSelected(props.row) > 0
                        ? ' (' + getVersionsSelected(props.row) + ')'
                        : '')
                  "
                  :type="props.row.selected ? 'is-success' : 'is-danger'"
                  :icon-right="active ? 'angle-up' : 'angle-down'"
                />
              </template>

              <BDropdownItem
                v-for="version in props.row.versions"
                :key="version.id"
                :value="version.id"
                @click="toggleSoftware(version)"
              >
                <BCheckbox
                  v-model="activeIds"
                  :native-value="version.id"
                  size="is-small"
                  :name="version.name"
                  disabled="disabled"
                  style="cursor:pointer;vertical-align:bottom;padding-bottom:0.2rem;"
                  :checked="activeIds.includes(version.id)"
                />
                <span>v{{ version.fileVersion }}</span>
              </BDropdownItem>
            </BDropdown>
          </BTableColumn>
        </template>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-algorithm') }}</p>
          </div>
        </template>

        <template #bottom-left>
          <BSelect v-model="perPage" size="is-small">
            <option value="10">
              {{ $t('count-per-page', { count: 10 }) }}
            </option>
            <option value="25">
              {{ $t('count-per-page', { count: 25 }) }}
            </option>
            <option value="50">
              {{ $t('count-per-page', { count: 50 }) }}
            </option>
            <option value="100">
              {{ $t('count-per-page', { count: 100 }) }}
            </option>
          </BSelect>
        </template>
      </IdxTable>
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce.js';
import SoftwareStatus from '../../software/SoftwareStatus.vue';
import IdxTable, { DEFAULTS } from '../../utils/IdxTable.vue';
import noteApi from '../../../services/noteApi.js';
import constants from '@/utils/constants.js';

export default {
  name: 'ProjectSoftwares',
  components: {
    SoftwareStatus,
    IdxTable,
  },
  data: () => ({
    loading: true,
    error: false,
    request: null,
    baseUrl: constants.CYTOMINE_CORE_HOST,
    searchString: '',
    sortBy: 'created',
    sortDirection: 'desc',
    perPage: DEFAULTS.perPage,
    currentPage: 1,
    total: 0,

    allAlgorithms: [],
    assaysProp: null,
    /** @type {Array<{id: number, created: Date, deleted: Date, assayId: number, projectId: number}>} */
    activeAssays: [],
    activeIds: [],
  }),
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },

    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },

    /** @returns {Assay[]} */
    algorithms() {
      const { sortBy, sortDirection } = this;
      const algorithms = this.allAlgorithms;

      const sortDir = sortDirection === 'asc' ? 1 : -1;

      return algorithms.sort((a, b) => {
        if (a[sortBy] === b[sortBy]) return 0;

        const order = a[sortBy] > b[sortBy] ? 1 : -1;
        return order * sortDir;
      });
    },
  },

  watch: {
    currentPage: {
      handler() {
        this.fetchAlgorithms();
      },
    },
  },

  async created() {
    try {
      // @ts-ignore
      this.activeAssays = await noteApi.get(
        `napi/project/${this.project.id}/algorithm`
      );
      this.activeIds = this.activeAssays.map((assay) => assay.assayId);

      this.fetchAlgorithms();
    } catch (error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  },

  methods: {
    debounceSearchString: debounce(async function(value) {
      this.searchString = value;
      // @ts-ignore
      this.fetchAlgorithms();
    }, 500),

    fetchAlgorithms() {
      this.request = async () => {
        try {
          /** @type {{results: Array, total: number}} */
          // @ts-ignore
          const results = await noteApi.get('napi/algorithm', {
            query: {
              page: this.currentPage - 1,
              perPage: this.perPage,
              sortBy: this.sortBy,
              sortDirection: this.sortDirection,
              name: this.searchString,
              activeIds: this.activeIds,
            },
          });
          this.allAlgorithms = results.results;
          this.total = results.total;

          if ((this.currentPage - 1) * this.perPage >= this.total) {
            this.currentPage = 1;
          }

          for (const algorithm of this.allAlgorithms) {
            algorithm.selected = this.activeIds.some((v) =>
              algorithm.versions.map((a) => a.id).includes(v)
            );
          }
        } catch (error) {
          this.$notify({
            type: 'error',
            text: this.$t('algorithms-fetch-fail'),
          });
          console.log(error);
        }
      };
      // Need to initialize the request first
      // so that when the table is rendered, the request can be called
      // reasons unknown
      this.request();
    },

    async toggleSoftware(algorithm) {
      try {
        // activeIds are updated before this function is called
        if (this.activeIds.includes(algorithm.id)) {
          await noteApi.post(
            `napi/project/${this.project.id}/algorithm/${algorithm.id}`
          );
        } else {
          await noteApi.delete(
            `/napi/project/${this.project.id}/algorithm/${algorithm.id}`
          );
        }

        this.$notify({
          type: 'success',
          text: this.$t('algorithm-status-update-success'),
        });

        if (!this.activeIds.length) {
          this.sortBy = 'name';
        }
        this.fetchAlgorithms();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('algorithm-status-toggle-error'),
        });
      }
    },
    sortByAlgorithmStatus(a, b, asc) {
      return (
        (asc ? 1 : -1) *
        (this.getAlgorithmStatusValue(a) - this.getAlgorithmStatusValue(b))
      );
    },
    getAlgorithmStatusValue(algorithm) {
      if (!algorithm.softwareVersion) return 0;
      if (algorithm.deprecated) return 1;
      return 2;
    },
    sortByAlgorithmSelected(a, b, asc) {
      return (
        (asc ? 1 : -1) *
        (this.getAlgorithmSelectedValue(a) - this.getAlgorithmSelectedValue(b))
      );
    },
    getAlgorithmSelectedValue(algorithm) {
      if (!this.activeIds.includes(algorithm.id)) return 0;
      return 1;
    },
    getVersionsSelected(algorithm) {
      return algorithm.versions.filter((a) => this.activeIds.includes(a.id))
        .length;
    },
  },
};
</script>

<style>
.project-softwares-wrapper .b-table .table-wrapper {
  min-height: 50vh;
}

.project-softwares-wrapper .search-field {
  max-width: 25em;
}

.project-softwares-wrapper .table {
  margin-top: 1.5em;
}

.project-softwares-wrapper .table .button {
  padding: 0 3em;
}
</style>
