<template>
  <div v-if="error" class="box error">
    <h2 class="mb-3">
      {{ $t('error') }}
    </h2>
    <p>{{ $t('error-loading-image') }}</p>
  </div>
  <div v-else class="h-full">
    <b-loading :is-full-page="false" :active="loading" />
    <div v-if="!loading" class="h-full flex flex-wrap relative overflow-hidden">
      <div
        v-for="(cell, i) in cells"
        :key="i"
        :style="`height:${elementHeight}%; width:${elementWidth}%;`"
        class="map-cell overflow-hidden"
      >
        <CytomineImage
          v-if="cell && cell.image"
          :key="`${cell.index}-${cell.image.id}`"
          :index="cell.index"
          :images="images"
          @close="closeMap(cell.index)"
        />
      </div>

      <ImageSelector :images="images" />

      <!-- Emit event when a hotkey is pressed (to rework once https://github.com/iFgR/vue-shortkey/issues/78 is implemented) -->
      <div
        v-shortkey.once="shortkeysMapping"
        class="display-none"
        @shortkey="shortkeyEvent"
      />
    </div>
  </div>
</template>

<script>
import { ImageInstanceCollection, ImageInstance } from 'cytomine-client';
import ImageSelector from './ImageSelector';
import CytomineImage from './CytomineImage';

import viewerModuleModel from '@/store/modules/project_modules/viewer';

import shortcuts from '@/utils/shortcuts.js';

export default {
  name: 'CytomineViewer',
  components: {
    CytomineImage,
    ImageSelector,
  },
  data() {
    return {
      error: false,
      loading: true,
      reloadInterval: null,
      idViewer: null,
      images: [],
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    viewers() {
      return this.$store.state.projects[this.project.id].viewers;
    },
    idImages() {
      return this.$route.params.idImages.split('-');
    },
    paramIdViewer() {
      return this.$route.query.viewer;
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    viewer() {
      return this.viewers[this.idViewer];
    },
    indexImages() {
      return this.viewer ? Object.keys(this.viewer.images) : [];
    },
    nbImages() {
      return this.indexImages.length;
    },
    nbHorizontalCells() {
      return Math.ceil(Math.sqrt(this.nbImages));
    },
    nbVerticalCells() {
      return this.nbHorizontalCells
        ? Math.ceil(this.nbImages / this.nbHorizontalCells)
        : 0;
    },
    cells() {
      const cells = new Array(this.nbHorizontalCells * this.nbVerticalCells);
      for (let i = 0; i < this.nbImages; i++) {
        const index = this.indexImages[i];
        const image = this.viewer.images[index].imageInstance;
        cells[i] = {
          index,
          image,
        };
      }
      return cells;
    },
    elementHeight() {
      return 100 / this.nbVerticalCells;
    },
    elementWidth() {
      return 100 / this.nbHorizontalCells;
    },
    shortkeysMapping() {
      // prettier-ignore
      const allowed = [
        'nav-next-image',
        'nav-previous-image',
        'nav-next-slice',
        'nav-previous-slice',
        'nav-next-t',
        'nav-previous-t',
        'nav-next-c',
        'nav-previous-c',
        'nav-first-slice',
        'nav-last-slice',
        'nav-first-t',
        'nav-last-t',
        'nav-first-z',
        'nav-last-z',
        'nav-first-c',
        'nav-last-c',
        'tool-select',
        'tool-terms-next',
        'tool-terms-previous',
        'tool-switch-terms-next',
        'tool-switch-terms-prev',
        'tool-toggle-terms-showing',
        'tool-brush',
        'tool-brush-smaller',
        'tool-brush-bigger',
        'tool-point',
        'tool-line',
        'tool-freehand-line',
        'tool-rectangle',
        'tool-circle',
        'tool-polygon',
        'tool-freehand-polygon',
        'tool-fill',
        'tool-correct-add',
        'tool-correct-remove',
        'tool-modify',
        'tool-rescale',
        'tool-move',
        'tool-rotate',
        'tool-delete',
        'tool-undo',
        'tool-redo',
        'tool-review-accept',
        'tool-review-reject',
        'tool-review-toggle',
        'tool-go-to-slice-t',
        'tool-go-to-slice-z',
        'tool-go-to-slice-c',
        'toggle-information',
        'toggle-zoom',
        'toggle-filters',
        'toggle-channels',
        'toggle-layers',
        'toggle-ontology',
        'toggle-properties',
        // 'toggle-broadcast',
        'toggle-review',
        'toggle-overview',
        'toggle-annotations',
        'toggle-current',
        'toggle-add-image',
        'toggle-link',
        'nav-next-z',
        'nav-previous-z'
      ];

      return Object.keys(shortcuts)
        .filter((key) => allowed.includes(key.replace('viewer-', '')))
        .reduce((object, key) => {
          object[key.replace('viewer-', '')] = shortcuts[key];
          return object;
        }, {});
    },
  },
  watch: {
    paramIdViewer() {
      this.findIdViewer();
    },
    idViewer(_, old) {
      if (old) {
        this.loading = true;
        this.loadViewer();
      }
    },
    viewer() {
      if (!this.viewer) {
        console.log('Viewer closed from external source');
        this.$router.push(`/project/${this.$route.params.idProject}`);
      }
    },
    nbImages() {
      this.$eventBus.$emit('updateMapSize');
    },
  },
  async created() {
    this.findIdViewer();
    await this.loadViewer();
    // this.reloadInterval = setInterval(
    //   () => this.$eventBus.$emit('reloadAnnotations'),
    //   constants.VIEWER_ANNOTATIONS_REFRESH_INTERVAL
    // );
  },
  mounted() {
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  },
  beforeDestroy() {
    clearInterval(this.reloadInterval);
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    findIdViewer() {
      if (this.paramIdViewer) {
        this.idViewer = this.paramIdViewer;
        return;
      }

      for (const id in this.viewers) {
        // if viewer containing the targetted images, and only them, store its id
        const imagesViewer = Object.values(this.viewers[id].images)
          .map((img) => img.imageInstance.id)
          .join('-');
        if (imagesViewer === this.$route.params.idImages) {
          this.idViewer = id;
          return;
        }
      }

      this.idViewer = Math.random()
        .toString(36)
        .substr(2, 9);
    },

    closeMap(index) {
      if (this.nbImages === 1) {
        this.$store.unregisterModule([
          'projects',
          this.project.id,
          'viewers',
          this.idViewer,
        ]);
        this.$router.push(`/project/${this.$route.params.idProject}`);
      } else {
        this.$store.dispatch(this.viewerModule + 'removeImage', index);
      }
    },

    async loadViewer() {
      try {
        this.$store.commit('currentProject/setCurrentViewer', this.idViewer);
        if (!this.viewer) {
          this.$store.registerModule(
            ['projects', this.project.id, 'viewers', this.idViewer],
            viewerModuleModel
          );

          const images = {};
          // don't fetch multiple times the same image.
          const idImages = [...new Set(this.idImages)];
          await Promise.all(
            idImages.map(async (id) => {
              const image = await ImageInstance.fetch(id);
              images[id] = image;
            })
          );

          this.idImages.forEach(async (id) => {
            await this.$store.dispatch(
              this.viewerModule + 'addImage',
              images[id]
            );
          });
        } else {
          await this.$store.dispatch(this.viewerModule + 'refreshData');
        }

        // get list of images for additional viewer component + getting # of images
        this.images = (
          await ImageInstanceCollection.fetchAll({
            filterKey: 'project',
            filterValue: this.project.id,
          })
        ).array;

        this.loading = false;
      } catch (err) {
        console.log(err);
        this.error = true;
      }
    },

    shortkeyEvent(event) {
      this.$eventBus.$emit('shortkeyEvent', event.srcKey);
    },
    shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }

      if (key === 'nav-next-image') {
        this.nextImage();
      } else if (key === 'nav-previous-image') {
        this.previousImage();
      }
    },
  },
};
</script>

<style scoped>
.map-cell {
  border-top: 0.2em solid #6d6d6d;
}
</style>
