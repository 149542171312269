import { AnnotationTermCollection, AnnotationType } from 'cytomine-client';

/** Enum providing the actions that can be performed on annotations */
export const Action = Object.freeze({
  SELECT: 'select',
  CREATE: 'create',
  BULK_CREATE: 'bulk-create',
  UPDATE: 'update',
  DELETE: 'delete',
  DELETE_ALL: 'delete-all',
});

/**
 * Fetch the terms associated to the provided annot, and populate term and userByTerm properties accordingly
 *
 * @param {object} annot The annotation to update
 */
export async function updateTermProperties(annot) {
  const annotTerms = await AnnotationTermCollection.fetchAll({
    filterKey: 'annotation',
    filterValue: annot.id,
  });
  annot.term = [];
  annot.userByTerm = [];
  const mapping = {};
  annotTerms.array.forEach(({ term, user }) => {
    if (!annot.term.includes(term)) {
      mapping[term] = annot.term.length;
      annot.term.push(term);
      annot.userByTerm.push({
        term,
        user: [user],
      });
    } else {
      // this term was already treated => add user to existing userByTerm object related to the term
      annot.userByTerm[mapping[term]].user.push(user);
    }
  });
}

/**
 * Checks whether an annotation belongs to the provided layer and image
 *
 * @param {object} annot The annotation
 * @param {object} layer The layer
 * @param {object} [image] The image
 * @returns {boolean} whether or not the annotation belongs to the provided layer and image
 */
export function annotBelongsToLayer(annot, layer, image = null) {
  if (image && annot.image !== image.id) {
    return false;
  }
  const isReviewed = annot.type === AnnotationType.REVIEWED;
  return layer.isReview ? isReviewed : !isReviewed && annot.user === layer.id;
}
