<template>
  <div class="tile is-child box last-actions">
    <h2 class="mb-3">
      {{ $t('activity-logs') }}
    </h2>

    <BSelect v-model="selectedUser" class="mb-4">
      <option :value="null">
        {{ $t('all-users-analyses') }}
      </option>
      <optgroup :label="$t('members')">
        <option v-for="member in members" :key="member.id" :value="member.id">
          {{ member.fullName }}
        </option>
      </optgroup>
      <optgroup :label="$t('analyses')">
        <option v-for="uJob in userJobs" :key="uJob.id" :value="uJob.id">
          {{ uJob.fullName }}
        </option>
      </optgroup>
    </BSelect>

    <ActivityLogs
      :id-user="selectedUser"
      :start-date="startDate"
      :end-date="endDate"
      :project="project"
    />
  </div>
</template>

<script>
import { UserJobCollection } from 'cytomine-client';

import { fullName } from '@/utils/user-utils.js';

import ActivityLogs from '@/components/utils/ActivityLogs.vue';

export default {
  name: 'ProjectActivityLogs',
  components: { ActivityLogs },
  props: {
    startDate: Number,
    endDate: Number,
  },
  data() {
    return {
      userJobs: [],
      selectedUser: null,
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    members() {
      return this.$store.state.currentProject.members;
    },
  },
  async created() {
    try {
      const userJobs = (
        await UserJobCollection.fetchAll({
          filterKey: 'project',
          filterValue: this.project.id,
        })
      ).array;
      userJobs.forEach((uJob) => (uJob.fullName = fullName(uJob)));
      this.userJobs = userJobs;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.last-actions {
  height: 100%;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}
</style>
