<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path
      stroke="#000"
      d="M1 8l4 5c3-1 1-9 4-10 2 0 4 5 4 5"
      fill="none"
      stroke-width="1.4"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLineFreeHand',
};
</script>
