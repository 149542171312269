<template>
  <span>
    <div
      v-if="term.color"
      :style="{ background: term.color }"
      class="color-preview relative inline-block mr-1 radius-4"
    />
    {{ term.name }} {{ occurances ? `(${occurances})` : '' }}
  </span>
</template>

<script>
export default {
  name: 'CytomineTerm',
  props: {
    term: {
      type: Object,
      required: true,
    },
    occurances: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style scoped>
.color-preview {
  width: 1em;
  height: 1em;
  box-shadow: 0 0 1px #777;
  top: 0.2em;
}
</style>
