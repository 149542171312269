<template>
  <div v-if="!configUI['project-information-tab']" class="box error">
    <h2 class="mb-3">
      {{ $t('access-denied') }}
    </h2>
    <p>{{ $t('insufficient-permission') }}</p>
  </div>
  <div v-else class="content-wrapper">
    <div class="box">
      <ProjectDetails
        :project="project"
        :excluded-properties="['imagesPreview']"
        editable
        @update="updateProject"
        @delete="deleteProject()"
      />
    </div>
  </div>
</template>

<script>
import { Project } from 'cytomine-client';
import ProjectDetails from './ProjectDetails.vue';

export default {
  name: 'ProjectInformation',
  components: { ProjectDetails },
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
  },
  methods: {
    updateProject(updatedProject) {
      this.$store.dispatch('currentProject/updateProject', updatedProject);
    },
    async deleteProject() {
      try {
        await Project.delete(this.project.id);
        this.$notify({
          type: 'success',
          text: this.$t('project-deletion-notif-success', {
            projectName: this.project.name,
          }),
        });
        this.$router.push('/projects');
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-deletion-notif-error', {
            projectName: this.project.name,
          }),
        });
      }
    },
  },
};
</script>
