<template>
  <div
    v-if="!configUI['project-configuration-tab'] && !canManageProject"
    class="box error"
  >
    <h2 class="mb-3">
      {{ $t('access-denied') }}
    </h2>
    <p>{{ $t('insufficient-permission') }}</p>
  </div>
  <div v-else class="content-wrapper">
    <p v-if="!currentUser.adminByNow" class="panel-heading">
      {{ $t('members') }}
    </p>
    <BField v-else class="radio-buttons-fullwidth">
      <BRadioButton v-model="activeTab" native-value="general" type="is-link">
        {{ $t('general') }}
      </BRadioButton>

      <BRadioButton v-model="activeTab" native-value="members" type="is-link">
        {{ $t('members') }}
      </BRadioButton>

      <BRadioButton v-model="activeTab" native-value="customUI" type="is-link">
        {{ $t('custom-ui') }}
      </BRadioButton>

      <BRadioButton
        v-if="currentUser.adminByNow"
        v-model="activeTab"
        native-value="digital-assays"
        type="is-link"
      >
        {{ $t('digital-assays') }}
      </BRadioButton>

      <BRadioButton
        v-model="activeTab"
        native-value="imageFilters"
        type="is-link"
      >
        {{ $t('image-filters') }}
      </BRadioButton>
    </BField>

    <div class="box">
      <KeepAlive>
        <component :is="activeComponent" />
      </KeepAlive>
    </div>
  </div>
</template>

<script>
import GeneralConfiguration from './configuration-panels/GeneralConfiguration.vue';
import ProjectMembers from './configuration-panels/ProjectMembers.vue';
import CustomUIProject from './configuration-panels/CustomUIProject.vue';
import ProjectSoftwares from './configuration-panels/ProjectSoftwares.vue';
import ProjectImageFilters from './configuration-panels/ProjectImageFilters.vue';

const defaultTab = 'members';

export default {
  name: 'ProjectConfiguration',
  data() {
    return {
      activeTab: defaultTab,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    queriedTab() {
      return this.$route.query.tab;
    },
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    activeComponent() {
      switch (this.activeTab) {
        case 'general':
          return GeneralConfiguration;
        case 'members':
          return ProjectMembers;
        case 'customUI':
          return CustomUIProject;
        case 'digital-assays':
          return ProjectSoftwares;
        case 'imageFilters':
          return ProjectImageFilters;
        default:
          return ProjectMembers;
      }
    },
  },
  watch: {
    queriedTab() {
      this.changeTab();
    },
    activeTab() {
      if (this.activeTab !== defaultTab || this.queriedTab) {
        this.$router.push(`?tab=${this.activeTab}`);
      }
    },
  },
  async created() {
    this.changeTab();
  },
  methods: {
    changeTab() {
      let { tab } = this.$route.query;
      tab = Array.isArray(tab) ? tab[0] : tab;
      this.activeTab = tab || defaultTab;
      if (!this.activeComponent) {
        this.activeTab = defaultTab;
      }
    },
  },
};
</script>
