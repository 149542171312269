<template>
  <div class="content-wrapper">
    <VAsync :await="request">
      <template #default="{ pending }">
        <div class="panel">
          <div
            class="flex justify-between align-center radius-t-8 p-4 bg-gray-1"
          >
            <p class="m-0 size-20">
              {{ $t('results') }}
            </p>
            <IdxBtn :disabled="!data.length" @click="downloadAsCSV">
              {{ $t('download') }}
              CSV
            </IdxBtn>
          </div>
          <div class="py-2 px-4">
            <BTable
              ref="projectTable"
              :loading="pending"
              :data="data"
              :per-page="perPage"
              paginated
              pagination-size="is-small"
              sticky-header
              @sort="$refs.projectTable.newCurrentPage = 1"
            >
              <template v-for="column in dataLabels">
                <BTableColumn
                  :key="column.field"
                  v-slot="props"
                  :field="column.field"
                  :label="column.label"
                  :sortable="column.sortable"
                  :custom-sort="column.customSort"
                >
                  <template v-if="column.field === 'instanceFilename'">
                    <RouterLink
                      target="_blank"
                      :to="`/project/${project.id}/image/${props.row.id}`"
                    >
                      {{ props.row[column.field] }}
                    </RouterLink>
                  </template>
                  <template v-else>
                    {{ props.row[column.field] }}
                  </template>
                </BTableColumn>
              </template>
              <template #bottom-left>
                <BSelect v-model="perPage" size="is-small">
                  <option
                    v-for="option in perPageOptions"
                    :key="option"
                    :value="option"
                  >
                    {{ $t('count-per-page', { count: option }) }}
                  </option>
                </BSelect>
              </template>
            </BTable>
          </div>
        </div>
      </template>
    </VAsync>
  </div>
</template>

<script>
import noteApi from '../../services/noteApi.js';
import download from '../../utils/download.js';

export default {
  props: {
    idProject: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    request: null,
    data: [],
    dataLabels: [],
    datetimeTracker: new Map(),
    perPage: 25,
    perPageOptions: [10, 25, 50, 100],
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
  },
  mounted() {
    this.request = async () => {
      const { results } = await noteApi.get(
        `/napi/project/${this.idProject}/image`,
        {
          query: {
            properties: true,
            perPage: -1,
          },
        }
      );

      const dataLabels = [
        {
          field: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          field: 'instanceFilename',
          label: this.$t('name'),
          sortable: true,
          sticky: true,
        },
      ];
      const data = [];

      for (const image of results) {
        const imageProps = {
          id: image.id,
          instanceFilename: image.instanceFilename,
        };
        for (const property of image.properties) {
          // find new data labels (column headers)
          if (
            !property.key.includes('@') &&
            !dataLabels.find((label) => label.field === property.key)
          ) {
            if (this.currentUser.admin) {
              // push all datalabels
              dataLabels.push({
                field: property.key,
                label: property.key,
                sortable: true,
              });
            } else if (
              property.key != 'Tile Count' &&
              property.key != 'Percent In-Focus' &&
              property.key != 'Average Focus'
            ) {
              dataLabels.push({
                field: property.key,
                label: property.key,
                sortable: true,
              });
            }
          }

          // set last update field
          const newDate = new Date(
            property.updated ? property.updated : property.created
          );
          // ms in 10 minutes.
          const coff = 1000 * 60 * 10;
          const roundedDate = new Date(
            Math.round(newDate.getTime() / coff) * coff
          );
          roundedDate.setSeconds(0);
          roundedDate.setMilliseconds(0);
          const datetime = roundedDate
            .toLocaleString('en-US')
            .replace(':00 PM', ' PM')
            .replace(':00 AM', ' AM');

          const oldDate = new Date(this.datetimeTracker.get(image.id));
          if (!this.datetimeTracker.get(image.id)) {
            this.datetimeTracker.set(image.id, datetime);
          } else if (oldDate < newDate) {
            // old date found
            this.datetimeTracker.set(image.id, datetime);
          }

          imageProps[property.key] = this.isNumeric(property.value)
            ? Math.round(property.value * 100) / 100
            : property.value;
        }
        imageProps.lastUpdateString = this.datetimeTracker.get(image.id);
        imageProps.lastUpdate = new Date(
          this.datetimeTracker.get(image.id)
        )?.getTime();
        data.push(imageProps);
      }
      this.data = data;

      if (this.currentUser.admin) {
        dataLabels.push({
          field: 'lastUpdateString',
          label: this.$t('status-last-update'),
          sortable: true,
          customSort: (a, b, isAsc) => {
            const aDate = new Date(a.lastUpdate);
            const bDate = new Date(b.lastUpdate);
            return (aDate < bDate ? -1 : 1) * (isAsc ? 1 : -1);
          },
        });
      }

      this.dataLabels = dataLabels;
    };
  },
  methods: {
    isNumeric(str) {
      if (typeof str != 'string') return false; // we only process strings!
      return (
        !Number.isNaN(str) && !Number.isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    }, // https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number

    downloadAsCSV() {
      const { data, dataLabels, project } = this;

      const firstLine = dataLabels.map((labelObj) => labelObj.label).join(',');
      const lines = [firstLine];

      for (const dataObj of data) {
        const nextLine = [];

        for (const labelObj of dataLabels) {
          const value = dataObj[labelObj.field];
          nextLine.push(value);
        }

        lines.push(nextLine.join(','));
      }

      download(lines.join('\n'), `${project.name}.csv`);
    },
  },
};
</script>

<style scoped>
.b-table >>> .table-wrapper.has-sticky-header {
  height: auto;
  max-height: 65vh;
}
</style>
