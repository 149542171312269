<template>
  <div class="color-manipulation">
    <h2 class="text-center">
      {{ $t('heatmap') }}
    </h2>
    <table>
      <tr class="text-right">
        <td class="py-1 px-2 pb-2">
          {{ $t('enabled') }}
        </td>
        <td class="py-1 px-2 pb-2" style="text-align:left;">
          <BSwitch v-model="heatmapMode" size="is-small" />
        </td>
      </tr>
      <tr class="text-right">
        <td class="py-1 px-2 pb-2">
          {{ $t('radius') }}
        </td>
        <td class="w-256 py-1 px-2 pb-2">
          <CytomineSlider v-model="radius" :min="0" :max="30" />
        </td>
      </tr>
      <tr class="text-right">
        <td class="py-1 px-2 pb-2">
          {{ $t('blur') }}
        </td>
        <td class="w-256 py-1 px-2 pb-2">
          <CytomineSlider v-model="blur" :min="0" :max="30" />
        </td>
      </tr>
    </table>

    <div class="mt-4 text-right">
      <button class="button is-small" @click="reset">
        {{ $t('button-reset') }}
      </button>
    </div>
  </div>
</template>

<script>
import CytomineSlider from '@/components/form/CytomineSlider';

export default {
  name: 'HeatmapPanel',
  components: { CytomineSlider },
  props: {
    index: String,
  },
  data() {
    return {
      filters: null,
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    heatmapMode: {
      get() {
        return this.imageWrapper.view.heatmapMode;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'toggleHeatmap', value);
      },
    },
    radius: {
      get() {
        return this.imageWrapper.view.heatmapRadius;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setHeatmapRadius', value);
        this.$eventBus.$emit('setHeatmapRadius', value);
      },
    },
    blur: {
      get() {
        return this.imageWrapper.view.heatmapBlur;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setHeatmapBlur', value);
        this.$eventBus.$emit('setHeatmapBlur', value);
      },
    },
  },
  async created() {},
  methods: {
    reset() {
      this.$store.commit(this.imageModule + 'resetHeatmap');
    },
  },
};
</script>

<style scoped>
td,
tr {
  vertical-align: middle !important;
}

>>> .vue-slider {
  margin-left: 0.4em;
  margin-right: 4em;
}
</style>
