import imageModule from './viewer_modules/image';
import router from '@/router';
import { getModuleNamespace } from '@/store/store';

export default {
  namespaced: true,

  state() {
    return {
      links: [],
      imageSelector: false,
      activeImage: 0,
      indexNextImage: 0,
      isLoading: false,
    };
  },

  mutations: {
    setImageSelector(state, value) {
      state.imageSelector = value;
    },

    addImage(state) {
      state.imageSelector = false;
      state.activeImage = state.indexNextImage;
      state.indexNextImage++;
    },

    setActiveImage(state, index) {
      state.activeImage = index;
    },

    setIsLoading(state, value) {
      state.isLoading = value;
    },

    // ----- View links

    createLinkGroup(state, indexes) {
      state.links.push(indexes);
    },

    linkImageToGroup(state, { indexGroup, indexImage }) {
      state.links[indexGroup].push(indexImage);
    },

    mergeLinkGroups(state, indexes) {
      state.links[indexes[0]].push(...state.links[indexes[1]]);
      state.links.splice(indexes[1], 1);
    },

    unlinkImage(state, { indexGroup, indexImage }) {
      const links = state.links;
      if (!indexGroup) {
        // if group not specified, find the group of the provided image
        indexGroup = links.findIndex((group) => group.includes(indexImage));
        if (indexGroup === -1) {
          return;
        }
      }

      const group = links[indexGroup];
      const indexWithinGroup = group.findIndex((idx) => idx === indexImage);
      group.splice(indexWithinGroup, 1);
      if (group.length === 1) {
        // if group no longer contains several images, delete it
        links.splice(indexGroup, 1);
      }
    },
  },

  actions: {
    changePath({ getters }) {
      const idAnnotation = router.currentRoute.params.idAnnotation;
      const action = router.currentRoute.query.action;
      router.replace(
        getters.pathViewer({
          idAnnotation,
          action,
        })
      );
    },

    async addImage({ state, commit, getters, dispatch }, image) {
      const index = state.indexNextImage;
      commit('addImage');
      this.registerModule(getters.pathImageModule(index), imageModule);
      await dispatch(`images/${index}/initialize`, image);
      dispatch('changePath');
    },

    setImageResolution({ state, commit }, { idImage, resolution }) {
      for (const index in state.images) {
        const image = state.images[index].imageInstance;
        if (image && image.id === idImage) {
          commit(`images/${index}/setResolution`, resolution);
        }
      }
    },

    addTerm({ state, commit }, term) {
      // add term to all image wrappers (as all images belong to same project)
      for (const index in state.images) {
        commit(`images/${index}/addTerm`, term);
      }
    },

    setCenter({ state, getters, commit }, { index, center }) {
      const refImage = state.images[index];
      const increments = refImage.view.center.map((val, i) => center[i] - val);
      const refZoom = refImage.imageInstance.depth - refImage.view.zoom;

      const indexesToUpdate = getters.getLinkedIndexes(index);
      indexesToUpdate.forEach((idx) => {
        const image = state.images[idx];
        const diffZoom = image.imageInstance.depth - image.view.zoom - refZoom;
        const zoomFactor = Math.pow(2, diffZoom);
        commit(
          `images/${idx}/setCenter`,
          image.view.center.map((val, i) => val + increments[i] * zoomFactor)
        );
      });
    },

    setZoom({ state, getters, commit }, { index, zoom }) {
      const zoomIncrement = zoom - state.images[index].view.zoom;
      const indexesToUpdate = getters.getLinkedIndexes(index);
      indexesToUpdate.forEach((idx) => {
        commit(
          `images/${idx}/setZoom`,
          state.images[idx].view.zoom + zoomIncrement
        );
      });
    },

    setRotation({ state, getters, commit }, { index, rotation }) {
      const rotationInc =
        rotation - state.images[index].view.rotation + 2 * Math.PI;
      const indexesToUpdate = getters.getLinkedIndexes(index);
      indexesToUpdate.forEach((idx) => {
        commit(
          `images/${idx}/setRotation`,
          (state.images[idx].view.rotation + rotationInc) % (2 * Math.PI)
        );
      });
    },

    async refreshData({ state, dispatch }) {
      await Promise.all(
        Object.keys(state.images).map(async (index) => {
          await dispatch(`images/${index}/refreshData`);
        })
      );
      dispatch('changePath');
    },

    removeImage({ commit, getters, dispatch }, index) {
      commit('unlinkImage', { indexImage: index });
      this.unregisterModule(getters.pathImageModule(index), imageModule);
      dispatch('changePath');
    },
  },

  getters: {
    pathModule: (state) => {
      return getModuleNamespace(state);
    },

    pathImageModule: (_, getters) => (index) => {
      return [...getters.pathModule, 'images', index];
    },

    pathViewer: (state, getters) => ({ idAnnotation, action } = {}) => {
      // module path = ['projects', idProject, 'viewers', idViewer]
      const idProject = getters.pathModule[1];
      const idViewer = getters.pathModule[3];
      // ---
      const imagesIds = Object.values(state.images).map((img) =>
        img.imageInstance ? img.imageInstance.id : 0
      );
      const annot = idAnnotation ? `/annotation/${idAnnotation}` : '';
      const actionStr = action ? '&action=' + action : '';
      var query = window.location.href;
      var regex = /[?&;](.+?)=([^&;]+)/g;
      var match;

      const params = {};

      if (query) {
        while ((match = regex.exec(query))) {
          params[match[1]] = decodeURIComponent(match[2]);
        }
      }
      let path = `/project/${idProject}/image/${imagesIds.join(
        '-'
      )}${annot}?viewer=${idViewer}${actionStr}`;

      for (const key in params) {
        if (key !== 'viewer') {
          path += `&${key}=${params[key]}`;
        }
      }

      return path;
    },

    getLinkedIndexes: (state) => (index) => {
      // find all indexes linked to provided index (if image is not linked, return only its index)
      return state.links.find((group) => group.includes(index)) || [index];
    },
  },

  modules: {
    images: {
      namespaced: true,
    },
  },
};
