import { spawn, Pool, Worker } from 'threads';

const pool = Pool(() => spawn(new Worker('./getAnnotations')), {
  concurrency: 4,
  name: 'getAnnotations',
});

const getAnnotations = async (params) => {
  return await pool.queue((workerFunc) => workerFunc(params));
};

export { getAnnotations };
