<template>
  <div class="tile is-ancestor is-vertical project-activity-charts-wrapper">
    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box chart-box">
          <div class="columns">
            <h2 class="column">
              {{ $t('activity') }}
            </h2>
            <div class="column is-narrow is-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('activity-chart-info-message') }}</p>
                  <p>{{ $t('barchart-hover-mouse-for-details') }}</p>
                  <p>{{ $t('barchart-click-label-to-toggle-visibility') }}</p>
                </template>
              </v-popover>
            </div>
          </div>
          <div class="chart-container big">
            <ActivityOverviewChart
              :project="project"
              :start-date="startDate"
              :end-date="endDate"
              :days-range="daysRange"
              css-classes="chart"
            />
          </div>
        </div>
      </div>

      <div class="tile is-vertical single-metrics">
        <div class="tile is-parent">
          <div class="tile is-child box single-metric">
            <div class="absolute-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('project-connections-info-message') }}</p>
                </template>
              </v-popover>
            </div>
            <strong class="metric">{{
              nbProjectVisits != null ? nbProjectVisits : '?'
            }}</strong>
            <strong>{{ $t('project-connections') }}</strong>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box single-metric">
            <div class="absolute-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('image-consultations-info-message') }}</p>
                </template>
              </v-popover>
            </div>
            <strong class="metric">{{
              nbImageConsultations != null ? nbImageConsultations : '?'
            }}</strong>
            <strong>{{ $t('image-consultations') }}</strong>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box single-metric">
            <div class="absolute-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('annotation-selections-info-message') }}</p>
                </template>
              </v-popover>
            </div>
            <strong class="metric">{{
              nbAnnotationSelections != null ? nbAnnotationSelections : '?'
            }}</strong>
            <strong>{{ $t('annotation-selections') }}</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-vertical single-metrics">
        <div class="tile is-parent">
          <div class="tile is-child box single-metric">
            <div class="absolute-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('manual-annotations-info-message') }}</p>
                </template>
              </v-popover>
            </div>
            <strong class="metric">
              {{
                nbAnnotations[annotationTypes.USER] != null
                  ? nbAnnotations[annotationTypes.USER]
                  : '?'
              }}
            </strong>
            <strong>{{ $t('user-annotations') }}</strong>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box single-metric">
            <div class="absolute-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('analysis-annotations-info-message') }}</p>
                </template>
              </v-popover>
            </div>
            <strong class="metric">
              {{
                nbAnnotations[annotationTypes.ALGO] != null
                  ? nbAnnotations[annotationTypes.ALGO]
                  : '?'
              }}
            </strong>
            <strong>{{ $t('analysis-annotations') }}</strong>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box single-metric">
            <div class="absolute-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('reviewed-annotations-info-message') }}</p>
                </template>
              </v-popover>
            </div>
            <strong class="metric">
              {{
                nbAnnotations[annotationTypes.REVIEWED] != null
                  ? nbAnnotations[annotationTypes.REVIEWED]
                  : '?'
              }}
            </strong>
            <strong>{{ $t('reviewed-annotations') }}</strong>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child box chart-box">
          <div class="columns">
            <h2 class="column">
              {{ $t('number-annotations') }}
            </h2>
            <template v-if="ontology">
              <div class="column is-narrow has-text-right">
                <div class="filter-label">
                  {{ $t('term') }}
                </div>
              </div>
              <div class="column is-one-third">
                <OntologyTreeMultiselect
                  v-model="selectedTerms"
                  :ontology="ontology"
                  :additional-nodes="[{ id: 0, name: $t('all') }]"
                  :start-with-additional-nodes="true"
                  :multiple="false"
                />
              </div>
            </template>
            <div class="column is-narrow is-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('number-annotations-chart-info-message') }}</p>
                  <p>{{ $t('barchart-hover-mouse-for-details') }}</p>
                  <p>{{ $t('barchart-click-label-to-toggle-visibility') }}</p>
                </template>
              </v-popover>
            </div>
          </div>
          <div class="chart-container big">
            <NumberAnnotationsChart
              :project="project"
              :start-date="startDate"
              :end-date="endDate"
              :days-range="daysRange"
              :term="selectedTerms[0] || null"
              css-classes="chart"
            />
            <!-- If option "all term" (id 0) was selected, return null instead -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="ontology" class="tile">
      <div class="tile is-6 is-parent is-vertical">
        <div class="tile is-child box chart-box no-grow">
          <div class="columns">
            <h2 class="column">
              {{ $t('manual-annotations-vs-term') }}
            </h2>
            <div class="column is-narrow is-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>{{ $t('annotations-vs-terms-chart-info-message') }}</p>
                </template>
              </v-popover>
            </div>
          </div>
          <div :style="styleAnnotationTermChart" class="chart-container">
            <AnnotationTermChart
              :project="project"
              :start-date="startDate"
              :end-date="endDate"
              css-classes="chart"
              @nbElems="(val) => (nbElemsAnnotationTermChart = val)"
            />
          </div>
        </div>
      </div>
      <div class="tile is-6 is-parent is-vertical">
        <div class="tile is-child box chart-box no-grow">
          <div class="columns">
            <h2 class="column">
              {{ $t('annotated-images-vs-term') }}
            </h2>
            <div class="column is-narrow is-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>
                    {{ $t('annotated-images-vs-terms-chart-info-message') }}
                  </p>
                </template>
              </v-popover>
            </div>
          </div>
          <div :style="styleAnnotationTermChart" class="chart-container">
            <AnnotatedImagesByTermChart
              :project="project"
              :start-date="startDate"
              :end-date="endDate"
              css-classes="chart"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-6 is-parent is-vertical">
        <div class="tile is-child box chart-box no-grow">
          <div class="columns">
            <h2 class="column">
              {{ $t('manual-annotations-vs-contributor') }}
            </h2>
            <div class="column is-narrow is-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>
                    {{ $t('annotations-vs-contributors-chart-info-message') }}
                  </p>
                </template>
              </v-popover>
            </div>
          </div>
          <div :style="styleAnnotationContributorChart" class="chart-container">
            <AnnotationContributorChart
              :project="project"
              :start-date="startDate"
              :end-date="endDate"
              css-classes="chart"
              @nbElems="(val) => (nbElemsAnnotationContributorChart = val)"
            />
          </div>
        </div>
      </div>
      <div class="tile is-6 is-parent is-vertical">
        <div class="tile is-child box chart-box no-grow">
          <div class="columns">
            <h2 class="column">
              {{ $t('annotated-images-vs-contributor') }}
            </h2>
            <div class="column is-narrow is-info-circle">
              <v-popover>
                <i class="fas fa-info-circle" />
                <template #popover>
                  <p>
                    {{
                      $t('annotated-images-vs-contributors-chart-info-message')
                    }}
                  </p>
                </template>
              </v-popover>
            </div>
          </div>
          <div :style="styleAnnotationContributorChart" class="chart-container">
            <AnnotatedImagesByContributorChart
              :project="project"
              :start-date="startDate"
              :end-date="endDate"
              css-classes="chart"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AnnotationType } from 'cytomine-client';
import NumberAnnotationsChart from '@/components/charts/NumberAnnotationsChart.js';
import AnnotationTermChart from '@/components/charts/AnnotationTermChart.js';
import AnnotatedImagesByTermChart from '@/components/charts/AnnotatedImagesByTermChart.js';
import AnnotationContributorChart from '@/components/charts/AnnotationContributorChart.js';
import AnnotatedImagesByContributorChart from '@/components/charts/AnnotatedImagesByContributorChart.js';
import ActivityOverviewChart from '@/components/charts/ActivityOverviewChart.js';
import OntologyTreeMultiselect from '@/components/ontology/OntologyTreeMultiselect.vue';

export default {
  name: 'ProjectActivityCharts',
  components: {
    NumberAnnotationsChart,
    AnnotationTermChart,
    AnnotatedImagesByTermChart,
    AnnotationContributorChart,
    AnnotatedImagesByContributorChart,
    ActivityOverviewChart,
    OntologyTreeMultiselect,
  },
  props: {
    startDate: Number,
    endDate: Number,
  },
  data() {
    return {
      loading: true,
      nbProjectVisits: null,
      nbImageConsultations: null,
      nbAnnotationSelections: null,
      nbAnnotations: {
        [AnnotationType.USER]: null,
        [AnnotationType.ALGO]: null,
        [AnnotationType.REVIEWED]: null,
      },
      selectedTerms: [0],
      nbElemsAnnotationTermChart: 0,
      nbElemsAnnotationContributorChart: 0,
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    queryParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
    annotationTypes() {
      return AnnotationType;
    },
    daysRange() {
      // duration of the intervals in evolution plots (depends on the length of the considered period)
      const dayDuration = 24 * 60 * 60 * 1000;
      const intervalDuration =
        (this.endDate || new Date().getTime()) -
        (this.startDate || this.project.created);

      if (intervalDuration > 365 * 2 * dayDuration) {
        // large period => find a daysRange resulting in around 30 points
        return Math.round(intervalDuration / dayDuration / 30);
      } else if (intervalDuration > 365 * dayDuration) {
        return 28;
      } else if (intervalDuration > 6 * 31 * dayDuration) {
        return 14;
      } else if (intervalDuration > 31 * dayDuration) {
        return 7;
      } else {
        return 1;
      }
    },
    styleAnnotationTermChart() {
      return {
        height: 30 + this.nbElemsAnnotationTermChart * 25 + 'px',
      };
    },
    styleAnnotationContributorChart() {
      return {
        height: 30 + this.nbElemsAnnotationContributorChart * 25 + 'px',
      };
    },
  },
  watch: {
    startDate() {
      this.loadData();
    },
    endDate() {
      this.loadData();
    },
  },
  async created() {
    await this.loadData();
    this.loading = false;
  },
  methods: {
    async fetchNbConnections() {
      this.nbProjectVisits = null;
      this.nbProjectVisits = await this.project.fetchNbConnections(
        this.queryParams
      );
    },
    async fetchNbImageConsultations() {
      this.nbImageConsultations = null;
      this.nbImageConsultations = await this.project.fetchNbImageConsultations(
        this.queryParams
      );
    },
    async fetchNbAnnotationSelections() {
      this.nbAnnotationSelections = null;
      this.nbAnnotationSelections = await this.project.fetchNbAnnotationActions(
        {
          type: 'select',
          ...this.queryParams,
        }
      );
    },
    async fetchNbAnnotations(type) {
      this.nbAnnotations[type] = null;
      this.nbAnnotations[type] = await this.project.fetchNbAnnotations({
        annotationType: type,
        ...this.queryParams,
      });
    },
    async loadData() {
      await Promise.all(
        [
          this.fetchNbConnections(),
          this.fetchNbImageConsultations(),
          this.fetchNbAnnotationSelections(),
          this.fetchNbAnnotations(AnnotationType.USER),
          this.fetchNbAnnotations(AnnotationType.ALGO),
          this.fetchNbAnnotations(AnnotationType.REVIEWED),
        ].map((p) => p.catch((e) => console.log(e)))
      ); // ignore errors (handled in template) and ensure all promises finish, even if some errors occur in the process);
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5em;
}

.single-metrics {
  flex-grow: 0;
  min-width: 20em;
}

.single-metric strong {
  display: block;
  text-align: center;
}

.single-metric .metric {
  font-size: 1.5em;
}

.chart-box {
  display: flex;
  flex-direction: column;
}

.chart-container {
  position: relative;
}

.no-grow {
  flex-grow: 0 !important;
}

.chart-container.big {
  min-height: 20em;
}

.columns {
  align-items: center;
}

.filter-label {
  margin-bottom: 0 !important;
}

.column.is-info-circle {
  padding-top: 0;
  margin-top: -1em;
  margin-left: 1em;
}

.fas.fa-info-circle {
  font-size: 1.25em;
  cursor: pointer;
}

.tile {
  position: relative;
}

.tile > .columns {
  margin-bottom: -0.5rem !important;
}

.absolute-info-circle {
  position: absolute;
  top: 0.75em;
  right: 1em;
}
</style>

<style>
.project-activity-charts-wrapper .chart {
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
