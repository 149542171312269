<template>
  <v-popover
    :open="opened"
    :auto-hide="false"
    placement="right"
    trigger="manual"
  >
    <!-- autoHide leads to erratic behaviour when adding/showing DOM elements => handle display of popover manually -->

    <div class="annot-preview relative p-1">
      <button
        ref="previewButton"
        class="absolute top-4 right-4 border-0 p-0"
        @click="opened = !opened"
      >
        <span class="visually-hidden">{{ $t('toggle-details') }}</span>
        <i :class="['fas', opened ? 'fa-minus' : 'fa-plus']" />
      </button>

      <RouterLink
        :to="
          `/project/${annot.project}/image/${annot.image}/annotation/${annot.id}`
        "
      >
        <img
          :src="previewUrl"
          :alt="$t('annotation-preview')"
          loading="lazy"
          :width="size"
          :height="size"
        />
      </RouterLink>
    </div>

    <template #popover>
      <AnnotationDetails
        v-if="opened"
        v-click-outside.capture="(event) => close(event)"
        :annotation="annot"
        :terms="terms"
        :users="users"
        :images="images"
        :is-preview="true"
        @addTerm="$emit('addTerm', $event)"
        @updateTerms="$emit('update')"
        @deletion="$emit('update')"
      />
      <!-- Display component only if it is the currently displayed annotation
            (prevents fetching unnecessary information) -->
    </template>
  </v-popover>
</template>

<script>
import noteApi from '../../services/noteApi.js';
import AnnotationDetails from './AnnotationDetails.vue';
export default {
  name: 'AnnotationPreview',
  components: { AnnotationDetails },
  props: {
    annot: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 85,
    },
    color: {
      type: String,
      default: '',
    },
    terms: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      opened: false,
      annotProperties: {},
      // NEED TO DO: (Commented code) Update object when our own annotation api is ready
      // annotDescription: {},
    };
  },
  computed: {
    /** @returns {string} */
    previewUrl() {
      const outlineParams = this.color
        ? '&draw=true&color=0x' + this.color
        : '';
      const url = `${this.annot.url}?maxSize=${this.size}&square=true&complete=false&thickness=2&increaseArea=1.25${outlineParams}`;

      return url;
    },
    // NEED TO DO: (Commented code) Update object when our own annotation api is ready
    annotWithInfo() {
      return {
        ...this.annot,
        properties: this.annotProperties,
        // description: this.annotDescription,
      };
    },
  },
  async mounted() {
    try {
      // NEED TO DO: (Commented code) Update object when our own annotation api is ready
      // const [annotProp, annotDescrip] = await Promise.all([
      const [annotProp] = await Promise.all([
        noteApi.get(
          `/api/domain/${this.annot.class}/${this.annot.id}/property.json?max=0&offset=0`
        ),
        // noteApi.get(
        //   `/api/domain/${this.annot.class}/${this.annot.id}/description.json`
        // ),
      ]);
      this.annotProperties = annotProp.collection;
      // this.annotDescription = annotDescrip;
    } catch (error) {
      console.log(error);
      this.error = true;
    }
  },
  methods: {
    close(event) {
      if (!this.opened) {
        return;
      }

      let el = event.target;
      while (el) {
        if (
          el.classList.contains('modal') ||
          el.classList.contains('notifications') ||
          el.isSameNode(this.$refs.previewButton)
        ) {
          // do not close the popover if click was performed in modal or in notification
          // if click performed on previewButton, handled in @click
          return;
        }
        el = el.parentElement;
      }

      this.opened = false;
    },
  },
};
</script>

<style scoped>
.annot-preview {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.annot-preview button {
  width: 1.5em;
  height: 1.5em;
}
</style>
