<template>
  <div>
    <vl-layer-vector>
      <vl-source-vector ref="olSourceDrawTarget" :ident="drawSourceName" />
    </vl-layer-vector>

    <vl-interaction-draw
      v-if="nbActiveLayers > 0 || drawCorrection"
      ref="olDrawInteraction"
      :source="drawSourceName"
      :type="drawType"
      :freehand="drawFreehand"
      :freehand-condition="undefined"
      :geometry-function="drawGeometryFunction"
      @drawend="drawEndHandler"
    />
  </div>
</template>

<script>
import Polygon, { fromCircle as polygonFromCircle } from 'ol/geom/Polygon';
import WKT from 'ol/format/WKT';

import { Annotation, AnnotationType } from 'cytomine-client';
import { Action } from '@/utils/annotation-utils.js';

export default {
  name: 'DrawInteraction',
  props: {
    index: String,
  },
  data() {
    return {
      format: new WKT(),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    rotation() {
      return this.imageWrapper.view.rotation;
    },
    termsToAssociate() {
      return this.imageWrapper.draw.termsNewAnnots;
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    activeTool() {
      return this.imageWrapper.draw.activeTool;
    },
    activeEditTool() {
      return this.imageWrapper.draw.activeEditTool;
    },
    selectedFeature() {
      return this.$store.getters[this.imageModule + 'selectedFeature'];
    },
    drawType() {
      switch (this.activeTool) {
        case 'point':
          return 'Point';
        case 'line':
        case 'freehand-line':
          return 'LineString';
        case 'rectangle':
        case 'circle':
          return 'Circle';
        case 'polygon':
        case 'freehand-polygon':
        case 'select': // correct mode
          return 'Polygon';
        default:
          return '';
      }
    },
    drawCorrection() {
      return this.activeTool === 'select';
    },
    drawFreehand() {
      return (
        this.activeTool === 'freehand-polygon' ||
        this.activeTool === 'freehand-line' ||
        this.drawCorrection
      );
    },
    drawGeometryFunction() {
      if (this.activeTool === 'rectangle') {
        return (coordinates, geometry) => {
          const rotatedCoords = this.rotateCoords(coordinates, this.rotation);

          const [firstCorner, thirdCorner] = rotatedCoords;
          const secondCorner = [thirdCorner[0], firstCorner[1]];
          const fourthCorner = [firstCorner[0], thirdCorner[1]];

          const rotatedBoxCoordinates = [
            firstCorner,
            secondCorner,
            thirdCorner,
            fourthCorner,
            firstCorner,
          ];
          const boxCoordinates = [
            this.rotateCoords(rotatedBoxCoordinates, -this.rotation),
          ];

          if (geometry) {
            geometry.setCoordinates(boxCoordinates);
          } else {
            geometry = new Polygon(boxCoordinates);
          }
          return geometry;
        };
      } else {
        return null;
      }
    },
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    activeLayers() {
      return this.layers.filter((layer) => layer.drawOn);
    },
    nbActiveLayers() {
      return this.activeLayers.length;
    },
    drawSourceName() {
      return `draw-target-${this.index}`;
    },
  },

  watch: {
    activeTool() {
      this.$refs.olDrawInteraction.scheduleRecreate();
    },
  },

  methods: {
    rotateCoords(coords, theta) {
      const cosTheta = Math.cos(theta);
      const sinTheta = Math.sin(theta);
      return coords.map(([x, y]) => [
        x * cosTheta + y * sinTheta,
        -x * sinTheta + y * cosTheta,
      ]);
    },

    clearDrawnFeatures() {
      this.$refs.olSourceDrawTarget.clear(true);
    },

    async drawEndHandler({ feature }) {
      if (this.drawCorrection) {
        await this.endCorrection(feature);
      } else if (this.nbActiveLayers > 0) {
        await this.endDraw(feature);
      }

      this.clearDrawnFeatures();
    },

    async endDraw(drawnFeature) {
      this.activeLayers.forEach(async (layer, idx) => {
        const annot = new Annotation({
          location: this.getWktLocation(drawnFeature),
          image: this.image.id,
          user: layer.id,
          term: this.termsToAssociate,
        });

        try {
          await annot.save();
          annot.userByTerm = this.termsToAssociate.map((term) => ({
            term,
            user: [this.currentUser.id],
          }));
          this.$eventBus.$emit('addAnnotations', [annot]);

          this.$store.commit(this.imageModule + 'addAction', {
            annots: [annot],
            type: Action.CREATE,
          });
        } catch (err) {
          console.log(err);
          if (err?.response?.status == 400) {
            this.$notify({
              type: 'error',
              text: 'Not a valid input for selected annotation type.',
            });
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notif-error-annotation-creation'),
            });
          }
        }
      });
    },

    async endCorrection(feature) {
      if (!this.selectedFeature) {
        return;
      }

      try {
        const annot = this.selectedFeature.properties.annot;
        const correctedAnnot = await Annotation.correctAnnotations({
          location: this.getWktLocation(feature),
          review: annot.type === AnnotationType.REVIEWED,
          remove: this.activeEditTool === 'correct-remove',
          annotation: annot.id,
        });
        if (correctedAnnot) {
          correctedAnnot.userByTerm = annot.userByTerm; // copy terms from initial annot
          this.$store.commit(this.imageModule + 'addAction', {
            annots: [correctedAnnot],
            type: Action.UPDATE,
          });
          this.$eventBus.$emit('editAnnotations', [correctedAnnot]);
        }
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-correction'),
        });
      }
    },

    getWktLocation(feature) {
      // transform circle to circular polygon
      const geometry = feature.getGeometry();
      if (geometry.getType() === 'Circle') {
        feature.setGeometry(polygonFromCircle(geometry));
      }
      return this.format.writeFeature(feature);
    },
  },
};
</script>
