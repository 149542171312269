<template>
  <div
    v-if="!configUI['project-activities-tab'] && !currentUser.admin"
    class="box error"
  >
    <h2 class="mb-3">
      {{ $t('access-denied') }}
    </h2>
    <p>{{ $t('insufficient-permission') }}</p>
  </div>
  <div v-else class="project-activity-wrapper content-wrapper flex flex-column">
    <div class="columns">
      <div class="column">
        <BField
          v-if="canManageProject"
          class="radio-buttons-fullwidth is-hovered"
        >
          <BRadioButton
            v-model="activeTab"
            native-value="charts"
            type="is-link"
          >
            {{ $t('activity-charts') }}
          </BRadioButton>

          <BRadioButton
            v-model="activeTab"
            native-value="members"
            type="is-link"
          >
            {{ $t('members-activity') }}
          </BRadioButton>

          <BRadioButton v-model="activeTab" native-value="logs" type="is-link">
            {{ $t('activity-logs') }}
          </BRadioButton>
        </BField>
      </div>
      <div class="column is-narrow">
        <BDropdown
          :disabled="activeTab === 'members'"
          position="is-bottom-left"
          class="date-picker-dropdown"
        >
          <template #trigger>
            <button class="button is-hovered">
              <i class="far fa-calendar" />
            </button>
          </template>

          <div class="min-w-320">
            <BDropdown-item custom>
              <BField :label="$t('from')" horizontal>
                <CytomineDatepicker
                  v-model="startDate"
                  :max-date="endDate || new Date()"
                />
              </BField>
              <BField :label="$t('to')" horizontal>
                <CytomineDatepicker
                  v-model="endDate"
                  :min-date="startDate"
                  :max-date="new Date()"
                />
              </BField>
            </BDropdown-item>
          </div>
        </BDropdown>
      </div>
    </div>

    <KeepAlive>
      <component
        :is="activeComponent"
        :start-date="startDate ? startDate.getTime() : null"
        :end-date="endDate ? endDate.setHours(23, 59, 59, 999) : null"
      />
    </KeepAlive>
  </div>
</template>

<script>
import ProjectActivityCharts from './activity/ProjectActivityCharts.vue';
import MembersActivity from './activity/MembersActivity.vue';
import ProjectActivityLogs from './activity/ProjectActivityLogs.vue';
import CytomineDatepicker from '@/components/form/CytomineDatepicker.vue';

const defaultTab = 'charts';

export default {
  name: 'ProjectActivity',
  components: { CytomineDatepicker },
  data() {
    return {
      activeTab: defaultTab,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    queriedTab() {
      const { tab } = this.$route.query;
      return Array.isArray(tab) ? tab[0] : tab;
    },
    activeComponent() {
      if (!this.canManageProject) {
        // contributor can only view charts tab
        return ProjectActivityCharts;
      }

      switch (this.activeTab) {
        case 'members':
          return MembersActivity;
        case 'logs':
          return ProjectActivityLogs;
        default:
          return ProjectActivityCharts;
      }
    },
  },
  watch: {
    queriedTab() {
      this.changeTab();
    },
    activeTab() {
      if (this.activeTab !== defaultTab || this.queriedTab) {
        this.$router.push(`?tab=${this.activeTab}`);
      }
    },
  },
  created() {
    this.changeTab();
  },
  methods: {
    changeTab() {
      this.activeTab = this.queriedTab || defaultTab;
      if (!this.activeComponent) {
        this.activeTab = defaultTab;
      }
    },
  },
};
</script>
