<template>
  <div id="project-visualizations-container" class="content-wrapper h-full">
    <b-loading :is-full-page="true" :active.sync="isLoading" />
    <div v-if="!isLoading" class="columns h-full">
      <ChartControls
        v-if="selectedChartIndex"
        :data-labels="dataLabels"
        class="column"
      />
      <div
        class="charts-container py-0 px-4 w-full flex flex-column"
        style="max-width:100%;min-height:100%;max-height:100%;"
      >
        <div
          v-for="(row, rowIndex) in charts"
          :key="rowIndex"
          class="chart-row w-full h-full flex"
        >
          <div
            v-for="(chart, columnIndex) in row"
            :key="columnIndex + ' ' + rowIndex + chart.selectedChartType"
            class="chart-column h-full w-full"
            style="flex: 1;"
          >
            <ChartContainer
              :row-index="rowIndex"
              :chart="chart"
              :column-index="columnIndex"
              :data="data"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noteApi from '../../../services/noteApi.js';
import ChartControls from './ChartControls.vue';
import ChartContainer from './ChartContainer.vue';
import { CHART_TYPES } from '@/types/chart-types.js';

import '@/assets/styles/visualizations.scss';

export default {
  components: {
    ChartContainer,
    ChartControls,
  },
  props: {
    idProject: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    data: [],
    dataLabels: [],
    chartTypes: Object.values(CHART_TYPES),
    chartType: CHART_TYPES,
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    charts() {
      return this.$store.state.projectVisualizations.charts;
    },
    selectedChartIndex() {
      return this.$store.state.projectVisualizations.selectedChartIndex;
    },
    numCharts() {
      return this.charts.map((row) => row.length).reduce((a, b) => a + b);
    },
  },
  watch: {
    // when the layout changes, we need to destroy all charts and rebuild them
    // to ensure they are sized correctly
    selectedChartIndex(newValue, oldValue) {
      // only if the value IS or WAS null will the layout change
      if (!newValue || !oldValue) {
        this.refreshCharts();
      }
    },
    numCharts() {
      this.refreshCharts();
    },
  },
  async mounted() {
    const { results } = await noteApi.get(
      `/napi/project/${this.idProject}/image`,
      {
        query: {
          properties: true,
          perPage: -1,
        },
      }
    );

    const dataLabels = [
      {
        field: 'id',
        label: this.$t('image-id'),
      },
      {
        field: 'instanceFilename',
        label: this.$t('slide-name'),
      },
    ];
    const data = [];

    for (const image of results) {
      const imageProps = {
        id: image.id,
        instanceFilename: image.instanceFilename,
      };
      for (const property of image.properties) {
        if (
          !property.key.includes('@') &&
          !dataLabels.find((label) => label.field === property.key)
        ) {
          dataLabels.push({
            field: property.key,
            label: property.key,
          });
        }

        imageProps[property.key] = this.isNumeric(property.value)
          ? Math.round(property.value * 100) / 100
          : property.value;
      }
      data.push(imageProps);
    }

    this.data = data;
    this.dataLabels = dataLabels;

    this.isLoading = false;
  },
  methods: {
    refreshCharts() {
      this.$eventBus.$emit('destroyChart');

      // use setTimeout to ensure the charts are destroyed before we try to render
      setTimeout(() => {
        this.$eventBus.$emit('buildChart');
      }, 10);
    },
    isNumeric(str) {
      if (typeof str != 'string') return false; // we only process strings!
      return (
        !Number.isNaN(str) && !Number.isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    }, // https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
  },
};
</script>

<style scoped>
.b-table >>> .table-wrapper.has-sticky-header {
  height: auto;
  max-height: 65vh;
}
</style>
