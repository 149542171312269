<template>
  <div>
    <h2 class="mb-2 text-center">
      {{ $t('properties') }}
    </h2>
    <BField :label="$t('key')" horizontal>
      <BSelect v-model="selectedPropertyKey" size="is-small" expanded>
        <option :value="null">
          {{ $t('no-key-selected') }}
        </option>
        <option v-for="key in propertiesKeys" :key="key" :value="key">
          {{ key }}
        </option>
      </BSelect>
    </BField>

    <BField :label="$t('color')" horizontal>
      <BSelect v-model="selectedPropertyColor" size="is-small" expanded>
        <option v-for="color in colors" :key="color.name" :value="color">
          {{ $t(color.name) }}
        </option>
      </BSelect>
    </BField>
  </div>
</template>

<script>
import { defaultColors } from '@/utils/style-utils.js';

export default {
  name: 'PropertiesPanel',
  props: {
    index: {
      type: String,
      required: true,
    },
  },
  computed: {
    colors() {
      return defaultColors;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    selectedPropertyKey: {
      get() {
        return this.imageWrapper.properties.selectedPropertyKey;
      },
      set(value) {
        this.$store.dispatch(
          this.imageModule + 'setSelectedPropertyKey',
          value
        );
      },
    },
    selectedPropertyColor: {
      get() {
        return this.imageWrapper.properties.selectedPropertyColor;
      },
      set(value) {
        this.$store.commit(
          this.imageModule + 'setSelectedPropertyColor',
          value
        );
      },
    },
    propertiesKeys() {
      return this.imageWrapper.properties.propertiesKeys;
    },
  },
};
</script>
