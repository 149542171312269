<template>
  <div class="follow-panel">
    <h2 class="mb-2 text-center">
      {{ $t('broadcast') }}
    </h2>

    <div>
      <BCheckbox
        v-model="broadcastModel"
        :disabled="disabledBroadcast"
        type="is-info"
      >
        {{ $t('broadcast-my-position') }}
      </BCheckbox>
    </div>

    <h2 :class="['mb-2', 'mt-3', { disabled: !broadcast }]">
      {{ $t('follow-user') }}
    </h2>

    <div :class="{ disabled: broadcast }" class="follow-panel-content">
      <div class="field">
        <BRadio
          v-model="trackedUserModel"
          :native-value="null"
          :disabled="broadcast"
          type="is-info"
        >
          {{ $t('no-tracking') }}
        </BRadio>
      </div>

      <template v-if="onlineManagers.length > 0">
        <h3>{{ $t('online-managers') }}</h3>
        <div v-for="user in onlineManagers" :key="user.id" class="field">
          <BRadio
            v-model="trackedUserModel"
            :native-value="user.id"
            :disabled="broadcast"
            type="is-info"
          >
            <Username :user="user" />
          </BRadio>
        </div>
      </template>

      <template v-if="onlineContributors.length > 0">
        <h3>{{ $t('online-contributors') }}</h3>
        <div v-for="user in onlineContributors" :key="user.id" class="field">
          <BRadio
            v-model="trackedUserModel"
            :native-value="user.id"
            :disabled="broadcast"
            type="is-info"
          >
            <Username :user="user" />
          </BRadio>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { UserPosition } from 'cytomine-client';
import Username from '@/components/user/Username.vue';
import constants from '@/utils/constants.js';

export default {
  name: 'FollowPanel',
  components: { Username },
  props: {
    index: {
      type: String,
      required: true,
    },
    view: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      onlineUsers: [],

      broadcastModel: false,
      trackedUserModel: null,

      timeoutOnlineUsers: null,
      timeoutTracking: null,

      disabledBroadcast: false,
    };
  },
  computed: {
    projectMembers() {
      return this.$store.state.currentProject.members;
    },
    projectManagers() {
      return this.$store.state.currentProject.managers;
    },
    projectContributors() {
      return this.$store.getters['currentProject/contributors'];
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    project() {
      return this.$store.state.currentProject.project;
    },
    blindMode() {
      return this.project.blindMode;
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    images() {
      return this.viewerWrapper.images;
    },
    linkedIndexes() {
      return (
        this.viewerWrapper.links.find((group) => group.includes(this.index)) ||
        []
      );
    },
    linkedIndexTracking() {
      // true if current view is linked with another view with tracking enabled
      return this.linkedIndexes.some(
        (idx) => idx !== this.index && this.images[idx].tracking.trackedUser
      );
    },
    imageWrapper() {
      return this.viewerWrapper.images[this.index];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    activePanel() {
      return this.imageWrapper.activePanel;
    },
    broadcast: {
      get() {
        return this.imageWrapper.tracking.broadcast;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setBroadcast', value);
      },
    },
    alreadyBroadcastingImage() {
      return Object.keys(this.images).some((index) => {
        const image = this.images[index];
        return (
          index !== this.index &&
          image.imageInstance &&
          image.imageInstance.id === this.image.id &&
          image.tracking.broadcast
        );
      });
    },
    trackedUser: {
      get() {
        return this.imageWrapper.tracking.trackedUser;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setTrackedUser', value);
      },
    },
    onlineManagers() {
      return this.projectManagers.filter(({ id }) =>
        this.onlineUsers.includes(id)
      );
    },
    onlineContributors() {
      return this.projectContributors.filter(({ id }) =>
        this.onlineUsers.includes(id)
      );
    },
    trackedUserFullName() {
      const trackedUser = this.projectMembers.find(
        (user) => user.id === this.trackedUser
      );
      return trackedUser.fullName || '';
    },
  },
  watch: {
    activePanel(panel) {
      if (panel === 'follow') {
        this.fetchOnline();
      }
    },

    broadcast() {
      if (this.broadcast) {
        this.trackedUser = null;
      }
    },

    alreadyBroadcastingImage(value) {
      if (!value) {
        this.disabledBroadcast = false;
      }
    },

    broadcastModel(value) {
      if (value && this.alreadyBroadcastingImage) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-already-broadcasting-this-image'),
        });
        this.disabledBroadcast = true;
        this.$nextTick(() => (this.broadcastModel = false));
        return;
      }

      this.broadcast = value;
    },

    trackedUserModel(value) {
      // if map is linked to another map on which tracking is already enabled, possible conflict
      if (value && this.linkedIndexTracking) {
        this.$buefy.dialog.confirm({
          title: this.$t('possible-conflict'),
          message: this.$t('confirm-unlink-view-to-track'),
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
          onConfirm: () => {
            this.$store.commit(this.viewerModule + 'unlinkImage', {
              indexImage: this.index,
            });
            this.trackedUser = value;
          },
          onCancel: () => (this.trackedUserModel = null),
        });
      } else {
        this.trackedUser = value;
      }
    },

    trackedUser(id) {
      this.trackedUserModel = id;

      if (id) {
        this.track();
        this.fetchOnline();
      }
    },

    onlineUsers(onlines) {
      if (this.trackedUser && !onlines.includes(this.trackedUser)) {
        this.$notify({
          type: 'info',
          text: this.$t('end-tracking-user-no-longer-broadcasting', {
            username: this.trackedUserFullName,
            imageName: this.blindMode
              ? this.image.blindedName
              : this.image.instanceFilename,
          }),
        });
        this.trackedUser = null;
      }
    },
  },
  created() {
    this.trackedUserModel = this.trackedUser;
    this.broadcastModel = this.broadcast;

    this.fetchOnline();
    this.track();
  },
  beforeDestroy() {
    clearTimeout(this.timeoutTracking);
    clearTimeout(this.timeoutOnlineUsers);
  },
  methods: {
    async track() {
      if (!this.trackedUser) {
        return;
      }

      try {
        const pos = await UserPosition.fetchLastPosition(
          this.image.id,
          this.trackedUser
        );
        if (!pos.id || !pos.broadcast) {
          return;
        }

        this.view.animate({
          center: [pos.x, pos.y],
          zoom: pos.zoom,
          rotation: pos.rotation,
          duration: 500,
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-tracked-user-position'),
        });
      }

      clearTimeout(this.timeoutTracking);
      this.timeoutTracking = setTimeout(
        this.track,
        constants.TRACKING_REFRESH_INTERVAL
      );
    },

    async fetchOnline() {
      if (!this.trackedUser && this.activePanel !== 'follow') {
        // if panel not opened and no tracking ongoing, no need to refresh the data
        return;
      }

      const onlines = await this.image.fetchConnectedUsers(true); // retrieve broadcasting users
      this.onlineUsers = onlines.filter((id) => id !== this.currentUser.id);

      clearTimeout(this.timeoutOnlineUsers);
      this.timeoutOnlineUsers = setTimeout(
        this.fetchOnline,
        constants.BROADCASTING_USERS_REFRESH_INTERVAL
      );
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  color: $grey;
}

.follow-panel-content {
  max-height: 14em;
  overflow: auto;
}
</style>
