<template>
  <div class="draw-tools-wrapper">
    <div class="buttons has-addons are-small">
      <button
        v-if="isToolDisplayed('select')"
        v-tooltip="$t('select-draw')"
        :class="{ 'is-selected': activeTool === 'select' }"
        class="button"
        data-label="SelectBtn"
        @click="activateTool('select')"
      >
        <span class="icon is-small"><i class="fas fa-mouse-pointer"/></span>
      </button>

      <button
        v-if="isToolDisplayed('select')"
        v-tooltip="$t('multi-select')"
        :class="{ 'is-selected': activeTool === 'multi-select' }"
        class="button"
        data-label="MultiSelectBtn"
        @click="activateTool('multi-select')"
      >
        <span class="icon is-small"><i class="fas fa-solid fa-magic"/></span>
      </button>
    </div>

    <template v-if="!reviewMode">
      <!-- drawing new annotations forbidden while reviewing -->
      <div
        v-if="terms && terms.length > 0"
        v-click-outside="() => (showTermSelector = false)"
        :class="{ 'has-preview': termsToAssociate.length > 0 }"
        class="buttons has-addons are-small term-selection"
      >
        <button
          v-tooltip="$t('terms-new-annotation')"
          :disabled="disabledDraw"
          class="button"
          data-label="ShowTermSelectorBtn"
          @click="showTermSelector = !showTermSelector"
        >
          <span class="icon is-small"><i class="fas fa-hashtag"/></span>
        </button>

        <div
          :style="{ background: backgroundTermsNewAnnot }"
          class="color-preview"
        >
          <span v-if="termsToAssociate.length > 1">{{
            termsToAssociate.length
          }}</span>
        </div>
        <div v-show="showTermSelector" class="ontology-tree-container">
          <BInput
            v-model="searchStringTerm"
            :placeholder="$t('search')"
            size="is-small"
          />
          <OntologyTree
            v-model="termsToAssociate"
            :ontology="ontology"
            :search-string="searchStringTerm"
            class="ontology-tree"
          />
        </div>
      </div>

      <div class="buttons has-addons are-small">
        <div
          v-if="isToolDisplayed('brush')"
          v-click-outside="() => (showBrushSlider = false)"
          class="buttons has-addons are-small term-selection"
        >
          <button
            v-tooltip="$t('brush')"
            :disabled="disabledDraw"
            :class="{ 'is-selected': activeTool === 'brush' }"
            class="button"
            data-label="BrushBtn"
            @click="activateBrushTool"
          >
            <span class="icon is-small"><i class="fas fa-paint-brush"/></span>
          </button>

          <div v-show="showBrushSlider" class="ontology-tree-container">
            <BField grouped class="p-1">
              <BSlider
                v-model="brushSizeValue"
                class="mx-3"
                data-label="BrushSizeSlider"
                expanded
                rounded
                :min="1"
                :max="30"
                :tooltip="false"
              />
              <IdxInput
                v-model="brushSizeValue"
                class="w-96"
                type="number"
                name="brushSizeInput"
                :hide-label="true"
                label="Brush Size"
                min="1"
                max="30"
              />
            </BField>
          </div>
        </div>

        <button
          v-if="isToolDisplayed('point')"
          v-tooltip="$t('point')"
          :disabled="disabledDraw"
          :class="{ 'is-selected': activeTool === 'point' }"
          class="button"
          data-label="PointBtn"
          @click="activateTool('point')"
        >
          <span class="icon is-small"><i class="fas fa-map-marker-alt"/></span>
        </button>

        <button
          v-if="isToolDisplayed('line')"
          v-tooltip="$t('line')"
          :disabled="disabledDraw"
          :class="{ 'is-selected': activeTool === 'line' }"
          class="button"
          data-label="LineBtn"
          @click="activateTool('line')"
        >
          <span class="icon is-small"><i class="fas fa-minus"/></span>
        </button>

        <button
          v-if="isToolDisplayed('freehand-line')"
          v-tooltip="$t('freehand-line')"
          :disabled="disabledDraw"
          :class="{ 'is-selected': activeTool === 'freehand-line' }"
          class="button"
          data-label="FreehandLineBtn"
          @click="activateTool('freehand-line')"
        >
          <span class="icon is-small">
            <IconLineFreeHand />
          </span>
        </button>

        <!-- QUESTION: redefine expected behaviour
      <button class="button" :disabled="disabledDraw" title="Arrow"
              @click="activateTool('arrow')" :class="{'is-selected': activeTool === 'arrow'}">
          <span class="icon is-small"><i class="fas fa-long-arrow-right"></i></span>
      </button> -->

        <button
          v-if="isToolDisplayed('rectangle')"
          v-tooltip="$t('rectangle')"
          :disabled="disabledDraw"
          :class="{ 'is-selected': activeTool === 'rectangle' }"
          class="button"
          data-label="RectangleBtn"
          @click="activateTool('rectangle')"
        >
          <span class="icon is-small"><i class="far fa-square"/></span>
        </button>

        <button
          v-if="isToolDisplayed('circle')"
          v-tooltip="$t('circle')"
          :disabled="disabledDraw"
          :class="{ 'is-selected': activeTool === 'circle' }"
          class="button"
          data-label="CircleBtn"
          @click="activateTool('circle')"
        >
          <span class="icon is-small"><i class="far fa-circle"/></span>
        </button>

        <button
          v-if="isToolDisplayed('polygon')"
          v-tooltip="$t('polygon')"
          :disabled="disabledDraw"
          :class="{ 'is-selected': activeTool === 'polygon' }"
          class="button"
          data-label="PolygonBtn"
          @click="activateTool('polygon')"
        >
          <span class="icon is-small"><i class="fas fa-draw-polygon"/></span>
        </button>

        <button
          v-if="isToolDisplayed('freehand-polygon')"
          v-tooltip="$t('freehand-polygon')"
          :disabled="disabledDraw"
          :class="{ 'is-selected': activeTool === 'freehand-polygon' }"
          class="button"
          data-label="FreehandPolygonBtn"
          @click="activateTool('freehand-polygon')"
        >
          <span class="icon is-small">
            <IconPolygonFreeHand />
          </span>
        </button>
      </div>
    </template>

    <template v-else>
      <div class="buttons has-addons are-small">
        <button
          v-tooltip="$t('validate-annotation')"
          :disabled="isToolDisabled('accept')"
          :class="{ 'is-success': !isToolDisabled('accept') }"
          class="button"
          data-label="ValidateAnnotationBtn"
          @click="accept()"
        >
          <span class="icon is-small"><i class="fas fa-check"/></span>
        </button>
        <button
          v-tooltip="$t('reject-annotation')"
          :disabled="isToolDisabled('reject')"
          :class="{ 'is-danger': !isToolDisabled('reject') }"
          class="button"
          data-label="RejectAnnotationBtn"
          @click="reject()"
        >
          <span class="icon is-small"><i class="fas fa-minus"/></span>
        </button>
      </div>
    </template>

    <div
      v-if="configUI['project-explore-annotation-main']"
      class="buttons has-addons are-small"
    >
      <button
        v-tooltip="$t('display-annot-details')"
        :disabled="!selectedFeature && selectedFeatures.length === 0"
        :class="{
          'is-selected':
            displayAnnotDetails &&
            (selectedFeature || selectedFeatures.length > 0),
        }"
        class="button"
        data-label="AnnotDetailsBtn"
        @click="displayAnnotDetails = !displayAnnotDetails"
      >
        <span class="icon is-small"><i class="fas fa-info"/></span>
      </button>
    </div>

    <div class="buttons has-addons are-small">
      <button
        v-if="isToolDisplayed('fill')"
        v-tooltip="$t('fill')"
        :disabled="isToolDisabled('fill')"
        class="button"
        data-label="FillBtn"
        @click="fill()"
      >
        <span class="icon is-small"><i class="fas fa-fill"/></span>
      </button>

      <button
        v-if="isToolDisplayed('edit')"
        v-tooltip="$t('modify')"
        :disabled="isToolDisabled('edit')"
        :class="{ 'is-selected': activeEditTool === 'modify' }"
        class="button"
        data-label="EditBtn"
        @click="activateEditTool('modify')"
      >
        <span class="icon is-small"><i class="fas fa-edit"/></span>
      </button>

      <button
        v-if="isToolDisplayed('union')"
        v-tooltip="$t('freehand-correct-add')"
        :disabled="isToolDisabled('correct-add')"
        :class="{ 'is-selected': activeEditTool === 'correct-add' }"
        class="button"
        data-label="UnionBtn"
        @click="activateEditTool('correct-add')"
      >
        <span class="icon is-small"
          ><i class="superscript fas fa-plus"/><i class="fas fa-pencil-alt"
        /></span>
      </button>

      <button
        v-if="isToolDisplayed('diff')"
        v-tooltip="$t('freehand-correct-remove')"
        :disabled="isToolDisabled('correct-remove')"
        :class="{ 'is-selected': activeEditTool === 'correct-remove' }"
        class="button"
        data-label="DiffBtn"
        @click="activateEditTool('correct-remove')"
      >
        <span class="icon is-small"
          ><i class="superscript fas fa-minus"/><i class="fas fa-pencil-alt"
        /></span>
      </button>

      <button
        v-if="isToolDisplayed('move')"
        v-tooltip="$t('move')"
        :disabled="isToolDisabled('move')"
        :class="{ 'is-selected': activeEditTool === 'translate' }"
        class="button"
        data-label="MoveBtn"
        @click="activateEditTool('translate')"
      >
        <span class="icon is-small"><i class="fas fa-arrows-alt"/></span>
      </button>

      <button
        v-if="isToolDisplayed('rotate')"
        v-tooltip="$t('rotate')"
        :disabled="isToolDisabled('rotate')"
        :class="{ 'is-selected': activeEditTool === 'rotate' }"
        class="button"
        data-label="RotateBtn"
        @click="activateEditTool('rotate')"
      >
        <span class="icon is-small"><i class="fas fa-sync-alt"/></span>
      </button>

      <button
        v-if="isToolDisplayed('delete')"
        v-tooltip="$t('delete')"
        :disabled="isToolDisabled('delete')"
        class="button"
        data-label="DeleteBtn"
        @click="confirmDeletion()"
      >
        <span class="icon is-small"><i class="far fa-trash-alt"/></span>
      </button>
    </div>

    <div
      v-if="isToolDisplayed('undo-redo')"
      class="buttons has-addons are-small"
    >
      <button
        v-tooltip="$t('undo')"
        :disabled="actions.length === 0"
        class="button"
        data-label="UndoBtn"
        @click="undo()"
      >
        <span class="icon is-small"><i class="fas fa-undo"/></span>
      </button>

      <button
        v-tooltip="$t('redo')"
        :disabled="undoneActions.length === 0"
        class="button"
        data-label="RedoBtn"
        @click="redo()"
      >
        <span class="icon is-small"><i class="fas fa-redo"/></span>
      </button>
    </div>
  </div>
</template>

<script>
import WKT from 'ol/format/WKT.js';
import { Cytomine, Annotation, AnnotationType } from 'cytomine-client';
import {
  undoBulkDeleteAnnotations,
  bulkDeleteAnnotations,
} from '../../services/noteApi.js';
import OntologyTree from '@/components/ontology/OntologyTree.vue';
import IconPolygonFreeHand from '@/components/icons/IconPolygonFreeHand.vue';
import IconLineFreeHand from '@/components/icons/IconLineFreeHand.vue';

import { Action, updateTermProperties } from '@/utils/annotation-utils.js';

export default {
  name: 'DrawTools',
  components: {
    OntologyTree,
    IconPolygonFreeHand,
    IconLineFreeHand,
  },
  props: {
    index: String,
    viewerWidth: { type: Number, required: true },
  },
  data() {
    return {
      showTermSelector: false,
      showBrushSlider: false,
      format: new WKT(),
      searchStringTerm: '',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    project() {
      return this.$store.state.currentProject.project;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    currentViewerPath() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageWrapper() {
      return this.viewerWrapper.images[this.index];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    terms() {
      return this.$store.getters['currentProject/terms'];
    },
    termsToAssociate: {
      get() {
        return this.imageWrapper.draw.termsNewAnnots;
      },
      set(terms) {
        this.$store.commit(this.imageModule + 'setTermsNewAnnots', terms);
      },
    },
    backgroundTermsNewAnnot() {
      if (this.termsToAssociate.length === 1) {
        return this.terms.find((term) => this.termsToAssociate[0] === term.id)
          .color;
      } else {
        return '#e2e2e2';
      }
    },
    activeTool: {
      get() {
        return this.imageWrapper.draw.activeTool;
      },
      set(tool) {
        this.$store.dispatch(this.imageModule + 'activateTool', tool);
      },
    },
    displayAnnotDetails: {
      get() {
        return this.imageWrapper.selectedFeatures.displayAnnotDetails;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setDisplayAnnotDetails', value);
      },
    },
    activeEditTool: {
      get() {
        return this.imageWrapper.draw.activeEditTool;
      },
      set(tool) {
        this.$store.commit(this.imageModule + 'activateEditTool', tool);
      },
    },
    selectedFeature() {
      return this.$store.getters[this.imageModule + 'selectedFeature'];
    },
    selectedFeatures() {
      return this.imageWrapper.selectedFeatures.selectedFeatures;
    },
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    noActiveLayer() {
      return !this.layers.find((layer) => layer.drawOn);
    },
    disabledDraw() {
      return this.noActiveLayer;
    },
    actions() {
      return this.imageWrapper.undoRedo.actions;
    },
    undoneActions() {
      return this.imageWrapper.undoRedo.undoneActions;
    },
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    isContributor() {
      return this.$store.getters['currentProject/isContributor'];
    },
    reviewMode() {
      return this.imageWrapper.review.reviewMode;
    },
    brushSizeValue: {
      get() {
        return this.imageWrapper.draw.brushSize;
      },
      set(value) {
        this.$store.dispatch(this.imageModule + 'setBrushSize', value);
      },
    },
  },
  watch: {
    noActiveLayer(value) {
      if (value) {
        this.activeTool = 'select';
      }
    },
    selectedFeature(feature) {
      // disable correct tools if no feature is selected
      if (
        !feature &&
        ['correct-add', 'correct-remove'].includes(this.activeEditTool)
      ) {
        this.activeEditTool = null;
      }
    },
    reviewMode(value) {
      if (value) {
        this.activeTool = 'select';
      }
    },
  },
  mounted() {
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  },
  beforeDestroy() {
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    setIsLoading(value) {
      this.$store.commit(this.currentViewerPath + 'setIsLoading', value);
    },
    iterateTerm(index) {
      const terms = this.terms;
      if (index < 0) {
        const lastTerm = terms[terms.length - 1];
        this.termsToAssociate = [lastTerm.id];
      } else if (index >= terms.length) {
        this.termsToAssociate = [terms[0].id];
      } else {
        this.termsToAssociate = [terms[index].id];
      }
      const currentTerm = this.terms.find(
        (term) => this.termsToAssociate[0] === term.id
      );
      this.$notify({
        type: 'success',
        text: this.$t('term-currently-annotating') + currentTerm.name,
      });
    },

    isToolDisplayed(tool) {
      return this.currentUser.admin || this.configUI[`project-tools-${tool}`];
    },

    isToolDisabled(tool) {
      if (!this.selectedFeature) {
        return true; // no feature selected -> all edit tools disabled
      }

      if (
        this.isContributor &&
        this.selectedFeature.properties.annot.user != this.currentUser.id
      ) {
        return true; // user is contributor and it is not their annot -> all edit tools disabled
      }

      const ftr = this.selectedFeature;
      const annot = ftr.properties.annot;
      if (tool === 'accept') {
        return annot.type === AnnotationType.REVIEWED;
      }
      if (tool === 'reject') {
        return annot.type !== AnnotationType.REVIEWED;
      }

      if (!this.$store.getters['currentProject/canEditAnnot'](annot)) {
        return true;
      }

      const geomType = ftr.geometry.type;
      if (geomType === 'Point') {
        return tool !== 'move' && tool !== 'delete'; // disable all tools except move and delete for points
      } else if (geomType === 'LineString') {
        return ['fill', 'correct-add', 'correct-remove'].includes(tool); // disable fill and correct tools for lines
      } else {
        return false;
      }
    },

    activateTool(tool) {
      this.activeTool = tool;
    },
    activateEditTool(tool) {
      this.activeTool = 'select';
      this.activeEditTool = this.activeEditTool === tool ? null : tool; // toggle behaviour
    },

    activateBrushTool() {
      this.showBrushSlider = !this.showBrushSlider;
      this.activateTool('brush');
    },

    async fill() {
      const feature = this.selectedFeature;
      if (!feature) {
        return;
      }

      this.activateEditTool(null);
      const annot = feature.properties.annot.clone();

      try {
        await annot.fill();
        this.$eventBus.$emit('editAnnotations', [annot]);
        this.$store.commit(this.imageModule + 'addAction', {
          annots: [annot],
          type: Action.UPDATE,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-fill'),
        });
      }
    },

    async deleteAnnot() {
      const feature = this.selectedFeature;
      if (!feature) {
        return;
      }

      this.activateEditTool(null);

      try {
        const annot = feature.properties.annot;
        await Annotation.delete(annot.id);
        this.$eventBus.$emit('deleteAnnotations', [annot]);
        this.$store.commit(this.imageModule + 'addAction', {
          annots: [annot],
          type: Action.DELETE,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-deletion'),
        });
      }
    },
    confirmDeletion() {
      if (!this.selectedFeature) {
        return;
      }

      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-annotation'),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteAnnot(),
      });
    },

    async undo() {
      if (this.actions.length === 0) {
        return;
      }

      const action = this.actions[this.actions.length - 1];
      try {
        this.setIsLoading(true);
        const opposedAction = await this.reverseAction(action, true);
        this.$store.commit(this.imageModule + 'undoAction', opposedAction);
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-undo'),
        });
      } finally {
        this.setIsLoading(false);
      }
    },
    async redo() {
      if (this.undoneActions.length === 0) {
        return;
      }

      const action = this.undoneActions[this.undoneActions.length - 1];
      try {
        this.setIsLoading(true);
        const opposedAction = await this.reverseAction(action, false);
        this.$store.commit(this.imageModule + 'redoAction', opposedAction);
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-redo'),
        });
      } finally {
        this.setIsLoading(false);
      }
    },
    async reverseAction({ annots, type, command }, undo) {
      let newType = type;
      if (type === Action.CREATE) {
        const annot = annots[0];
        await Annotation.delete(annot.id);
        this.$eventBus.$emit('deleteAnnotations', [annot]);
        newType = Action.DELETE;
        command = Cytomine.instance.lastCommand;
      } else if (type === Action.DELETE) {
        const annot = annots[0];
        const collection = await Cytomine.instance.undo(command); // always undo if annotation was deleted
        const newAnnot = await this.getUpdatedAnnotation(collection);
        this.$eventBus.$emit('addAnnotations', [newAnnot]);
        newType = Action.CREATE;
      } else if (type === Action.DELETE_ALL) {
        await undoBulkDeleteAnnotations({
          annotationIds: annots.map((a) => a.id),
          imageId: this.image.id,
          projectId: this.project.id,
        });
        this.$eventBus.$emit('addAnnotations', annots);
        newType = Action.BULK_CREATE;
      } else if (type === Action.BULK_CREATE) {
        // @ts-ignore
        await bulkDeleteAnnotations({
          annotationIds: annots.map((a) => a.id),
          imageId: this.image.id,
          projectId: this.project.id,
        });
        this.$eventBus.$emit('deleteAnnotations', annots);
        newType = Action.DELETE_ALL;
      } else {
        // annotation was updated
        let collection;
        if (undo) {
          collection = await Cytomine.instance.undo(command);
        } else {
          collection = await Cytomine.instance.redo(command);
        }
        const newAnnot = await this.getUpdatedAnnotation(collection);
        this.$eventBus.$emit('editAnnotations', [newAnnot]);
      }
      return {
        annots,
        command,
        type: newType,
      };
    },
    async getUpdatedAnnotation(collection) {
      for (const model of collection) {
        const jsonAnnot = model.annotation || model.reviewedannotation;
        if (jsonAnnot) {
          const annot = new Annotation(jsonAnnot);
          await updateTermProperties(annot);
          return annot;
        }
      }
    },

    async accept() {
      const feature = this.selectedFeature;
      if (!feature) {
        return;
      }

      this.activateEditTool(null);
      const annot = feature.properties.annot;
      if (annot.type === AnnotationType.REVIEWED) {
        return;
      }

      try {
        const reviewedAnnot = await annot.review();
        reviewedAnnot.userByTerm = annot.userByTerm; // copy terms from initial annot
        this.$eventBus.$emit('reviewAnnotation', annot);
        this.$eventBus.$emit('addAnnotations', [reviewedAnnot]);
        this.$eventBus.$emit('selectAnnotation', {
          index: this.index,
          annot: reviewedAnnot,
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-validation'),
        });
      }
    },
    async reject() {
      const feature = this.selectedFeature;
      if (!feature) {
        return;
      }

      this.activateEditTool(null);
      const reviewedAnnot = feature.properties.annot;
      if (reviewedAnnot.type !== AnnotationType.REVIEWED) {
        return;
      }

      try {
        const annot = await Annotation.fetch(reviewedAnnot.parentIdent);
        annot.userByTerm = reviewedAnnot.userByTerm; // copy terms from reviewed annot
        await annot.cancelReview();
        this.$eventBus.$emit('deleteAnnotations', [reviewedAnnot]);
        this.$eventBus.$emit('addAnnotations', [annot], false);
        this.$eventBus.$emit('selectAnnotation', {
          index: this.index,
          annot,
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-rejection'),
        });
      }
    },

    shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }

      switch (key) {
        case 'tool-select':
          if (this.isToolDisplayed('select')) {
            this.activateTool('select');
          }
          break;
        case 'tool-terms-next':
        case 'tool-terms-previous':
          if (this.disabledDraw) {
            break;
          } else if (this.terms?.length == 0) {
            // Error message no terms available
            this.$notify({
              type: 'error',
              text: this.$t('terms-not-available'),
            });
          } else {
            console.log(this.layers);
            const lastAssociatedTermId = this.termsToAssociate[
              this.termsToAssociate.length - 1
            ];
            // Get index of last associated term
            const termIndex = this.terms.findIndex(
              (term) => term.id === lastAssociatedTermId
            );
            let index = termIndex - 1;
            if (key === 'tool-terms-next') {
              index = termIndex + 1;
            }
            this.iterateTerm(index);
          }
          break;
        case 'tool-brush':
          if (this.isToolDisplayed('brush') && !this.disabledDraw) {
            this.activateTool('brush');
          }
          break;
        case 'tool-brush-smaller':
        case 'tool-brush-bigger':
          if (this.isToolDisplayed('brush') && !this.disabledDraw) {
            if (key === 'tool-brush-smaller') {
              this.brushSizeValue--;
              break;
            } else if (key === 'tool-brush-bigger') {
              this.brushSizeValue++;
            }
          }
          break;
        case 'tool-point':
          if (this.isToolDisplayed('point') && !this.disabledDraw) {
            this.activateTool('point');
          }
          break;
        case 'tool-line':
          if (this.isToolDisplayed('line') && !this.disabledDraw) {
            this.activateTool('line');
          }
          break;
        case 'tool-freehand-line':
          if (this.isToolDisplayed('freehand-line') && !this.disabledDraw) {
            this.activateTool('freehand-line');
          }
          break;
        case 'tool-rectangle':
          if (this.isToolDisplayed('rectangle') && !this.disabledDraw) {
            this.activateTool('rectangle');
          }
          break;
        case 'tool-circle':
          if (this.isToolDisplayed('circle') && !this.disabledDraw) {
            this.activateTool('circle');
          }
          break;
        case 'tool-polygon':
          if (this.isToolDisplayed('polygon') && !this.disabledDraw) {
            this.activateTool('polygon');
          }
          break;
        case 'tool-freehand-polygon':
          if (this.isToolDisplayed('polygon') && !this.disabledDraw) {
            this.activateTool('freehand-polygon');
          }
          break;
        case 'tool-delete':
          if (
            this.isToolDisplayed('delete') &&
            !this.isToolDisplayed('delete')
          ) {
            this.confirmDeletion();
          }
          break;
        case 'tool-undo':
          if (this.isToolDisplayed('undo')) {
            this.undo();
          }
          break;
        case 'tool-redo':
          if (this.isToolDisplayed('redo')) {
            this.redo();
          }
          break;
        case 'tool-review-accept':
          if (this.reviewMode && this.isToolDisplayed('accept')) {
            this.accept();
          }
          break;
        case 'tool-review-reject':
          if (this.reviewMode && this.isToolDisplayed('reject')) {
            this.reject();
          }
          break;
        case 'tool-fill':
          if (this.isToolDisplayed('fill') && !this.isToolDisabled('fill')) {
            this.fill();
          }
          break;
        case 'tool-correct-add':
          if (
            this.isToolDisplayed('union') &&
            !this.isToolDisabled('correct-add')
          ) {
            this.activateEditTool('correct-add');
          }
          break;
        case 'tool-correct-remove':
          if (
            this.isToolDisplayed('diff') &&
            !this.isToolDisabled('correct-remove')
          ) {
            this.activateEditTool('correct-remove');
          }
          break;
        case 'tool-modify':
          if (this.isToolDisplayed('edit') && !this.isToolDisabled('edit')) {
            this.activateEditTool('modify');
          }
          break;
        case 'tool-move':
          if (this.isToolDisplayed('move') && !this.isToolDisabled('move')) {
            this.activateEditTool('translate');
          }
          break;
        case 'tool-rotate':
          if (
            this.isToolDisplayed('rotate') &&
            !this.isToolDisabled('rotate')
          ) {
            this.activateEditTool('rotate');
          }
          break;
        case 'toggle-current':
          if (
            this.configUI['project-explore-annotation-main'] &&
            this.selectedFeature
          ) {
            this.displayAnnotDetails = !this.displayAnnotDetails;
          }
          break;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        'term-currently-annotating': 'Now annotating for term: ',
      },
      fr: {
        'term-currently-annotating': 'Annotant maintenant pour le terme: ',
      },
    },
  },
};
</script>

<style scoped>
.buttons {
  float: left;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 0 !important;
}

.button {
  margin-bottom: 0px !important;
}

.superscript {
  position: relative;
  bottom: 0.65em;
  left: 1px;
  font-size: 0.7em;
}

:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}

.term-selection {
  position: relative;
}

.term-selection.has-preview i.fas {
  position: relative;
  right: 0.25em;
  top: 0.2em;
  font-size: 0.9em;
}

.term-selection .ontology-tree-container {
  position: absolute;
  top: 100%;
  left: -1.5em;
  margin-top: 5px;
  background: white;
  min-width: 18em;
  max-width: 20vw;
  max-height: 40vh;
  overflow: auto;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  border-radius: 4px;
}

.term-selection:not(.has-preview) .color-preview {
  display: none;
}

.color-preview {
  width: 1em;
  height: 1em;
  display: inline-block;
  border-radius: 0.45em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  z-index: 1000;
  pointer-events: none;
  font-size: 0.7em;
  font-weight: 600;
  text-align: center;
  line-height: 0.9em;
  font-family: Arial;
}
</style>

<style lang="scss">
$colorActiveIcon: #fff;

.draw-tools-wrapper {
  .term-selection .ontology-tree-container {
    .control {
      margin: 0.75em;
      margin-bottom: 0;
    }

    .ontology-tree {
      padding: 0.75em 0;
    }
  }

  .button.is-selected {
    background-color: #6899d0;
    color: $colorActiveIcon;

    path {
      stroke: $colorActiveIcon;
    }
  }

  .icon svg {
    height: 1.15em !important;
  }
}
</style>
