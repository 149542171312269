<template/>

<script>
import { isNullOrUndefined } from '@/utils/string-utils.js';

export default {
  name: 'BarChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    nullValues: [],
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    xAxis() {
      return 'instanceFilename';
    },
    yAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).yAxis[0];
    },
  },
  watch: {
    xAxis() {
      this.initChart();
    },
    yAxis() {
      this.initChart();
    },
  },
  created() {
    // configure what's displayed in the chart controls panel
    this.$store.commit('projectVisualizations/setChartConfig', {
      xAxis: false,
    });
  },
  mounted() {
    this.initChart();
  },
  methods: {
    getSeriesData() {
      const series = this.data.map((data) => {
        if (isNullOrUndefined(data[this.xAxis]) || isNullOrUndefined(data[this.yAxis]) || data[this.yAxis] === 'NA' || data[this.xAxis] === 'NA') {
          this.nullValues.push(data);
          return null;
        }
        return {
          name: data[this.xAxis],
          showInLegend: false,
          id: data.id,
          data: [{ y: data[this.yAxis], id: data.id }],
        };
      });
      return series
        .filter((a) => a)
        .map((item, index) => {
          item.data[0].x = index;
          return item;
        });
    },
    initChart() {
      const seriesData = this.getSeriesData();
      // @ts-ignore
      const chartOptions = {
        chart: {
          type: 'column',
          zoomType: 'x',
        },
        title: {
          text: `Slide data by ${this.yAxis}`,
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          title: {
            text:
              this.xAxis === 'instanceFilename'
                ? this.$t('slides')
                : this.xAxis,
          },
          min: 0,
        },
        yAxis: {
          title: {
            text: this.yAxis,
          },
          labels: {
            overflow: 'justify',
          },
        },
        plotOptions: {
          bar: {
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: (event) => {
                  const routeData = this.$router.resolve({
                    path: `/project/${this.project.id}/image/${event.point.id}`,
                  });
                  window.open(routeData.href, '_blank');
                },
              },
            },
          },
        },
        series: seriesData,
      };

      this.$emit('buildChart', {
        chartOptions,
        nullValues: this.nullValues,
      });
    },
  },
};
</script>

<style scoped></style>
