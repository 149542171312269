<template>
  <div
    v-click-outside="() => (activeSelector = false)"
    :class="{ 'multiselect--active': activeSelector }"
    class="multiselect"
  >
    <div
      class="multiselect__select"
      @click="activeSelector = !activeSelector"
    />

    <div class="multiselect__tags" @click="activeSelector = true">
      <div v-show="!activeSelector" class="multiselect__tags-wrap">
        <strong v-if="allSelected"> {{ $t('all') }} </strong>
        <template v-else>
          <span v-for="(term, index) in displayedSelectedTerms" :key="term.id">
            <CytomineTerm :term="term" />
            <span v-if="index < displayedSelectedTerms.length - 1" class="comma">,</span>
          </span>
          <strong v-if="countNotDisplayed > 0">
            {{
              $tc('and-count-others', countNotDisplayed, {
                count: countNotDisplayed,
              })
            }}
          </strong>
        </template>
      </div>
      <input
        v-show="activeSelector"
        ref="input"
        v-model="searchString"
        :placeholder="$t('select-options')"
        type="text"
        class="multiselect__input"
      />
    </div>

    <div v-show="activeSelector" class="multiselect__content-wrapper">
      <ul v-if="multiple">
        <li
          class="multiselect__element multiselect__select-all"
          @click="selectAll()"
        >
          <span
            :class="[
              'multiselect__option',
              allSelected ? 'multiselect__option--selected' : '',
            ]"
          >
            {{ $t('select-all') }}
          </span>
        </li>
      </ul>
      <OntologyTree
        :ontology="ontology"
        :additional-nodes="additionalNodes"
        :start-with-additional-nodes="startWithAdditionalNodes"
        :selected-nodes="selectedNodes"
        :search-string="searchString"
        :multiple-selection="multiple"
        @select="handleSelection()"
        @setSelectedNodes="(nodes) => $emit('setSelectedNodes', nodes)"
      >
        <template #no-result>
          <ul class="multiselect__content">
            <li @click="selectAll()">
              <span class="multiselect__option">
                {{ $t('no-result') }}
              </span>
            </li>
          </ul>
        </template>
      </OntologyTree>
    </div>
  </div>
</template>

<script>
import OntologyTree from './OntologyTree.vue';
import CytomineTerm from './CytomineTerm.vue';

export default {
  name: 'OntologyTreeMultiselect',
  components: {
    OntologyTree,
    CytomineTerm,
  },
  model: {
    prop: 'selectedNodes',
    event: 'setSelectedNodes',
  },
  props: {
    ontology: { type: Object },
    additionalNodes: {
      type: Array,
      default: () => [],
    },
    startWithAdditionalNodes: {
      type: Boolean,
      default: false,
    },
    selectedNodes: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeSelector: false,
      searchString: '',
      maxNbDisplayed: 3,
    };
  },
  computed: {
    allTerms() {
      if (!this.ontology) {
        return [];
      }
      const terms = this.additionalNodes.map((node) => node);
      terms.push(...this.ontology.terms);
      return terms;
    },
    allSelected() {
      return this.allTerms.length === this.selectedNodes.length;
    },
    displayedSelectedTerms() {
      const ids = this.selectedNodes.slice(0, this.maxNbDisplayed);
      return ids.map((id) => this.allTerms.find((term) => term.id === id));
    },
    countNotDisplayed() {
      return this.selectedNodes.length - this.maxNbDisplayed;
    },
  },
  watch: {
    async activeSelector(val) {
      if (val) {
        await this.$nextTick();
        this.$refs.input.focus();
      }
    },
  },
  methods: {
    selectAll() {
      this.$emit(
        'setSelectedNodes',
        this.allSelected ? [] : this.allTerms.map((term) => term.id)
      );
    },

    handleSelection() {
      if (!this.multiple) {
        this.activeSelector = false;
      }
    },
  },
};
</script>
