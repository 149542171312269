<template>
  <div
    class="chart-container relative flex flex-column text-center w-full h-full justify-center align-center"
    style="min-height:100%;"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
    <div
      :class="isSelected ? 'is-selected': ''"
      style="display:flex;height:100%;width:100%;min-height:100%;flex:1 1; align-items:center"
    >
      <!-- Highchart placeholder div -->
      <div
        :id="containerId"
        class="p-1 w-full h-full"
        style="min-height:100%; flex:1 1;"
      >
      
      </div>
      <div v-if="!highchart" class="flex absolute align-center justify-center w-full h-full">
        <strong style="font-size:4em;color:#dfdfdf;">No Chart</strong>
      </div>
      

      <!-- Add a new chart to the right -->
      <IdxBtn
        v-if="highchart && hovering && allCharts[rowIndex].length < 2"
        color="white"
        link
        style="position:absolute; right: -8px;"
        @click="addColumn"
      >
        <BIcon
          pack="fas"
          icon="plus-circle"
          style="font-size: 1.5em; color:#42a042;"
        />
      </IdxBtn>
    </div>
    <div style="display:flex;flex-basis:100%;height:12px;" />

    <!-- Add a new chart below -->
    <IdxBtn
      v-if="highchart && hovering && allCharts.length < 2"
      color="white"
      link
      style="position:absolute; bottom: -12px; margin-left:5%;"
      @click="addRow"
    >
      <BIcon
          pack="fas"
          icon="plus-circle"
          style="font-size: 1.5em; color:#42a042;"
        />
    </IdxBtn>

    <!-- Chart types -->
    <BarChart
      v-if="chart.selectedChartType === chartType.BAR"
      v-bind="chartProps"
      @buildChart="buildChart"
    />
    <BoxPlot
      v-if="chart.selectedChartType === chartType.BOX_PLOT"
      v-bind="chartProps"
      @buildChart="buildChart"
    />
    <ScatterPlot
      v-if="chart.selectedChartType === chartType.SCATTER"
      v-bind="chartProps"
      @buildChart="buildChart"
    />
    <PieChart
      v-if="chart.selectedChartType === chartType.PIE"
      v-bind="chartProps"
      @buildChart="buildChart"
    />

    <!-- Chart controls -->
    <template v-if="highchart && hovering">
    <IdxBtn
      v-if="nullValues && nullValues.length > 0"
      color="white"
      style="position: absolute; top: 12px; left: 20px"
      @click="toggleInfo"
    >
      <BIcon pack="fas" icon="info-circle" style="font-size: 15px;" />
    </IdxBtn>
    <IdxBtn
      :color="
        selectedChartIndex &&
        selectedChartIndex[0] === rowIndex &&
          selectedChartIndex[1] === columnIndex
          ? 'primary'
          : 'white'
      "
      style="position: absolute; top: 12px; left: 64px"
      @click="toggleChartControls"
    >
      <BIcon pack="fas" icon="pencil-alt" style="font-size: 15px;" />
    </IdxBtn>
    <IdxBtn
      v-if="numCharts > 1"
      color="red"
      style="position: absolute; top: 12px; left: 108px"
      @click="removeChart"
    >
      <BIcon pack="fas" icon="times-circle" style="font-size: 15px;" />
    </IdxBtn>
    </template>
    
    <BModal
      :active.sync="infoExpanded"
      :destroy-on-hide="false"
      trap-focus
      aria-role="dialog"
      aria-modal
      has-modal-card
    >
      <!-- eslint-enable vuejs-accessibility/aria-props -->
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ $t('chart-info') }}
          </p>
        </header>
        <div class="card-content">
          <div style="text-align:left;">
            <BIcon pack="fas" icon="info-circle" style="font-size: 15px;" class="color-primary" />
            {{ nullValues.length }} {{ $t('slides-excluded') }}</div>
          <BTable
            v-if="nullValues.length > 0"
            :data="nullValues"
            paginated
            per-page="10"
            pagination-size="is-small"
            class="mt-4"
          >
            <BTableColumn
              v-slot="props"
              :label="$t('name')"
              field="instanceFilename"
              sortable
              cell-class="has-text-left"
            >
              {{ props.row.instanceFilename }}
            </BTableColumn>
            <BTableColumn
              v-slot="props"
              :label="yAxis"
              :field="yAxis"
              sortable
              cell-class="px-3"
            >
              {{ props.row[yAxis] }}
            </BTableColumn>
            <BTableColumn
              v-slot="props"
              :label="xAxis"
              :field="xAxis"
              sortable
              cell-class="px-3"
            >
              {{ props.row[xAxis] }}
            </BTableColumn>
            <BTableColumn v-slot="props" centered>
              <RouterLink
                :to="`/project/${project.id}/image/${props.row.id}`"
                target="_blank"
              >
                <IdxBtn small full-width color="primary">
                  {{ $t('view-slide') }}
                </IdxBtn>
              </RouterLink>
            </BTableColumn>
          </BTable>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.js';
import BarChart from './charts/BarChart.vue';
import ScatterPlot from './charts/ScatterPlot.vue';
import BoxPlot from './charts/BoxPlot.vue';
import PieChart from './charts/PieChart.vue';
import { CHART_TYPES } from '@/types/chart-types.js';
import { MergeDeep } from '@/utils/array-utils.js';

HighchartsMore(Highcharts);

export default {
  name: 'ChartContainer',
  components: {
    BarChart,
    ScatterPlot,
    BoxPlot,
    PieChart,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    chart: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    highchart: null,
    hovering: false,
    nullValues: [],
    chartOptions: {},
    chartTypes: Object.values(CHART_TYPES),
    chartType: CHART_TYPES,
    infoExpanded: false,
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    containerId() {
      return `container-${this.rowIndex}-${this.columnIndex}`;
    },
    xAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).xAxis[0];
    },
    yAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).yAxis[0];
    },
    allCharts() {
      return this.$store.state.projectVisualizations.charts;
    },
    numCharts() {
      return this.allCharts.map((row) => row.length).reduce((a, b) => a + b);
    },
    selectedChartIndex() {
      return this.$store.state.projectVisualizations.selectedChartIndex;
    },
    isSelected() {
      return (
        this.selectedChartIndex &&
        this.selectedChartIndex[0] === this.rowIndex &&
        this.selectedChartIndex[1] === this.columnIndex
      );
    },
    chartProps() {
      return {
        data: this.data,
        rowIndex: this.rowIndex,
        columnIndex: this.columnIndex,
      };
    },
  },
  mounted() {
    this.$eventBus.$on('destroyChart', this.destroyChart);
    this.$eventBus.$on('buildChart', this.buildChart);
  },
  watch: {
  },
  beforeDestroy() {
    this.destroyChart();
    this.$eventBus.$off('destroyChart', this.destroyChart);
    this.$eventBus.$off('buildChart', this.buildChart);
  },
  methods: {
    destroyChart() {
      if (this.highchart) {
        this.highchart.destroy();
        this.highchart = null;
      }
    },
    toggleInfo() {
      this.infoExpanded = !this.infoExpanded;
    },
    toggleChartControls() {
      if ( !this.isSelected ) {
        this.$store.commit('projectVisualizations/setSelectedChartIndex', [
          this.rowIndex,
          this.columnIndex,
        ]);
      } else {
        this.$store.commit('projectVisualizations/setSelectedChartIndex', null);
      }
    },
    resizeChart() {
      this.highchart.reflow();
    },
    buildChart(options) {
      this.destroyChart();

      if(options) {
        this.chartOptions = options.chartOptions;
        this.nullValues = options.nullValues;
      }

      const mergedOptions = MergeDeep({
        chart: {
          backgroundColor: '#fbfbfb',
        },
        // fits chart to bounding box
        render: function() {
          const chart = this,
            group = chart.series[0].group,
            bBox = group.getBBox(),
            ratio = bBox.width / bBox.height;

          chart.setSize(null, (chart.plotSizeX + 20) / ratio, false);
        },
        credits: {
          enabled: false,
        },
      }, this.chartOptions);

      this.highchart = Highcharts.chart(this.containerId, mergedOptions);
    },
    addRow() {
      this.hovering = false;
      const currentCharts = [...this.allCharts];
      currentCharts.push([{ ...this.chart }]);
      this.$store.commit('projectVisualizations/updateCharts', currentCharts);
      this.$store.commit('projectVisualizations/setSelectedChartIndex', [1, 0]);
    },
    addColumn() {
      this.hovering = false;
      const currentCharts = [...this.allCharts];
      currentCharts[this.rowIndex].push({ ...this.chart });
      this.$store.commit('projectVisualizations/updateCharts', currentCharts);
      this.$store.commit('projectVisualizations/setSelectedChartIndex', [
        this.rowIndex,
        this.columnIndex + 1,
      ]);
    },
    removeChart() {
      const currentCharts = [...this.allCharts];
      currentCharts[this.rowIndex].splice(this.columnIndex, 1);
      if (currentCharts[this.rowIndex].length === 0) {
        currentCharts.splice(this.rowIndex, 1);
      }
      this.$store.commit('projectVisualizations/setSelectedChartIndex', [0, 0]);
      this.$store.commit('projectVisualizations/updateCharts', currentCharts);
    },
  },
};
</script>

<style scoped>
.is-selected {
  -webkit-box-shadow:inset 0px 0px 0px 1px #27b4f7;
  -moz-box-shadow:inset 0px 0px 0px 1px #27b4f7;
  box-shadow:inset 0px 0px 0px 1px #27b4f7;
}
</style>
