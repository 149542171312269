import { defaultColors } from '@/utils/style-utils.js';
import { PropertyCollection } from 'cytomine-client';
import constants from '@/utils/constants';

export default {
  state() {
    return {
      idImage: 0,
      propertiesKeys: null,
      selectedPropertyKey: null,
      selectedPropertyColor: defaultColors[0],
      selectedPropertyValues: {},
    };
  },

  mutations: {
    setIdImage(state, id) {
      state.idImage = id;
    },

    setPropertiesKeys(state, keys) {
      state.propertiesKeys = keys;
    },

    setSelectedPropertyKey(state, value) {
      state.selectedPropertyKey = value;
    },

    setSelectedPropertyColor(state, value) {
      state.selectedPropertyColor = value;
    },

    setSelectedPropertyValues(state, properties) {
      state.selectedPropertyValues = properties;
    },

    addSelectedPropertyValues(state, newValues) {
      state.selectedPropertyValues = {
        ...state.selectedPropertyValues,
        ...newValues,
      };
    },
  },

  actions: {
    async initialize({ commit, rootState }, image) {
      commit('setIdImage', image.id);

      const [propertiesKeys, projectProperties] = await Promise.all([
        fetchPropertiesKeys(image.id),
        PropertyCollection.fetchAll({
          object: rootState.currentProject.project,
        }),
      ]);

      let selectedPropertyKey = null;
      const defaultPropertyProp = projectProperties.array.find(
        (prop) => prop.key === constants.DEFAULT_PROPERTY_KEY
      );
      if (
        defaultPropertyProp &&
        propertiesKeys.includes(defaultPropertyProp.value)
      ) {
        selectedPropertyKey = defaultPropertyProp.value;
      }

      commit('setPropertiesKeys', propertiesKeys);
      commit('setSelectedPropertyKey', selectedPropertyKey);
    },

    async setSelectedPropertyKey({ state, commit, getters }, value) {
      const selectedLayers = getters.selectedLayers;

      let properties = {};
      if (value && selectedLayers) {
        for (const layer of selectedLayers) {
          const layerValues = await fetchLayerPropertiesValues(
            layer.id,
            state.idImage,
            value
          );
          properties = {
            ...properties,
            ...layerValues,
          };
        }
      }

      commit('setSelectedPropertyValues', properties);
      commit('setSelectedPropertyKey', value);
    },

    async addLayer({ state, commit }, layer) {
      const key = state.selectedPropertyKey;
      let propValues = {};
      if (key) {
        propValues = await fetchLayerPropertiesValues(
          layer.id,
          state.idImage,
          key
        );
      }
      commit('addSelectedPropertyValues', propValues);
    },

    async setImageInstance({ commit, dispatch }, image) {
      commit('setIdImage', image.id);
      await dispatch('refreshProperties');
    },

    async refreshProperties({ state, commit, dispatch }) {
      const keys = await fetchPropertiesKeys(state.idImage);
      commit('setPropertiesKeys', keys);

      const currentKey = state.selectedPropertyKey;
      const newKey = keys.includes(currentKey) ? currentKey : null;
      dispatch('setSelectedPropertyKey', newKey);
    },

    async refreshData({ dispatch }) {
      await dispatch('refreshProperties');
    },
  },
};

/**
 * @param idLayer
 * @param idImage
 * @param key
 */
async function fetchLayerPropertiesValues(idLayer, idImage, key) {
  if (idLayer === -1) {
    return []; // currently not possible to retrieve properties of review layer (TODO in core)
  }

  const propertiesValues =
    await PropertyCollection.fetchPropertiesValuesAndPositions(
      idLayer,
      idImage,
      key
    );

  // if several properties with target key for an annotation, concatenate their values
  const properties = {};
  propertiesValues.forEach((propVal) => {
    if (!properties[propVal.idAnnotation]) {
      properties[propVal.idAnnotation] = propVal.value;
    } else {
      properties[propVal.idAnnotation] += '; ' + propVal.value;
    }
  });

  return properties;
}

/**
 * @param idImage
 */
async function fetchPropertiesKeys(idImage) {
  const data = await PropertyCollection.fetchKeysAnnotationProperties(
    null,
    idImage
  );
  return data;
}
