import { HorizontalBar } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { asArray as hexToRgb } from 'ol/color.js';

const defaultColor = '#eee';

export default {
  name: 'annotated-images-by-term-chart',
  extends: HorizontalBar,
  props: {
    project: Object,
    startDate: Number,
    endDate: Number,
  },
  data() {
    return {
      chartData: null,
    };
  },
  computed: {
    queryParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
  },
  watch: {
    async queryParams() {
      this.doRenderChart();
    },
  },
  methods: {
    async doRenderChart() {
      const terms = await this.project.fetchStatsAnnotatedImagesByTerm(
        this.queryParams
      );
      const data = terms.map((term) => term.value);
      const borderColors = terms.map((term) => {
        const [r, g, b] = hexToRgb(term.color || defaultColor);
        const factor = 0.8;
        return `rgba(${r * factor}, ${g * factor}, ${b * factor}, 1)`;
      });

      this.$emit('nbElems', data.length);

      this.renderChart(
        {
          labels: terms.map((term) => term.key || this.$t('no-term')),
          datasets: [
            {
              data,
              backgroundColor: terms.map((term) => term.color || defaultColor),
              borderColor: borderColors,
              borderWidth: 1,
              hoverBorderColor: borderColors,
            },
          ],
        },
        {
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            xAxes: [
              {
                ticks: {
                  min: 0,
                  suggestedMax: Math.round(Math.max(...data) * 1.2) + 1,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                categoryPercentage: 0.6,
              },
            ],
          },
        }
      );
    },
  },
  async mounted() {
    this.addPlugin(ChartDataLabels);
    this.doRenderChart();
  },
};
