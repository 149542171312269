var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    selector: _vm.allowSelection,
    draggable: _vm.allowDrag,
    editable: _vm.allowEdition,
  }},[_c('SlVueTree',{ref:"tree",attrs:{"allow-multiselect":false},on:{"select":_vm.select,"drop":_vm.drop},scopedSlots:_vm._u([{key:"toggle",fn:function(ref){
  var node = ref.node;
return [(!node.data.hidden && !node.isLeaf && node.children.length > 0)?[_c('i',{class:[
            'tree-toggle',
            'fas',
            node.isExpanded ? 'fa-angle-down' : 'fa-angle-right' ]})]:_vm._e(),_c('div',{staticClass:"sl-vue-tree-gap"})]}},{key:"title",fn:function(ref){
          var node = ref.node;
return [(!node.data.hidden)?_c('div',{staticClass:"tree-selector"},[(_vm.allowSelection)?_c('i',{staticClass:"tree-checkbox",class:_vm.classNames(node)}):_vm._e(),_c('CytomineTerm',{attrs:{"term":node.data}})],1):_c('div')]}},{key:"sidebar",fn:function(ref){
          var node = ref.node;
return [(!node.data.hidden)?_vm._t("custom-sidebar",function(){return [(_vm.allowEdition)?_c('div',[_c('IdxBtn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.startTermUpdate(node)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-edit"})])]),_c('IdxBtn',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmTermDeletion(node)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-trash-alt"})])])],1):_vm._e()]},{"term":node.data}):_vm._e()]}}],null,true),model:{value:(_vm.treeNodes),callback:function ($$v) {_vm.treeNodes=$$v},expression:"treeNodes"}}),(_vm.noResult)?_vm._t("no-result",function(){return [_c('em',{staticClass:"has-text-grey no-result"},[_vm._v(_vm._s(_vm.$t('no-result')))])]}):_vm._e(),(_vm.allowEdition || _vm.allowNew)?_c('div',{staticClass:"my-2 text-center"},[_c('IdxBtn',{attrs:{"small":""},on:{"click":_vm.startTermCreation}},[_vm._v(" "+_vm._s(_vm.$t('add-term'))+" ")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }