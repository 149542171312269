<template>
  <div>
    <div class="progress-bar-container">
      <div class="progress-bar stripes animated slower">
        <span class="progress-bar-inner" :style="'width:' + percent + '%'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    percent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
body,
html {
  height: 100%;
  background: #0e0e15;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 60px 0;
  }
}

@keyframes auto-progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.progress-bar {
  background-color: #dbdbdb;
  height: 12px;
  width: 100%;
  margin: 50px auto;
  border-radius: 5px;
}

.stripes {
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgb(240, 240, 240) 25%,
    transparent 25%,
    transparent 50%,
    rgb(240, 240, 240) 50%,
    rgb(240, 240, 240) 75%,
    transparent 75%,
    transparent
  );
}

.stripes.animated {
  animation: animate-stripes 0.6s linear infinite;
}

.stripes.animated.slower {
  animation-duration: 1.25s;
}

.progress-bar-inner {
  display: block;
  height: 12px;
  background-color: #24a3ff;
  border-radius: 3px;
  position: relative;
}
</style>
