<template>
  <div />
</template>

<script>
export default {
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
  },
  created() {
    if (this.configUI['project-images-tab']) {
      this.$router.replace(`/project/${this.project.id}/images`);
    } else {
      this.$router.replace(`/project/${this.project.id}/information`);
    }
  },
};
</script>
