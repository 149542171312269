<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <vl-layer-vector :update-while-interacting="true">
      <vl-source-vector ref="olSourceDrawTarget" :ident="drawSourceName" />
    </vl-layer-vector>
    <div id="mouse-circle" ref="mouseCursorEl"></div>
  </div>
</template>

<script>
import Polygon, { fromCircle as polygonFromCircle } from 'ol/geom/Polygon';
import LinearRing from 'ol/geom/LinearRing';
import {
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
} from 'ol/geom';
import Circle from 'ol/geom/Circle';
import Feature from 'ol/Feature';

import { Draw } from 'ol/interaction';
import Vector from 'ol/source/Vector';
import { Style, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import WKT from 'ol/format/WKT';
import WKTReader from 'jsts/org/locationtech/jts/io/WKTReader.js';
import Parser from 'jsts/org/locationtech/jts/io/OL3Parser.js';
import 'jsts/org/locationtech/jts/monkey.js';
import { Annotation, AnnotationType } from 'cytomine-client';
import { Action } from '@/utils/annotation-utils.js';

export default {
  name: 'BrushInteraction',
  props: {
    map: {
      type: Object,
      required: true,
    },
    index: String,
    mousePosition: Array,
    viewerWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      format: new WKT(),
      jstsReader: new WKTReader(),
      jstsParser: new Parser(),
      isDrawing: false,
      featuresInProgress: [],
      brushCursor: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    brushSizeMapUnits() {
      const olMapBounds = this.map.$map
        .getView()
        .calculateExtent(this.map.$map.getSize());
      const olMapWidth = olMapBounds[2] - olMapBounds[0];

      return (this.imageWrapper.draw.brushSize / 100) * olMapWidth;
    },
    brushSizePx() {
      const htmlMapBounds = this.map.$el.getBoundingClientRect();
      const olMapBounds = this.map.$map
        .getView()
        .calculateExtent(this.map.$map.getSize());
      const pxMapWidth = htmlMapBounds.width;
      const olMapWidth = olMapBounds[2] - olMapBounds[0];
      const pxToMapUnitsRatio = pxMapWidth / olMapWidth;
      return this.brushSizeMapUnits * pxToMapUnitsRatio;
    },
    termsToAssociate() {
      return this.imageWrapper.draw.termsNewAnnots;
    },
    selectedFeature() {
      return this.$store.getters[this.imageModule + 'selectedFeature'];
    },
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    activeLayers() {
      return this.layers.filter((layer) => layer.drawOn);
    },
    drawSourceName() {
      return `draw-target-${this.index}`;
    },
    activeTool() {
      return this.imageWrapper.draw.activeTool;
    },
  },

  watch: {
    mousePosition(oldPosition, newPosition) {
      if (this.isDrawing) {
        this.handleDrawing();
      }
    },
  },
  created() {
    this.map.$el.addEventListener('mousedown', this.handleMouseDown);
    this.map.$el.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('mousemove', this.handleMouseMove);

    this.jstsParser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon
    );
  },
  beforeDestroy() {
    this.map.$el.removeEventListener('mousedown', this.handleMouseDown);
    this.map.$el.removeEventListener('mouseup', this.handleMouseUp);
    if (this.brushCursor) {
      this.map.$map.removeInteraction(this.brushCursor);
    }
  },
  methods: {
    handleMouseMove(event) {
      if (this.$refs.mouseCursorEl) {
        const mapBounds = this.map.$el.getBoundingClientRect();
        this.$refs.mouseCursorEl.style.top =
          event.pageY - mapBounds.top - this.brushSizePx / 2 + 'px';
        this.$refs.mouseCursorEl.style.left =
          event.pageX - mapBounds.left - this.brushSizePx / 2 + 'px';
        this.$refs.mouseCursorEl.style.margin = `${-this.brushSizePx /
          2}px 0px 0px ${-this.brushSizePx / 2}`;
        this.$refs.mouseCursorEl.style.width = this.brushSizePx + 'px';
        this.$refs.mouseCursorEl.style.height = this.brushSizePx + 'px';
      }
    },
    drawCircleCursor() {
      if (this.brushCursor) {
        this.map.$map.removeInteraction(this.brushCursor);
      }
      this.brushCursor = new Draw({
        type: 'Polygon',
        source: this.drawSourceName,
        style: new Style({
          image: new CircleStyle({
            radius: this.brushSizeMapUnits / 2,
            fill: null,
            stroke: new Stroke({
              color: 'rgba(255,0,0,0.9)',
              width: 1,
            }),
          }),
        }),
      });
    },
    async handleMouseDown() {
      this.isDrawing = true;

      if (this.selectedFeature) {
        // add to an existing feature
        this.featuresInProgress.push(this.selectedFeature.properties.annot);
        this.handleDrawing();
      } else {
        // create a new feature
        // draw initial circle
        var circle = new Circle(this.mousePosition, this.brushSizeMapUnits / 2);
        var circleFeature = new Feature(circle);

        this.activeLayers.forEach(async (layer, idx) => {
          const annot = new Annotation({
            location: this.getWktLocation(circleFeature),
            image: this.image.id,
            user: layer.id,
            term: this.termsToAssociate,
          });
          try {
            await annot.save();
            annot.userByTerm = this.termsToAssociate.map((term) => ({
              term,
              user: [this.currentUser.id],
            }));
            this.$eventBus.$emit('addAnnotations', [annot]);

            this.featuresInProgress.push(annot);
          } catch (err) {
            console.log(err);
          }
        });
      }
    },
    async handleMouseUp() {
      // Code to auto-select feature on mouse up
      // for (var annot of this.featuresInProgress) {
      //   this.$eventBus.$emit('selectAnnotation', {
      //     index: this.index,
      //     annot,
      //   });
      // }

      for (var annot of this.featuresInProgress) {
        try {
          const annotClone = annot.clone();
          await annotClone.save();
          this.$store.commit(this.imageModule + 'addAction', {
            annots: [annotClone],
            type: Action.CREATE,
          });
        } catch (err) {
          console.log(err);
        }
      }

      this.featuresInProgress = [];
      this.isDrawing = false;
    },
    async handleDrawing() {
      const correctedAnnots = [];
      for (var annot of this.featuresInProgress) {
        // draw new circle
        var circle = new Circle(this.mousePosition, this.brushSizeMapUnits / 2);
        var circleFeature = new Feature(circle);

        const a = this.jstsReader.read(annot.location);
        const b = this.jstsReader.read(this.getWktLocation(circleFeature));
        const union = a.union(b); // takes the union of the two features

        circleFeature.setGeometry(this.jstsParser.write(union));

        const correctedAnnot = annot.clone();
        correctedAnnot.location = this.getWktLocation(circleFeature);

        correctedAnnot.userByTerm = annot.userByTerm; // copy terms from initial annot

        correctedAnnots.push(correctedAnnot);
      }

      this.$eventBus.$emit('editAnnotations', correctedAnnots); // updates the annots in the UI
      this.featuresInProgress = correctedAnnots;
    },

    getWktLocation(feature) {
      // transform circle to circular polygon
      const geometry = feature.getGeometry();
      if (geometry.getType() === 'Circle') {
        feature.setGeometry(polygonFromCircle(geometry));
      }
      return this.format.writeFeature(feature);
    },
  },
};
</script>
<style scoped>
#mouse-circle {
  position: absolute;
  border: 1px solid #000000;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  pointer-events: none !important;
  z-index: 99999;
}
</style>
