<template>
  <div class="user-activity-wrapper">
    <BLoading :is-full-page="false" :active="loading" />
    <div v-if="!loading" class="content-wrapper">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <RouterLink :to="`/project/${project.id}`">
              {{ project.name }}
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="`/project/${project.id}/activity?tab=members`">
              {{ $t('members-activity') }}
            </RouterLink>
          </li>
          <li>
            <span aria-current="page" class="pl-3">{{
              $t('activity-of-user', { username: user.fullName })
            }}</span>
          </li>
        </ul>
      </nav>

      <div class="box relative">
        <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
          <h2
            class="
              mb-3
              size-28
              weight-4
              text-transform-none
              spacing-0
              text-center
            "
          >
            {{ $t('error') }}
          </h2>
          <p>{{ $t('unexpected-error-info-message') }}</p>
        </BMessage>

        <template v-else>
          <p class="size-12 text-right has-text-grey">
            {{
              $t('data-last-updated-on', {
                time: $options.filters.date(lastUpdate, 'LTS'),
              })
            }}
          </p>
          <h2 class="mb-3">
            {{ $t('activity-of-user', { username: user.fullName }) }}
          </h2>

          <ul>
            <li v-if="currentUser.admin">
              <strong>{{ $t('email') }}:</strong>&nbsp;<a
                :href="`mailto:${user.email}`"
              >{{ user.email }}</a>
            </li>
            <li>
              <strong>{{ $t('registration-date') }}:</strong>
              {{ Number(user.created) | date('ll LTS') }}
            </li>
            <li>
              <strong>{{ $t('first-project-connection') }}:</strong>
              {{ Number(resumeActivity.firstConnection) | date('ll LTS') }}
            </li>
            <li>
              <strong>{{ $t('last-project-connection') }}:</strong>
              {{ Number(resumeActivity.lastConnection) | date('ll LTS') }}
            </li>
          </ul>

          <hr />

          <div class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">
                  {{ $t('project-connections') }}
                </p>
                <p class="title">
                  {{ resumeActivity.totalConnections }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">
                  {{ $t('image-consultations') }}
                </p>
                <p class="title">
                  {{ resumeActivity.totalConsultations }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">
                  {{ $t('annotation-selections') }}
                </p>
                <p class="title">
                  {{ resumeActivity.totalAnnotationSelections }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">
                  {{ $t('annotation-creations') }}
                </p>
                <p class="title">
                  {{ resumeActivity.totalAnnotations }}
                </p>
              </div>
            </div>
          </div>

          <hr />

          <h2 class="mb-3">
            {{ $t('project-detailed-connections') }}
          </h2>

          <BTable
            :data="connections.array"
            paginated
            :per-page="connectionsPerPage"
            default-sort="created"
            default-sort-direction="desc"
            pagination-size="is-small"
            detailed
            detail-key="id"
          >
            <BTableColumn :label="$t('date')" field="created" sortable>
              <template #default="props">
                {{ Number(props.row.created) | date('ll LT') }}
              </template>
            </BTableColumn>

            <BTableColumn :label="$t('duration')" field="time" sortable>
              <template #default="props">
                {{ props.row.time | duration('humanize') }}
                <span v-if="props.row.online" class="tag is-success ml-2">{{
                  $t('ongoing')
                }}</span>
              </template>
            </BTableColumn>

            <BTableColumn
              :label="$t('number-viewed-images')"
              field="countViewedImages"
              centered
              sortable
            >
              <template #default="props">
                {{ props.row.countViewedImages }}
              </template>
            </BTableColumn>

            <BTableColumn
              :label="$t('number-annotation-creations')"
              field="countCreatedAnnotations"
              centered
              sortable
            >
              <template #default="props">
                {{ props.row.countCreatedAnnotations }}
              </template>
            </BTableColumn>

            <template #empty>
              <div class="content has-text-grey has-text-centered">
                <p>{{ $t('project-no-connection') }}</p>
              </div>
            </template>

            <template #detail="{ row: connection }">
              <ProjectConnectionDetails
                :key="connection.id"
                :connection="connection"
              />
            </template>

            <template #footer>
              <p class="has-text-centered">
                <a
                  :href="connections.downloadURL"
                  class="button is-link"
                  target="_self"
                >
                  {{ $t('button-export-as-csv') }}
                </a>
              </p>
            </template>

            <template #bottom-left>
              <BSelect v-model="connectionsPerPage" size="is-small">
                <option value="10">
                  {{ $t('count-per-page', { count: 10 }) }}
                </option>
                <option value="25">
                  {{ $t('count-per-page', { count: 25 }) }}
                </option>
                <option value="50">
                  {{ $t('count-per-page', { count: 50 }) }}
                </option>
                <option value="100">
                  {{ $t('count-per-page', { count: 100 }) }}
                </option>
              </BSelect>
            </template>
          </BTable>

          <hr />

          <h2 class="mb-3">
            {{ $t('connections-chart') }}
          </h2>

          <div class="columns">
            <div class="column is-narrow">
              <BField :label="$t('by')" horizontal>
                <BSelect v-model="period" size="is-small">
                  <option value="hour">
                    {{ $t('hour') }}
                  </option>
                  <option value="day">
                    {{ $t('day') }}
                  </option>
                  <option value="week">
                    {{ $t('week') }}
                  </option>
                </BSelect>
              </BField>
            </div>
            <div class="column is-narrow">
              <BField :label="$t('from')" horizontal>
                <CytomineDatepicker
                  v-model="startDate"
                  :reset-button="false"
                  :max-date="endDate || new Date()"
                />
              </BField>
            </div>
            <div class="column is-narrow">
              <BField :label="$t('to')" horizontal>
                <CytomineDatepicker
                  v-model="endDate"
                  :min-date="startDate"
                  :max-date="new Date()"
                />
              </BField>
            </div>
          </div>

          <LastConnectionsChart
            :project="project.id"
            :user="idUser"
            :period="period"
            :start-date="startDate.getTime()"
            :end-date="endDate ? endDate.setHours(23, 59, 59, 999) : null"
            :show-dates="true"
            :revision="chartRevision"
            css-classes="h-320 mt-5"
          />

          <hr />

          <h2 class="mb-3">
            {{ $t('detailed-image-consultations') }}
          </h2>

          <BTable
            :data="consultations.array"
            paginated
            :per-page="consultationsPerPage"
            pagination-size="is-small"
          >
            <BTableColumn :label="$t('overview')" field="created">
              <template #default="props">
                <RouterLink
                  :to="`/project/${project.id}/image/${props.row.image}`"
                >
                  <img
                    :src="props.row.imageThumb"
                    class="max-h-64 max-w-160"
                  />
                </RouterLink>
              </template>
            </BTableColumn>

            <BTableColumn :label="$t('image')" field="imageName" sortable>
              <template #default="props">
                <RouterLink
                  :to="`/project/${project.id}/image/${props.row.image}`"
                >
                  {{ props.row.imageName }}
                </RouterLink>
              </template>
            </BTableColumn>

            <BTableColumn :label="$t('duration')" field="time" sortable>
              <template #default="props">
                {{ props.row.time | duration('humanize') }}
              </template>
            </BTableColumn>

            <BTableColumn
              :label="$t('number-consultations')"
              field="frequency"
              centered
              sortable
            >
              <template #default="props">
                {{ props.row.frequency }}
              </template>
            </BTableColumn>

            <BTableColumn
              :label="$t('first-consultation')"
              field="first"
              centered
              sortable
            >
              <template #default="props">
                {{ Number(props.row.first) | date('ll LT') }}
              </template>
            </BTableColumn>

            <BTableColumn
              :label="$t('last-consultation')"
              field="last"
              centered
              sortable
            >
              <template #default="props">
                {{ Number(props.row.last) | date('ll LT') }}
              </template>
            </BTableColumn>

            <BTableColumn
              :label="$t('number-annotation-creations')"
              field="countCreatedAnnotations"
              centered
              sortable
            >
              <template #default="props">
                {{ props.row.countCreatedAnnotations }}
              </template>
            </BTableColumn>

            <template #empty>
              <div class="content has-text-grey has-text-centered">
                <p>{{ $t('no-image-consultation') }}</p>
              </div>
            </template>

            <template #footer>
              <p class="has-text-centered">
                <a
                  :href="consultations.downloadURL"
                  class="button is-link"
                  target="_self"
                >
                  {{ $t('button-export-as-csv') }}
                </a>
              </p>
            </template>

            <template #bottom-left>
              <BSelect v-model="consultationsPerPage" size="is-small">
                <option value="10">
                  {{ $t('count-per-page', { count: 10 }) }}
                </option>
                <option value="25">
                  {{ $t('count-per-page', { count: 25 }) }}
                </option>
                <option value="50">
                  {{ $t('count-per-page', { count: 50 }) }}
                </option>
                <option value="100">
                  {{ $t('count-per-page', { count: 100 }) }}
                </option>
              </BSelect>
            </template>
          </BTable>
        </template>
      </div>
    </div>
  </div>
</template>
      </div>
    </div>
  </div>
</template>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  User,
  ProjectConnectionCollection,
  ImageConsultationCollection,
} from 'cytomine-client';

import constants from '@/utils/constants.js';
import { fullName } from '@/utils/user-utils.js';

import CytomineDatepicker from '@/components/form/CytomineDatepicker.vue';
import ProjectConnectionDetails from '@/components/project/ProjectConnectionDetails.vue';
import LastConnectionsChart from '@/components/charts/LastConnectionsChart.js';

export default {
  name: 'MemberActivityDetails',
  components: {
    CytomineDatepicker,
    ProjectConnectionDetails,
    LastConnectionsChart,
  },
  data() {
    return {
      loading: true,
      error: false,
      user: null,
      resumeActivity: null,

      connections: null,
      connectionsPerPage: 10,

      consultations: null,
      consultationsPerPage: 10,

      lastUpdate: null,
      timeout: null,

      chartRevision: 0,
      period: 'day',
      startDate: dayjs().subtract(1, 'month').toDate(),
      endDate: null,
    };
  },
  computed: {
    /** @returns {any} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {number} */
    idUser() {
      return Number(this.$route.params.idUser);
    },
    /** @returns {any} */
    project() {
      return this.$store.state.currentProject.project;
    },
  },
  async created() {
    this.user = new User({ id: this.idUser });
    await this.fetchData();
    this.loading = false;
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  methods: {
    async fetchData() {
      try {
        await Promise.all([
          this.fetchUser(),
          this.fetchResumeActivity(),
          this.fetchConnections(),
          this.fetchConsultations(),
        ]);
      } catch (error) {
        console.log(error);
        this.error = true;
        return;
      }

      this.chartRevision++;
      this.lastUpdate = new Date();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        this.fetchData,
        constants.MEMBERS_ACTIVITY_REFRESH_INTERVAL
      );
    },
    async fetchUser() {
      await this.user.fetch();
      this.user.fullName = fullName(this.user);
    },
    async fetchResumeActivity() {
      this.resumeActivity = await this.user.fetchResumeActivity(
        this.project.id
      );
    },
    async fetchConnections() {
      this.connections = await ProjectConnectionCollection.fetchAll({
        project: this.project.id,
        user: this.idUser,
      });
    },
    async fetchConsultations() {
      this.consultations = await ImageConsultationCollection.fetchAll({
        project: this.project.id,
        user: this.idUser,
        resume: true,
      });
    },
  },
};
</script>

<style lang="scss">
.user-activity-wrapper {
  .table-wrapper {
    margin-bottom: 0;
  }
}
</style>
