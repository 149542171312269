var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.error)?_c('BMessage',{attrs:{"type":"is-danger","has-icon":"","icon-size":"is-small"}},[_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('error'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('unexpected-error-info-message')))])]):(!_vm.loading)?_c('table',{staticClass:"table"},_vm._l((_vm.customUITree),function(category){return _c('tbody',{key:category.label},[_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t(category.label))+" ")])]),_vm._l((category.props),function(prop){return _c('tr',{key:prop.key},[_c('td',[(prop.icon || prop.iconComponent)?_c('span',{staticClass:"icon relative mr-3"},[(prop.superscript)?_c('i',{staticClass:"absolute top-0 left-0 size-12",class:prop.superscript}):_vm._e(),(prop.icon)?_c('i',{class:prop.icon}):_c(prop.iconComponent,{tag:"component"})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(prop.label))+" ")]),_c('td',[_c('button',{class:[
            'button ',
            _vm.customUI[prop.key] && _vm.customUI[prop.key].ADMIN_PROJECT
              ? 'is-success'
              : 'is-danger' ],attrs:{"disabled":prop.key === 'project-configuration-tab'},on:{"click":function($event){return _vm.toggleProp(prop.key, 'ADMIN_PROJECT')}}},[_vm._v(" "+_vm._s(_vm.$t('manager'))+" ")])]),_c('td',[_c('button',{class:[
            'button',
            _vm.customUI[prop.key] && _vm.customUI[prop.key].CONTRIBUTOR_PROJECT
              ? 'is-success'
              : 'is-danger' ],attrs:{"disabled":prop.key === 'project-configuration-tab'},on:{"click":function($event){return _vm.toggleProp(prop.key, 'CONTRIBUTOR_PROJECT')}}},[_vm._v(" "+_vm._s(_vm.$t('contributor'))+" ")])])])})],2)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }