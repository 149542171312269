<template>
  <div class="list-members-wrapper">
    <BLoading :is-full-page="false" :active="loading" />
    <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>
    <template v-else-if="!loading">
      <div class="columns">
        <div class="column is-one-quarter">
          <BInput
            v-model="searchString"
            :placeholder="$t('search')"
            type="search"
            icon="search"
            data-label="MemberSearch"
          />
        </div>

        <div class="column is-one-quarter">
          <div class="flex align-center">
            <div class="mr-4">
              {{ $t('role') }}
            </div>
            <CytomineMultiselect
              v-model="selectedRoles"
              class="flex-grow"
              :options="availableRoles"
              multiple
              :searchable="false"
              label="label"
              track-by="value"
              data-label="RolesSelector"
            />
          </div>
        </div>
        <div class="column is-one-half has-text-right-desktop buttons">
          <IdxBtn
            color="primary"
            data-label="AddMemberBtn"
            @click="addMemberModal = true"
          >
            {{ $t('button-add-members') }}
          </IdxBtn>

          <IdxBtn
            :disabled="selectedMembers.length === 0"
            color="red"
            data-label="RemoveMemberBtn"
            @click="confirmMembersRemoval()"
          >
            {{ $t('button-remove-selected-members') }}
          </IdxBtn>
        </div>
      </div>

      <CytomineTable
        :collection="MemberCollection"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :sort.sync="sortField"
        :order.sync="sortOrder"
        :detailed="false"
        :checkable="true"
        :is-row-checkable="(row) => row.id !== currentUser.id"
        :checked-rows.sync="selectedMembers"
        :revision="revision"
      >
        <template #default>
          <BTableColumn
            v-slot="props"
            :label="$t('username')"
            field="username"
            sortable
            width="100"
          >
            {{ props.row.username }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('name')"
            field="fullName"
            sortable
            width="150"
          >
            {{ props.row.firstname }} {{ props.row.lastname }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('role')"
            field="projectRole"
            sortable
            width="50"
          >
            <IconProjectMemberRole
              :is-manager="props.row.role !== contributorRole.value"
              :is-representative="props.row.role === representativeRole.value"
              :editable="currentUser.admin"
              @toggleManager="confirmToggleManager(props.row)"
              @toggleRepresentative="toggleRepresentative(props.row)"
            />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('source')"
            field="origin"
            centered
            sortable
            width="50"
          >
            <span
              :class="{ ldap: props.row.LDAP }"
              class="tag ldap is-rounded is-info"
            >
              {{ displayMemberOrigin(props.row) }}
            </span>
          </BTableColumn>

          <BTableColumn v-slot="props" label="" centered width="50">
            <RouterLink
              :to="`/project/${project.id}/activity/user/${props.row.id}`"
              class="button is-small is-link"
              data-label="ViewUserActivityBtn"
            >
              {{ $t('button-view-activity') }}
            </RouterLink>
          </BTableColumn>
        </template>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-member-fitting-criteria') }}</p>
          </div>
        </template>

        <template #footer>
          <div class="has-text-centered">
            <a :href="exportURL" target="_self" class="button is-link">{{
              $t('button-export-as-csv')
            }}</a>
          </div>
        </template>
      </CytomineTable>

      <div class="radius-12 mt-4 p-4 bg-gray-1">
        <h2 class="mb-3">
          {{ $t('legend') }}
        </h2>
        <p class="mb-2">
          <IconProjectMemberRole /> : {{ $t('project-contributor') }}
        </p>
        <p class="mb-2">
          <IconProjectMemberRole :is-manager="true" /> :
          {{ $t('project-manager') }}
        </p>
        <p>
          <IconProjectMemberRole :is-manager="true" :is-representative="true" />
          : {{ $t('project-representative') }}
        </p>
      </div>

      <AddMemberModal
        :active.sync="addMemberModal"
        @addMembers="refreshMembers()"
      />
    </template>
  </div>
</template>

<script>
import {
  Cytomine,
  UserCollection,
  ProjectRepresentative,
} from 'cytomine-client';
import AddMemberModal from './AddMemberModal.vue';
import { fullName } from '@/utils/user-utils.js';
import CytomineTable from '@/components/utils/CytomineTable.vue';
import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';
import IconProjectMemberRole from '@/components/icons/IconProjectMemberRole.vue';

export default {
  name: 'ProjetMembers',
  components: {
    IconProjectMemberRole,
    CytomineTable,
    CytomineMultiselect,
    AddMemberModal,
  },
  data() {
    return {
      loading: true,
      error: false,

      addMemberModal: false,

      currentPage: 1,
      perPage: 25,
      sortField: '',
      sortOrder: '',

      searchString: '',

      contributorRole: {
        label: this.$t('contributor'),
        value: 'contributor',
      },
      managerRole: {
        label: this.$t('manager'),
        value: 'manager',
      },
      representativeRole: {
        label: this.$t('representative'),
        value: 'representative',
      },
      availableRoles: [],
      selectedRoles: [],
      selectedMembers: [],

      revision: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    project() {
      return this.$store.state.currentProject.project;
    },
    MemberCollection() {
      const collection = new UserCollection({
        filterKey: 'project',
        filterValue: this.project.id,
      });
      if (this.selectedRoles.length > 0) {
        collection['projectRole'] = {
          in: this.selectedRoles.map((option) => option.value).join(),
        };
      }
      if (this.searchString) {
        collection['fullName'] = {
          ilike: encodeURIComponent(this.searchString),
        };
      }

      return collection;
    },

    exportURL() {
      // TODO in core: should export only the filtered users
      return (
        Cytomine.instance.host +
        Cytomine.instance.basePath +
        `project/${this.project.id}/user/download?format=csv`
      );
    },
  },
  async created() {
    this.availableRoles = [
      this.contributorRole,
      this.managerRole,
      this.representativeRole,
    ];
    this.selectedRoles = this.availableRoles;
    this.revision++;
    this.loading = false;
  },

  methods: {
    displayMemberOrigin(member) {
      let key;
      if (member.origin === 'LDAP') key = 'LDAP';
      if (member.origin === 'BOOTSTRAP') key = 'system';
      else key = 'manual';

      return this.$t(key);
    },
    async refreshMembers() {
      try {
        this.revision++;
        await this.$store.dispatch('currentProject/fetchProjectMembers');
      } catch (error) {
        console.log(error);
        this.error = true;
      }
    },

    confirmMembersRemoval() {
      this.$buefy.dialog.confirm({
        title: this.$t('remove-members'),
        message: this.$tc(
          'remove-members-confirmation-message',
          this.selectedMembers.length,
          {
            count: this.selectedMembers.length,
            username: fullName(this.selectedMembers[0]),
          }
        ),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.removeSelectedMembers(),
      });
    },

    async removeSelectedMembers() {
      try {
        await this.project.deleteUsers(
          this.selectedMembers.map((member) => member.id)
        );
        await this.refreshMembers();
        this.$notify({
          type: 'success',
          text: this.$t('project-remove-members-notif-success'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-remove-members-notif-error'),
        });
      }
    },

    confirmToggleManager(member) {
      if (
        member.id === this.currentUser.id &&
        member.role !== this.contributorRole.value
      ) {
        this.$buefy.dialog.confirm({
          title: this.$t('remove-yourself-from-manager'),
          message: this.$tc(
            'remove-yourself-from-manager-confirmation-message'
          ),
          type: 'is-danger',
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
          onConfirm: () => this.toggleManager(member),
        });
      } else {
        this.toggleManager(member);
      }
    },
    async toggleManager(member) {
      try {
        if (member.role !== this.contributorRole.value) {
          if (member.role == this.representativeRole.value) {
            await ProjectRepresentative.delete(0, this.project.id, member.id);
          }
          await this.project.deleteAdmin(member.id);
        } else {
          await this.project.addAdmin(member.id);
        }
        this.revision++;

        if (member.id === this.currentUser.id) {
          await this.$store.dispatch('currentProject/fetchUIConfig');
          if (
            !this.$store.state.currentProject.configUI[
              'project-configuration-tab'
            ]
          ) {
            this.$router.push(`/project/${this.project.id}`);
          }
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-change-role', {
            username: fullName(member),
          }),
        });
      }
    },
    async toggleRepresentative(member) {
      try {
        if (member.role === this.representativeRole.value) {
          await ProjectRepresentative.delete(0, this.project.id, member.id);
        } else {
          await new ProjectRepresentative({
            user: member.id,
            project: this.project.id,
          }).save();
        }
        this.revision++;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-change-role', {
            username: fullName(member),
          }),
        });
      }
    },
  },
};
</script>

<style scoped>
.list-members-wrapper {
  min-height: 40vh;
}

.tag.ldap {
  background-color: #a0c0e6;
}
</style>
