export function Unique(arr, allowNull = true) {
  var o = {},
    a = [];
  for (var i = 0; i < arr.length; i++) o[arr[i]] = 1;
  for (var e in o) {
    if (allowNull || (!allowNull && e !== null && e !== undefined && e !== ''))
      a.push(e);
  }
  return a;
}

export function Union(arr1, arr2) {
  return Array.from(new Set([...arr1, ...arr2]));
}

/**
 * Simple object check.
 *
 * @param item
 * @returns {boolean}
 */
export function IsObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 *
 * @param target
 * @param ...sources
 * @param {...any} sources
 */
// Taken from here: https://stackoverflow.com/a/34749873/2638356
export function MergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (IsObject(target) && IsObject(source)) {
    for (const key in source) {
      if (IsObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        MergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return MergeDeep(target, ...sources);
}
