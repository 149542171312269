import {
  createColorStyle,
  createLineStyle,
  changeOpacity,
} from '@/utils/style-utils.js';

const initialTermsOpacity = 1;
const initialLayersOpacity = 0.5;
const initialPointSize = 1;

export default {
  state() {
    return {
      terms: null,

      displayNoTerm: true,
      noTermOpacity: initialTermsOpacity,
      noTermStyle: createColorStyle(
        '#fff',
        initialLayersOpacity * initialTermsOpacity,
        initialPointSize
      ),

      defaultStyle: createColorStyle(
        '#fff',
        initialLayersOpacity,
        initialPointSize
      ),
      multipleTermsStyle: createColorStyle(
        '#fff',
        initialLayersOpacity,
        initialPointSize
      ),

      layersOpacity: initialLayersOpacity,
      pointSize: initialPointSize,
    };
  },

  mutations: {
    addTerm(state, term) {
      state.terms.push(formatTerm(term, state.layersOpacity, state.pointSize));
    },

    setTerms(state, terms) {
      state.terms = terms;
    },

    setPointSize(state, size) {
      state.pointSize = size;
      state.defaultStyle = createColorStyle(
        '#fff',
        state.layersOpacity,
        state.pointSize
      );
      state.noTermStyle = createColorStyle(
        '#fff',
        state.layersOpacity * state.noTermOpacity,
        state.pointSize
      );
      state.multipleTermsStyle = createColorStyle(
        '#fff',
        state.layersOpacity,
        state.pointSize
      );
    },

    toggleTermVisibility(state, payload) {
      const [indexTerm, value] = payload;
      const term = state.terms[indexTerm];
      // if boolean value exists, use value. Otherwise set it to opposite of visible
      term.visible = value ?? !term.visible;
    },

    setDisplayNoTerm(state, value) {
      state.displayNoTerm = value;
    },

    setTermOpacity(state, { indexTerm, opacity }) {
      const term = state.terms[indexTerm];
      term.opacity = opacity;
      changeOpacity(term.olStyle, state.layersOpacity * opacity);
      changeOpacity(term.olLineStyle, state.layersOpacity * opacity);
    },

    setNoTermOpacity(state, opacity) {
      state.noTermOpacity = opacity;
      changeOpacity(state.noTermStyle, state.layersOpacity * opacity);
    },

    resetTermOpacities(state) {
      state.terms.forEach((term) => {
        term.opacity = initialTermsOpacity;
        changeOpacity(term.olStyle, state.layersOpacity * initialTermsOpacity);
        changeOpacity(
          term.olLineStyle,
          state.layersOpacity * initialTermsOpacity
        );
      });
      state.noTermOpacity = initialTermsOpacity;
      changeOpacity(
        state.noTermStyle,
        state.layersOpacity * state.noTermOpacity
      );
    },

    setLayersOpacity(state, opacity) {
      state.layersOpacity = opacity;
      if (state.terms) {
        state.terms.forEach((term) => {
          changeOpacity(term.olStyle, opacity * term.opacity);
          changeOpacity(term.olLineStyle, opacity * term.opacity);
        });
      }
      changeOpacity(state.noTermStyle, opacity * state.noTermOpacity);
      changeOpacity(state.multipleTermsStyle, opacity);
      changeOpacity(state.defaultStyle, opacity);
    },
  },

  actions: {
    initialize({ state, commit, rootGetters }) {
      const terms = formatTerms(
        rootGetters['currentProject/terms'],
        initialLayersOpacity,
        state.pointSize
      );
      commit('setTerms', terms);
    },

    toggleTermVisibility({ state, commit }, [indexTerm, value]) {
      commit('toggleTermVisibility', [indexTerm, value]);
      const toggledTerm = state.terms[indexTerm];
      if (!toggledTerm.visible) {
        commit('removeTermFromSelectedFeatures', {
          idTerm: toggledTerm.id,
          terms: state.terms,
        });
      }
    },

    setDisplayNoTerm({ commit }, value) {
      commit('setDisplayNoTerm', value);
      if (!value) {
        commit('removeNoTermFromSelectedFeatures');
      }
    },

    async refreshData({ state, commit, rootGetters }) {
      const terms = formatTerms(
        rootGetters['currentProject/terms'],
        state.layersOpacity,
        state.pointSize,
        state.terms
      );
      commit('setTerms', terms);
    },
  },

  getters: {
    termsMapping: (state) => {
      return state.terms.reduce((mapping, term) => {
        mapping[term.id] = term;
        return mapping;
      }, {});
    },
  },
};

/**
 * @param terms
 * @param layersOpacity
 * @param pointSize
 * @param previousTerms
 */
function formatTerms(terms, layersOpacity, pointSize, previousTerms = []) {
  if (!terms) {
    return;
  }

  const result = [];
  const nbTerms = terms.length;
  for (let i = 0; i < nbTerms; i++) {
    const term = terms[i];
    const prevTerm = previousTerms.find((prevTerm) => prevTerm.id === term.id);
    result.push(formatTerm(term, layersOpacity, pointSize));
  }
  return result;
}

/**
 * @param term
 * @param layersOpacity
 * @param pointSize
 */
function formatTerm(term, layersOpacity, pointSize) {
  const result = { id: term.id, pointSize: term.pointSize };
  result.opacity = initialTermsOpacity;
  result.olStyle = createColorStyle(
    term.color,
    initialTermsOpacity * layersOpacity,
    term.pointSize || pointSize
  );
  result.olLineStyle = createLineStyle(
    term.color,
    initialTermsOpacity * layersOpacity
  );
  result.visible = true;
  return result;
}
