<template>
  <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
    <h2 class="mb-3">
      {{ $t('error') }}
    </h2>
    <p>{{ $t('unexpected-error-info-message') }}</p>
  </BMessage>
  <table v-else-if="!loading" class="table">
    <tbody v-for="category in customUITree" :key="category.label">
      <tr>
        <th colspan="3">
          {{ $t(category.label) }}
        </th>
      </tr>
      <tr v-for="prop in category.props" :key="prop.key">
        <td>
          <span
            v-if="prop.icon || prop.iconComponent"
            class="icon relative mr-3"
          >
            <i
              v-if="prop.superscript"
              :class="prop.superscript"
              class="absolute top-0 left-0 size-12"
            />
            <i v-if="prop.icon" :class="prop.icon" />
            <component :is="prop.iconComponent" v-else />
          </span>
          {{ $t(prop.label) }}
        </td>
        <td>
          <button
            :class="[
              'button ',
              customUI[prop.key] && customUI[prop.key].ADMIN_PROJECT
                ? 'is-success'
                : 'is-danger',
            ]"
            :disabled="prop.key === 'project-configuration-tab'"
            @click="toggleProp(prop.key, 'ADMIN_PROJECT')"
          >
            {{ $t('manager') }}
          </button>
        </td>
        <td>
          <button
            :class="[
              'button',
              customUI[prop.key] && customUI[prop.key].CONTRIBUTOR_PROJECT
                ? 'is-success'
                : 'is-danger',
            ]"
            :disabled="prop.key === 'project-configuration-tab'"
            @click="toggleProp(prop.key, 'CONTRIBUTOR_PROJECT')"
          >
            {{ $t('contributor') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import IconPolygonFreeHand from '@/components/icons/IconPolygonFreeHand.vue';
import IconLineFreeHand from '@/components/icons/IconLineFreeHand.vue';

export default {
  name: 'CustomUiProject',
  data() {
    return {
      loading: true,
      error: false,

      customUI: {},
      // prettier-ignore
      customUITree: [
        {
          label: 'images-list',
          props: [
            {
              key: 'project-qc-field',
              label: 'qc',
              icon: 'fas fa-clipboard'
            },
            {
              key: 'project-data-field',
              label: 'data',
              icon: 'far fa-chart-bar'
            },
            {
              key: 'project-status-field',
              label: 'status',
              icon: 'fas fa-play-circle'
            },
            {
              key: 'project-manual-annotations-field',
              label: 'user-annotations',
              icon: 'far fa-edit'
            },
            {
              key: 'project-image-properties-field',
              label: 'image-properties',
              icon: 'fas fa-file-image'
            },
            {
              key: 'project-image-slide-label-field',
              label: 'image-slide-label',
              icon: 'fas fa-file-alt'
            },
          ]
        },
        {
          label: 'project-side-bar',
          props: [
            {
              key: 'project-images-tab',
              label: 'images',
              icon: 'far fa-images' 
            },
            {
              key: 'project-annotations-tab',
              label: 'annotations',
              icon: 'far fa-edit' 
            },
            {
              key: 'project-jobs-tab',
              label: 'analysis',
              icon: 'fas fa-tasks' 
            },
            {
              key: 'project-activities-tab',
              label: 'activity',
              icon: 'fas fa-tachometer-alt' 
            },
            {
              key: 'project-information-tab',
              label: 'information',
              icon: 'fas fa-info-circle' 
            },
            {
              key: 'project-results-tab',
              label: 'results',
              icon: 'fas fa-file-invoice' 
            },
            {
              key: 'project-visualizations-tab',
              label: 'visualizations',
              icon: 'fas fa-chart-bar' 
            },
            {
              key: 'project-configuration-tab',
              label: 'configuration',
              icon: 'fas fa-cogs' 
            }
          ]
        },
        {
          label: 'viewer-panels',
          props: [
            {
              key: 'project-explore-hide-tools',
              label: 'all-panels' 
            }, // hide all panels
            {
              key: 'project-explore-overview',
              label: 'overview',
              icon: 'fas fa-image' 
            },
            {
              key: 'project-explore-channel-manipulation',
              label: 'channels',
              icon: 'fas fa-bars'
            },
            {
              key: 'project-explore-results-panel',
              label: 'results-panel',
              icon: 'fas fa-poll'
            },
            {
              key: 'project-explore-info',
              label: 'information',
              icon: 'fas fa-info' 
            },
            {
              key: 'project-explore-digital-zoom',
              label: 'digital-zoom',
              icon: 'fas fa-search' 
            },
            {
              key: 'project-explore-link',
              label: 'link-images',
              icon: 'fas fa-link' 
            },
            {
              key: 'project-explore-color-manipulation',
              label: 'colors',
              icon: 'fas fa-adjust' 
            },
            {
              key: 'project-explore-image-layers',
              label: 'annotation-layers',
              icon: 'fas fa-copy' 
            },
            {
              key: 'project-explore-ontology',
              label: 'ontology',
              icon: 'fas fa-hashtag' 
            },
            {
              key: 'project-explore-property',
              label: 'properties',
              icon: 'fas fa-tag' 
            },
            // {
            //   key: 'project-explore-follow',
            //   label: 'broadcast',
            //   icon: 'fas fa-street-view' 
            // },
            {
              key: 'project-explore-review',
              label: 'review',
              icon: 'fas fa-check-circle' 
            },
          ]
        },
        {
          label: 'annotation-details',
          props: [
            // display annotation details in viewer
            {
              key: 'project-explore-annotation-main',
              label: 'annotation-details-box' 
            },
            {
              key: 'project-explore-annotation-geometry-info',
              label: 'geometry-info',
              icon: 'fas fa-ruler-combined' 
            },
            {
              key: 'project-explore-annotation-description',
              label: 'description',
              icon: 'far fa-file-alt' 
            },
            {
              key: 'project-explore-annotation-terms',
              label: 'terms',
              icon: 'fas fa-hashtag' 
            },
            // Tags hidden until scoped access per user per project
            // {
            //   key: 'project-explore-annotation-tags',
            //   label: 'tags',
            //   icon: 'fas fa-bookmark' 
            // },
            {
              key: 'project-explore-annotation-properties',
              label: 'properties',
              icon: 'fas fa-tag' 
            },
            {
              key: 'project-explore-annotation-attached-files',
              label: 'attached-files',
              icon: 'fas fa-paperclip' 
            },
            {
              key: 'project-explore-annotation-creation-info',
              label: 'creation-info',
              icon: 'fas fa-info' 
            },
            {
              key: 'project-explore-annotation-comments',
              label: 'comments',
              icon: 'fas fa-comment' 
            }

          ]
        },
        {
          label: 'draw-tools',
          props: [
            {
              key: 'project-tools-main',
              label: 'all-draw-tools' 
            },

            {
              key: 'project-tools-select',
              label: 'select',
              icon: 'fas fa-mouse-pointer' 
            },

            {
              key: 'project-tools-brush',
              label: 'brush',
              icon: 'fas fa-paint-brush' 
            },
            {
              key: 'project-tools-point',
              label: 'point',
              icon: 'fas fa-map-marker-alt' 
            },
            {
              key: 'project-tools-line',
              label: 'line',
              icon: 'fas fa-minus' 
            },
            {
              key: 'project-tools-freehand-line',
              label: 'freehand-line',
              iconComponent: IconLineFreeHand 
            },
            {
              key: 'project-tools-rectangle',
              label: 'rectangle',
              icon: 'far fa-square' 
            },
            {
              key: 'project-tools-circle',
              label: 'circle',
              icon: 'far fa-circle' 
            },
            {
              key: 'project-tools-polygon',
              label: 'polygon',
              icon: 'fas fa-draw-polygon' 
            },
            {
              key: 'project-tools-freehand-polygon',
              label: 'freehand-polygon',
              iconComponent: IconPolygonFreeHand 
            },
            {
              key: 'project-tools-union',
              label: 'freehand-correct-add',
              icon: 'fas fa-pencil-alt',
              superscript: 'fas fa-plus' 
            },
            {
              key: 'project-tools-diff',
              label: 'freehand-correct-remove',
              icon: 'fas fa-pencil-alt',
              superscript: 'fas fa-minus' 
            },

            {
              key: 'project-tools-fill',
              label: 'fill',
              icon: 'fas fa-fill' 
            },
            {
              key: 'project-tools-edit',
              label: 'modify',
              icon: 'fas fa-edit' 
            },
            {
              key: 'project-tools-move',
              label: 'move',
              icon: 'fas fa-arrows-alt' 
            },
            {
              key: 'project-tools-rotate',
              label: 'rotate',
              icon: 'fas fa-sync-alt' 
            },
            {
              key: 'project-tools-delete',
              label: 'delete',
              icon: 'fas fa-trash-alt' 
            },

            {
              key: 'project-tools-undo-redo',
              label: 'undo-redo',
              icon: 'fas fa-undo' 
            },
          ]
        }
      ]
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
  },
  async created() {
    try {
      this.customUI = await this.project.fetchUIConfig();
    } catch (error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  },
  methods: {
    async toggleProp(prop, userType) {
      try {
        this.customUI[prop][userType] = !this.customUI[prop][userType];
        this.customUI = await this.project.saveUIConfig(this.customUI);
        this.$store.dispatch('currentProject/fetchUIConfig');
      } catch (error) {
        console.log(error);
        this.customUI[prop][userType] = !this.customUI[prop][userType]; // revert change
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-custom-ui-change'),
        });
      }
    },
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}

.icon svg {
  height: 1.15em !important;
}
</style>
