<template>
  <div id="chart-controls-container" class="p-5">
    <b-field :label="$t('chart-type')">
      <b-select
        v-model="selectedChartType"
        expanded
        @input="$emit('update:selectedChartType', selectedChartType)"
      >
        <option v-for="(value, key) in chartTypes" :key="key" :value="value">
          {{ value }}
        </option>
      </b-select>
    </b-field>
    <hr style="background-color:#eaeaea;" />
    <!-- X Axis -->
    <b-field
      v-if="chartConfig.xAxis"
      :label="
        $t('x-axis') + (chartConfig.xAxisGrouped ? ' ' + $t('grouped') : '')
      "
    >
      <b-select v-model="xAxis" @input="$emit('update:xAxis', xAxis)">
        <option
          v-for="value in dataLabels"
          :key="value.field"
          :value="value.field"
        >
          {{ value.label }}
        </option>
      </b-select>
    </b-field>
    <!-- Y Axis -->
    <b-field v-if="chartConfig.yAxis" :label="$t('y-axis')">
      <b-select v-model="yAxis" @input="$emit('update:yAxis', yAxis)">
        <option
          v-for="value in dataLabels"
          :key="value.field"
          :value="value.field"
        >
          {{ value.label }}
        </option>
      </b-select>
    </b-field>

    <!-- Incase we ever need a multi-select -->
    <!-- <b-field
      v-if="selectedChartType === chartType.SCATTER"
      :label="$t('x-axis')"
    >
      <CytomineMultiselect
        v-model="xAxisModel"
        :options="dataLabels"
        :allow-empty="false"
        :searchable="false"
        label="label"
        track-by="field"
        multiple
      />
    </b-field> -->
  </div>
</template>

<script>
import { CHART_TYPES } from '@/types/chart-types.js';
import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';

export default {
  components: {
    CytomineMultiselect,
  },
  props: {
    dataLabels: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    chartTypes: Object.values(CHART_TYPES),
    chartType: CHART_TYPES,
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    chartConfig() {
      return this.$store.state.projectVisualizations.chartConfig;
    },
    selectedChartType: {
      get() {
        return this.$store.getters['projectVisualizations/getSelectedChartData']
          ?.selectedChartType;
      },
      set(value) {
        this.$store.commit('projectVisualizations/setSelectedChartType', value);
      },
    },
    xAxis: {
      get() {
        return this.$store.getters['projectVisualizations/getSelectedChartData']
          ?.xAxis[0];
      },
      set(value) {
        if (!Array.isArray(value)) {
          value = [value];
        }
        value = value.map((val) => (typeof val !== 'string' ? val.field : val));
        this.$store.commit('projectVisualizations/setXAxis', value);
      },
    },
    yAxis: {
      get() {
        return this.$store.getters['projectVisualizations/getSelectedChartData']
          ?.yAxis[0];
      },
      set(value) {
        if (!Array.isArray(value)) {
          value = [value];
        }
        this.$store.commit('projectVisualizations/setYAxis', value);
      },
    },
    xAxisModel: {
      get() {
        return this.dataLabels.filter((d) => this.xAxis.includes(d.field));
      },
      set(val) {
        this.xAxis = val.map((d) => d.field);
      },
    },
  },
};
</script>

<style scoped>
#chart-controls-container {
  background-color: #27b4f70d;
  border: 1px solid #27b4f7;
  min-width: 300px;
  max-width: 300px;
}
</style>
