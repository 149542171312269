<template>
  <div>
    <h2 class="mb-2 text-center">
      {{ $t('digital-zoom') }}
    </h2>
    <BCheckbox v-model="digitalZoom">
      {{ $t('digital-zoom-checkbox-label') }}
    </BCheckbox>
  </div>
</template>

<script>
export default {
  name: 'DigitalZoom',
  props: {
    index: {
      type: String,
      required: true,
    },
  },
  computed: {
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    digitalZoom: {
      get() {
        return this.imageWrapper.view.digitalZoom;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setDigitalZoom', Boolean(value));
      },
    },
  },
};
</script>
