<template>
  <div ref="playground" class="annotation-details-playground absolute">
    <VueDraggableResizable
      v-if="selectedFeature && selectedFeature.properties && reload"
      v-show="displayAnnotDetails"
      :parent="true"
      :resizable="false"
      :w="width"
      :h="height"
      :x="positionAnnotDetails.x"
      :y="positionAnnotDetails.y"
      class="
        draggable
        flex flex-column
        border-1 border-gray-3
        radius-4
        bg-gray-1
      "
      drag-handle=".drag"
      @dragstop="dragStop"
    >
      <div class="flex align-center border-b-1 border-gray-3 p-1 bg-gray-2">
        <h2 class="flex-grow m-0 size-16">
          {{ $t('current-selection') }}
        </h2>
        <IdxBtn small class="drag close ml-1 w-28">
          <i class="fas fa-arrows-alt" />
        </IdxBtn>
        <IdxBtn
          small
          class="close ml-1 w-28"
          @click="displayAnnotDetails = false"
        >
          <i class="fas fa-times" />
        </IdxBtn>
      </div>

      <div class="p-2 overflow-auto h-full">
        <AnnotationDetails
          :key="selectedFeature.id"
          :index="index"
          :annotation="selectedFeature.properties.annot"
          :terms="terms"
          :users="allUsers"
          :show-image-info="false"
          :show-comments="showComments"
          @addTerm="addTerm"
          @updateTerms="updateTerms"
          @updateProperties="updateProperties"
          @centerView="centerViewOnAnnot"
          @deletion="handleDeletion"
        />
      </div>
    </VueDraggableResizable>
  </div>
</template>

<script>
import { UserCollection, UserJobCollection } from 'cytomine-client';
import VueDraggableResizable from 'vue-draggable-resizable';
import WKT from 'ol/format/WKT.js';

import noteApi from '../../services/noteApi.js';
import { fullName } from '@/utils/user-utils.js';
import { Action, updateTermProperties } from '@/utils/annotation-utils.js';
import AnnotationDetails from '@/components/annotations/AnnotationDetails.vue';

export default {
  name: 'AnnotationDetailsContainer',
  components: {
    VueDraggableResizable,
    AnnotationDetails,
  },
  props: {
    index: { type: String, default: '' },
    view: { type: Object, default: () => {} },
  },
  data() {
    return {
      width: 320,
      height: 500,
      users: [],
      userJobs: [],
      reload: true,
      format: new WKT(),
      showComments: false,
      annotProperties: [],
    };
  },
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    displayAnnotDetails: {
      get() {
        return this.imageWrapper.selectedFeatures.displayAnnotDetails;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setDisplayAnnotDetails', value);
      },
    },
    positionAnnotDetails: {
      get() {
        return this.imageWrapper.selectedFeatures.positionAnnotDetails;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setPositionAnnotDetails', value);
      },
    },
    selectedFeature() {
      return this.$store.getters[this.imageModule + 'selectedFeature'];
    },
    allUsers() {
      const allUsers = this.users.concat(this.userJobs);
      allUsers.forEach((user) => (user.fullName = fullName(user)));
      return allUsers;
    },
    annot() {
      return this.selectedFeature ? this.selectedFeature.properties.annot : {};
    },
    terms() {
      return this.$store.getters['currentProject/terms'] || [];
    },
    annotWithProperties() {
      return {
        ...this.selectedFeature.properties.annot,
        properties: this.annotProperties, // array of objects
        // REVISIT: not functioning as expected,
        // using PropertyCollection class for now
      };
    },
  },
  watch: {
    selectedFeature: {
      handler() {
        if (this.selectedFeature) {
          this.displayAnnotDetails = true;
          const targetAnnot = this.imageWrapper.selectedFeatures.showComments;
          this.showComments = targetAnnot === this.annot.id;
          if (targetAnnot !== null) {
            this.$store.commit(this.imageModule + 'setShowComments', null);
          }
          // this.fetchProperties();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchUsers();
    this.fetchUserJobs();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$eventBus.$on('updateMapSize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.$eventBus.$off('updateMapSize', this.handleResize);
  },
  methods: {
    async fetchProperties() {
      try {
        const response = await noteApi.get(
          `/api/domain/${this.selectedFeature.properties.annot.class}/${this.selectedFeature.properties.annot.id}/property.json?max=0&offset=0`
        );
        this.annotProperties = response.collection;
      } catch (error) {
        console.log(error);
        this.error = true;
      }
    },
    async fetchUsers() {
      const results = await UserCollection.fetchAll({
        filterKey: 'project',
        filterValue: this.project.id,
      });
      this.users = results.array;
    },
    async fetchUserJobs() {
      this.userJobs = (
        await UserJobCollection.fetchAll({
          filterKey: 'project',
          filterValue: this.image.project,
        })
      ).array;
    },

    centerViewOnAnnot() {
      const geometry = this.format.readGeometry(this.annot.location);
      this.view.fit(geometry, {
        duration: 500,
        padding: [10, 10, 10, 10],
        maxZoom: this.image.depth,
      });
    },

    addTerm(term) {
      this.$store.dispatch(this.viewerModule + 'addTerm', term);
    },

    async updateTerms() {
      const updatedAnnot = await this.annot.clone().fetch();
      await updateTermProperties(updatedAnnot);

      this.$eventBus.$emit('editAnnotations', [updatedAnnot]);
      this.$store.commit(this.imageModule + 'changeAnnotSelectedFeature', {
        indexFeature: 0,
        annot: updatedAnnot,
      });
    },

    updateProperties() {
      this.$store.dispatch(this.imageModule + 'refreshProperties', this.index);
    },

    handleDeletion() {
      this.$store.commit(this.imageModule + 'addAction', {
        annots: [this.annot],
        type: Action.DELETE,
      });
      this.$eventBus.$emit('deleteAnnotations', [this.annot]);
    },

    dragStop(x, y) {
      this.positionAnnotDetails = {
        x,
        y,
      };
    },

    async handleResize() {
      await this.$nextTick(); // wait for update of clientWidth and clientHeight to their new values

      if (this.$refs.playground) {
        const maxX = Math.max(
          this.$refs.playground.clientWidth - this.width,
          0
        );
        const maxY = Math.max(
          this.$refs.playground.clientHeight - this.height,
          0
        );
        const x = Math.min(this.positionAnnotDetails.x, maxX);
        const y = Math.min(this.positionAnnotDetails.y, maxY);
        this.positionAnnotDetails = {
          x,
          y,
        };

        // HACK to force the component to recreate and take into account new (x,y) ; should no longer be
        // necessary with version 2 of vue-draggable-resizable
        this.reload = false;
        this.$nextTick(() => (this.reload = true));
      }
    },
  },
};
</script>

<style scoped>
.annotation-details-playground {
  inset: 3.5rem 4.5rem 2em 3.5rem;
  pointer-events: none; /* to allow selection of elements below it */
}

.draggable {
  pointer-events: auto;
}
</style>

<style>
.dragging .button.drag {
  background-color: #6899d0;
  color: #fff;
}

.annotation-details-playground .draggable {
  z-index: 15 !important;
}
</style>
