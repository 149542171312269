<template>
  <div>
    <h2 class="text-center">
      {{ $t('information') }}
    </h2>
    <table class="grid table mb-2" style="grid-template-columns: 1fr auto;">
      <tbody>
        <tr>
          <td class="p-1">
            <strong>{{ $t('name') }}</strong>
          </td>
          <td class="p-1">
            <ImageName :image="image" show-both-names />
          </td>
        </tr>
        <tr>
          <td class="p-1">
            <strong>{{ $t('width') }}</strong>
          </td>
          <td class="p-1">
            {{ image.width }}
          </td>
        </tr>
        <tr>
          <td class="p-1">
            <strong>{{ $t('height') }}</strong>
          </td>
          <td class="p-1">
            {{ image.height }}
          </td>
        </tr>
        <tr>
          <td class="p-1">
            <strong>{{ $t('resolution') }}</strong>
          </td>
          <td class="p-1">
            {{ resolution }}
          </td>
        </tr>
        <tr>
          <td class="p-1">
            <strong>{{ $t('magnification') }}</strong>
          </td>
          <td class="p-1">
            {{ magnification }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="p-1">
            <div class="flex gap-8 justify-center">
              <IdxBtn v-if="canEdit" small @click="showCalibrationModal">
                {{ $t('button-set-calibration') }}
              </IdxBtn>
              <IdxBtn
                :to="`/project/${image.project}/image/${image.id}/information`"
                small
              >
                {{ $t('button-more-info') }}
              </IdxBtn>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="p-1">
            <div class="flex justify-center navigation has-addons">
              <IdxBtn :disabled="isFirstImage" small @click="previousImage()">
                <i class="mr-2 fas fa-angle-left fa-lg" />
                {{ $t('image-previous') }}
              </IdxBtn>
              <IdxBtn :disabled="isLastImage" small @click="nextImage()">
                {{ $t('image-next') }}
                <i class="ml-2 fas fa-angle-right fa-lg" />
              </IdxBtn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ImageName from './../../image/ImageName.vue';

export default {
  name: 'InformationPanel',
  components: {
    ImageName,
  },
  props: {
    index: String,
  },
  data() {
    return {
      calibrationModal: false,
      isFirstImage: false,
      isLastImage: false,
    };
  },
  computed: {
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    image() {
      return this.viewerWrapper.images[this.index].imageInstance;
    },
    resolution() {
      if (this.image.resolution) {
        return this.image.resolution.toFixed(3);
      } else {
        return this.$t('unknown');
      }
    },
    magnification() {
      return this.image.magnification || this.$t('unknown');
    },
    canEdit() {
      return this.$store.getters['currentProject/canEditImage'](this.image);
    },
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
  },
  mounted() {
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  },
  beforeDestroy() {
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    showCalibrationModal() {
      this.$eventBus.$emit('showCalibration', true);
    },
    async previousImage() {
      try {
        const prev = await this.image.fetchNext();
        if (!prev.id) {
          this.$notify({
            type: 'warn',
            text: this.$t('first-image-warning'),
          });
          this.isFirstImage = true;
        } else {
          await this.$store.dispatch(
            this.imageModule + 'setImageInstance',
            prev
          );
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('previous-image-fetch-error'),
        });
      }
    },
    async nextImage() {
      try {
        const next = await this.image.fetchPrevious();
        if (!next.id) {
          this.$notify({
            type: 'warn',
            text: this.$t('last-image-warning'),
          });
          this.isLastImage = true;
        } else {
          await this.$store.dispatch(
            this.imageModule + 'setImageInstance',
            next
          );
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('next-image-fetch-error'),
        });
      }
    },
    shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }

      if (key === 'nav-next-image') {
        this.nextImage();
      } else if (key === 'nav-previous-image') {
        this.previousImage();
      }
    },
  },
};
</script>
