/* eslint no-undef: 0 */

/* Sources:
 * https://www.dfstudios.co.uk/articles/programming/image-programming-algorithms/
 * https://stackoverflow.com/a/8510751
 * http://alienryderflex.com/saturation.html
 */

/**
 * @param contrast
 */
function computeContrastFactorFunc(contrast) {
  return (259 * (contrast + 255)) / 255 / (259 - contrast);
}

/**
 * @param hue
 */
function computeHueMatrixFunc(hue) {
  const angle = (hue * Math.PI) / 180;
  const cosA = Math.cos(angle);
  const sinA = Math.sin(angle);
  const sqrtThird = Math.sqrt(1 / 3);
  const hueMatrix = [
    [
      cosA + (1 - cosA) / 3,
      (1 / 3) * (1 - cosA) - sqrtThird * sinA,
      (1 / 3) * (1 - cosA) + sqrtThird * sinA,
    ],
    [
      (1 / 3) * (1 - cosA) + sqrtThird * sinA,
      cosA + (1 / 3) * (1 - cosA),
      (1 / 3) * (1 - cosA) - sqrtThird * sinA,
    ],
    [
      (1 / 3) * (1 - cosA) - sqrtThird * sinA,
      (1 / 3) * (1 - cosA) + sqrtThird * sinA,
      cosA + (1 / 3) * (1 - cosA),
    ],
  ];
  return hueMatrix;
}

/**
 * @param val
 */
function truncatePixelValueFunc(val) {
  return val < 0 ? 0 : val > 255 ? 255 : val;
}

/**
 * @param pixel
 * @param newContrastFactor
 */
function changeBrightnessContrastFunc(pixel, newContrastFactor = null) {
  newContrastFactor = newContrastFactor || contrastFactor;
  for (let i = 0; i < 3; i++) {
    const newVal =
      truncatePixelValue(newContrastFactor * (pixel[i] - 128) + 128) +
      brightness;
    pixel[i] = truncatePixelValue(newVal);
  }
}

/**
 * @param pixel
 * @param saturation
 */
function changeSaturationFunc(pixel) {
  const r = pixel[0];
  const g = pixel[1];
  const b = pixel[2];

  const saturationConstant = Math.sqrt(r * r * Pr + g * g * Pg + b * b * Pb);

  for (let i = 0; i < 3; i++) {
    const newVal =
      saturationConstant +
      (pixel[i] - saturationConstant) * (1 + saturation / 100);
    pixel[i] = truncatePixelValue(newVal);
  }
}

/**
 * @param pixel
 * @param newHueMatrix
 */
function changeHueFunc(pixel, newHueMatrix = null) {
  newHueMatrix = newHueMatrix || hueMatrix;
  const r = pixel[0];
  const g = pixel[1];
  const b = pixel[2];

  for (let i = 0; i < 3; i++) {
    const newVal =
      newHueMatrix[i][0] * r + newHueMatrix[i][1] * g + newHueMatrix[i][2] * b;
    pixel[i] = truncatePixelValue(newVal);
  }
}

export const constLib = {
  Pr: 0.299,
  Pg: 0.587,
  Pb: 0.114,

  truncatePixelValue: truncatePixelValueFunc,
  changeBrightnessContrast: changeBrightnessContrastFunc,
  changeSaturation: changeSaturationFunc,
  changeHue: changeHueFunc,

  computeContrastFactor: computeContrastFactorFunc,
  computeHueMatrix: computeHueMatrixFunc,
  contrastFactor: -1,
  hueMatrix: -1,
};

/**
 * @param pixels
 */
export function operation(pixels) {
  const pixel = pixels[0];

  let newContrastFactor = contrastFactor;
  if (brightness !== 0 || contrast !== 0) {
    if (contrastFactor === -1) {
      newContrastFactor = computeContrastFactor(contrast);
    }
    changeBrightnessContrast(pixel, newContrastFactor);
  }

  if (saturation !== 0) {
    changeSaturation(pixel);
  }

  let newHueMatrix = hueMatrix;
  if (hue !== 0) {
    if (hueMatrix === -1) {
      newHueMatrix = computeHueMatrix(hue);
    }
    changeHue(pixel, newHueMatrix);
  }

  return pixel;
}
