import ZoomifySource from 'ol/source/Zoomify';
import { createFromTileUrlFunctions } from 'ol/tileurlfunction';
import TileGrid from 'ol/tilegrid/TileGrid';
import { DEFAULT_TILE_SIZE } from 'ol/tilegrid/common';

// source: https://github.com/openlayers/openlayers/blob/v5.3.0/src/ol/source/Zoomify.js#L202
// temporary hack since Zoomify does not support multiple URLs (https://github.com/openlayers/openlayers/issues/9352)
function createFromTemplate(tileCountUpToTier, tierSizeInTiles) {
  return (template) => {
    return (tileCoord) => {
      if (!tileCoord) {
        return undefined;
      } else {
        const tileCoordZ = tileCoord[0];
        const tileCoordX = tileCoord[1];
        const tileCoordY = tileCoord[2];
        const tileIndex =
          tileCoordX + tileCoordY * tierSizeInTiles[tileCoordZ][0];
        const tileSize = DEFAULT_TILE_SIZE;
        const tileWidth = Array.isArray(tileSize) ? tileSize[0] : tileSize;
        const tileGroup =
          ((tileIndex + tileCountUpToTier[tileCoordZ]) / tileWidth) | 0;
        const localContext = {
          z: tileCoordZ,
          x: tileCoordX,
          y: tileCoordY,
          tileIndex: tileIndex,
          TileGroup: 'TileGroup' + tileGroup,
        };
        return template.replace(/\{(\w+?)\}/g, function (m, p) {
          return localContext[p];
        });
      }
    };
  };
}

export const GetZoomifySource = (projection, urls, size, extent) => {
  const imageWidth = size[0];
  const imageHeight = size[1];
  const tierSizeInTiles = [];
  let tileSizeForTierSizeCalculation = DEFAULT_TILE_SIZE;
  while (
    imageWidth > tileSizeForTierSizeCalculation ||
    imageHeight > tileSizeForTierSizeCalculation
  ) {
    tierSizeInTiles.push([
      Math.ceil(imageWidth / tileSizeForTierSizeCalculation),
      Math.ceil(imageHeight / tileSizeForTierSizeCalculation),
    ]);
    tileSizeForTierSizeCalculation += tileSizeForTierSizeCalculation;
  }
  tierSizeInTiles.push([1, 1]);
  tierSizeInTiles.reverse();

  const resolutions = [1];
  const tileCountUpToTier = [0];
  for (let i = 1, ii = tierSizeInTiles.length; i < ii; i++) {
    resolutions.push(1 << i);
    tileCountUpToTier.push(
      tierSizeInTiles[i - 1][0] * tierSizeInTiles[i - 1][1] +
        tileCountUpToTier[i - 1]
    );
  }
  resolutions.reverse();

  const source = new ZoomifySource({
    crossOrigin: 'Anonymous',
    projection: projection,
    size: size,
    url: '',
    extent: extent,
    transition: 0,
    tileSize: DEFAULT_TILE_SIZE,
  });

  if (urls) {
    const tileUrlFunction = createFromTileUrlFunctions(
      urls.map(createFromTemplate(tileCountUpToTier, tierSizeInTiles))
    );
    source.setTileUrlFunction(tileUrlFunction);
  }
  return source;
};
