<template>
  <div>
    <h2 class="text-center mb-2">
      {{ $t('terms') }}
    </h2>
    <div class="flex justify-right sticky border-b border-grey-2 pb-2 top-0">
      <!-- border: 2px solid #dbdbdb; -->
      <div class="px-4 flex-grow">
        <BInput
          v-model="searchString"
          :label="$t('search')"
          :placeholder="$t('search')"
          size="is-small"
          expanded
        />
      </div>

      <div class="flex align-center pr-2">
        <div class="flex justify-center h-auto">
          <i class="far fa-eye p-1" />
          <BCheckbox
            v-model="isAllTermsChecked"
            size="is-small"
            :disabled="terms.length === 0"
          />
        </div>
        <div class="block w-96 text-center">
          {{ $t('opacity') }}
        </div>
      </div>
    </div>
    <div class="overflow-auto mb-1 max-h-240">
      <OntologyTree
        :ontology="ontology"
        :allow-selection="false"
        :search-string="searchString"
        @termsChanged="onTermsChanged"
      >
        <template #custom-sidebar="{ term }">
          <div class="flex align-center pr-2">
            <div class="visibility flex justify-center">
              <BCheckbox
                v-if="term.id"
                :value="terms[termsMapping[term.id]].visible"
                size="is-small"
                @input="toggleTermVisibility(termsMapping[term.id])"
              />

              <BCheckbox v-else v-model="displayNoTerm" size="is-small" />
            </div>

            <div class="flex w-96">
              <input
                v-if="term.id"
                :disabled="!terms[termsMapping[term.id]].visible"
                :value="terms[termsMapping[term.id]].opacity"
                class="slider m-0 is-small"
                step="0.05"
                min="0"
                max="1"
                type="range"
                aria-label="Opacity"
                @change="(event) => changeOpacity(termsMapping[term.id], event)"
                @input="(event) => changeOpacity(termsMapping[term.id], event)"
              />

              <input
                v-else
                v-model="noTermOpacity"
                class="slider m-0 is-small"
                step="0.05"
                min="0"
                max="1"
                type="range"
                aria-label="No opacity"
              />
            </div>
          </div>
        </template>
      </OntologyTree>
    </div>
    <div class="has-text-right">
      <button class="button is-small" @click="resetOpacities">
        {{ $t('button-reset-opacities') }}
      </button>
    </div>
  </div>
</template>

<script>
import OntologyTree from '@/components/ontology/OntologyTree.vue';

export default {
  name: 'OntologyPanel',
  components: { OntologyTree },
  props: {
    index: { type: String, required: true },
  },
  data() {
    return {
      searchString: '',
      isSelectedTermsUpdated: false,
      selectedTerms: [],
      visibleTerms: [],
    };
  },
  computed: {
    /** @returns {object} */
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    /** @returns {string} */
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /** @returns {object} */
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    /** @returns {object} */
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    /** @returns {boolean} */
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    /** @returns {Array} */
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    /** @returns {boolean} */
    noActiveLayer() {
      return !this.layers.find((layer) => layer.drawOn);
    },
    /** @returns {object} */
    terms() {
      return this.imageWrapper.style.terms;
    },
    /** @returns {object} */
    termsMapping() {
      const mapping = {};
      this.terms.forEach((term, idx) => (mapping[term.id] = idx));
      return mapping;
    },
    /** @returns {object[]} */
    additionalNodes() {
      return [
        {
          id: 0,
          name: this.$t('no-term'),
        },
      ];
    },
    displayNoTerm: {
      /** @returns {boolean} */
      get() {
        return this.imageWrapper.style.displayNoTerm;
      },
      /** @param {boolean} value */
      set(value) {
        this.$store.dispatch(this.imageModule + 'setDisplayNoTerm', value);
      },
    },
    noTermOpacity: {
      /** @returns {number} */
      get() {
        return this.imageWrapper.style.noTermOpacity;
      },
      /** @param {number} value */
      set(value) {
        this.$store.commit(
          this.imageModule + 'setNoTermOpacity',
          Number(value)
        );
      },
    },
    isAllTermsChecked: {
      get() {
        /** @returns {boolean} */
        return this.visibleTerms.every(
          (term) => this.terms[this.termsMapping[term.id]].visible
        );
      },
      set(isVisible) {
        for (const term of this.visibleTerms) {
          this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [
            this.termsMapping[term.id],
            isVisible,
          ]);
        }
        this.isSelectedTermsUpdated = true;
      },
    },
  },
  mounted() {
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
    this.visibleTerms = this.terms;
  },
  beforeDestroy() {
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    toggleTermVisibility(index) {
      this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [index]);
      this.isSelectedTermsUpdated = true;
    },
    toggleSelectedTermsVisibility(selectedTerms) {
      for (const term of selectedTerms) {
        this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [
          this.termsMapping[term.id],
        ]);
      }
    },
    changeOpacity(index, event) {
      const opacity = Number(event.target.value);
      this.$store.commit(this.imageModule + 'setTermOpacity', {
        indexTerm: index,
        opacity,
      });
    },
    resetOpacities() {
      this.$store.commit(this.imageModule + 'resetTermOpacities');
    },
    async shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }
      if (!('tool-toggle-terms-showing' === key) || this.noActiveLayer) {
        return;
      }
      if (this.terms?.length == 0) {
        // Error message no terms available
        this.$notify({
          type: 'error',
          text: this.$t('terms-not-available'),
        });
      } else {
        if (this.isSelectedTermsUpdated || this.selectedTerms?.length === 0) {
          this.selectedTerms = [];
          for (const term of this.terms) {
            if (this.terms[this.termsMapping[term.id]].visible) {
              this.selectedTerms.push(term);
            }
          }
          this.isSelectedTermsUpdated = false;
        }
        this.toggleSelectedTermsVisibility(this.selectedTerms);
      }
    },
    onTermsChanged(terms) {
      this.visibleTerms = terms;
    },
  },
};
</script>

<style scoped>
.visibility {
  width: 2.8em;
  height: 2.1em;
}

>>> .sl-vue-tree-title {
  align-items: center;
}

>>> .checkbox .control-label {
  padding: 0 !important;
}
</style>
