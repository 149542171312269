<template>
  <CytomineModal
    :active="active"
    :title="$t('add-members-to-project')"
    @close="$emit('update:active', false)"
  >
    <BLoading :is-full-page="false" :active="loading" class="small" />
    <template v-if="!loading">
      <!-- eslint-disable-next-line prettier/prettier -->
      <p class="label">
        Select a user or enter a custom email address and press 'return'.
      </p>
      <BField>
        <DomainTagInput
          v-model="selectedMembers"
          :domains="notMemberUsers"
          placeholder="search-user"
          displayed-property="email"
          :allow-new="true"
          group-displayed-property="fullName"
          :searched-property="['fullName', 'email']"
        />
      </BField>
    </template>

    <template #footer>
      <IdxBtn @click="$emit('update:active', false)">
        {{ $t('cancel') }}
      </IdxBtn>
      <IdxBtn
        class="is-link"
        :disabled="selectedMembers <= 0"
        @click="addMembers"
      >
        {{ $t('add') }}
      </IdxBtn>
    </template>
  </CytomineModal>
</template>

<script>
import noteApi from '@/services/noteApi.js';
import { fullName } from '@/utils/user-utils.js';
import DomainTagInput from '@/components/utils/DomainTagInput.vue';
import CytomineModal from '@/components/utils/CytomineModal.vue';

export default {
  name: 'AddMemberModal',
  components: {
    CytomineModal,
    DomainTagInput,
  },
  props: {
    active: Boolean,
  },
  data() {
    return {
      loading: true,
      users: [],
      selectedMembers: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    project() {
      return this.$store.state.currentProject.project;
    },
    currentProjectMembers() {
      return this.$store.state.currentProject.members;
    },
    projectMembersIds() {
      const newProjectMembers = this.currentProjectMembers.concat(
        this.selectedMembers
      );
      return newProjectMembers.map((u) => u.id);
    },
    notMemberUsers() {
      return this.users.filter(
        (user) => !this.projectMembersIds.includes(user.id)
      );
    },
  },
  watch: {
    active() {
      this.selectedMembers = [];
    },
  },
  async created() {
    let route = '/napi/user/invite';
    if (this.currentUser.adminByNow) {
      route = `/api/user.json`;
    }
    const data = await noteApi.get(route);
    this.users = data.collection.map((user) => {
      user.fullName = fullName(user);
      return user;
    });
    this.loading = false;
  },
  methods: {
    async addMembers() {
      if (
        this.project.maxUsers !== 0 &&
        this.selectedMembers.length + this.currentProjectMembers.length >
          this.project.maxUsers
      ) {
        this.$notify({
          type: 'error',
          duration: -1,
          text: this.$t('error-too-many-members', {
            maxUsers: this.project.maxUsers,
          }),
        });
        return;
      }
      try {
        // await this.project.addUsers(
        //   this.selectedMembers.map((member) => member.id)
        // );
        this.loading = true;
        const emails = this.selectedMembers.map((member) =>
          member.email ? member.email : member
        );
        await noteApi.post(`/napi/project/${this.project.id}/users/invite`, {
          json: {
            emails: emails,
          },
        });
        await noteApi.post(`/napi/project/invite`, {
          json: {
            to: [...emails],
            projects: [
              {
                projectName: this.project.name,
                projectLink: `${window.location.origin}/#/project/${this.project.id}`,
              },
            ],
          },
        });
        this.$emit('addMembers');
        this.$notify({
          type: 'success',
          text: this.$t('project-add-members-notif-success'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-add-members-notif-error'),
        });
      } finally {
        this.loading = false;
      }

      this.$emit('update:active', false);
    },
  },
};
</script>

<style scoped>
>>> .modal-card,
>>> .modal-card-body {
  overflow: visible !important;
  width: 60vw !important;
}
</style>
