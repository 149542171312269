var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{style:('display:inline-block;width:' +
      _vm.size +
      'px;height:' +
      _vm.size +
      'px;border-radius:' +
      _vm.size +
      'px;background-color:rgb(' +
      _vm.color.r +
      ',' +
      _vm.color.g +
      ',' +
      _vm.color.b +
      ')')})}
var staticRenderFns = []

export { render, staticRenderFns }