<template>
  <div
    class="rotation-selector-wrapper radius-4 border-1 border-gray-2 bg-white"
  >
    <div class="ol-rotate ol-unselectable ol-control custom">
      <IdxBtn v-tooltip="$t('rotate')" @click="expanded = !expanded">
        <span
          :style="{ transform: `rotate(${rotation}rad)` }"
          class="ol-compass"
          data-label="RotateImgBtn"
          aria-hidden="true"
        >
          ⇧
        </span>
      </IdxBtn>
    </div>
    <div v-show="expanded" class="p-2 pl-6">
      <label class="relative">
        <span>{{ $t('rotate') }} ({{ degreesRotation }}°)</span>
        <input
          v-model="degreesRotation"
          name="rotation"
          type="range"
          min="0"
          max="360"
          class="block mb-2"
          data-label="RotateInput"
        />
      </label>

      <div class="flex mb-2">
        <IdxBtn small data-label="ResetRotateBtn" @click="degreesRotation = 0">
          {{ $t('button-reset') }}
        </IdxBtn>
        <IdxBtn small data-label="Increment90Btn" @click="increment(90)">
          +90°
        </IdxBtn>
        <IdxBtn small data-label="Decrement90Btn" @click="increment(-90)">
          -90°
        </IdxBtn>
      </div>

      <BField>
        <BRadioButton
          v-for="value in ['none', 'vertical', 'horizontal']"
          :key="value"
          v-model="activeMirroring"
          v-tooltip="$t(`mirroring-${value}`)"
          :native-value="value"
          size="is-small"
          name="mirroring"
        >
          <i :class="`fas fa-microscope fa-flip-${value}`" />
          <span class="visually-hidden">{{ $t(`mirroring-${value}`) }}</span>
        </BRadioButton>
      </BField>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RotationSelector',
  props: {
    index: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    /** @returns {string} */
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    /** @returns {object} */
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    /** @returns {string} */
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /** @returns {number} */
    rotation() {
      return this.imageWrapper.view.rotation;
    },
    degreesRotation: {
      /** @returns {number} */
      get() {
        return Math.round(((this.rotation * 180) / Math.PI) * 1000) / 1000;
      },
      /** @param {number} value */
      set(value) {
        this.$store.dispatch(this.viewerModule + 'setRotation', {
          index: this.index,
          rotation: (Number(value) / 180) * Math.PI,
        });
      },
    },
    activeMirroring: {
      /** @returns {string} */
      get() {
        return this.imageWrapper.draw.activeMirroring;
      },
      /** @param {string} mirroring */
      set(mirroring) {
        this.$store.commit(this.imageModule + 'activateMirroring', mirroring);
        this.$eventBus.$emit('mirrorAnnotation');
      },
    },
  },
  methods: {
    /** @param {number} inc */
    increment(inc) {
      this.degreesRotation = (this.degreesRotation + inc + 360) % 360;
    },
    activateMirroring(mirroring) {
      this.activeMirroring = mirroring;
    },
  },
};
</script>

<style scoped>
.rotation-selector-wrapper {
  top: 12.7rem;
}

.ol-rotate.ol-control {
  left: -1px !important;
  top: 0.15rem !important;
}
</style>
