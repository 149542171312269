<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path
      stroke="#000"
      d="M12 12c1-1-3-2 0-6 2-2-2-4-4-3-1 0 0 2-2 3-2 2-5 0-5 3s10 5 11 3z"
      fill="none"
      stroke-width="1.3"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPolygonFreeHand',
};
</script>
