<template>
  <!-- eslint-disable vuejs-accessibility/aria-props -->
  <BModal
    :active.sync="showModal"
    :destroy-on-hide="false"
    trap-focus
    aria-role="dialog"
    aria-modal
    has-modal-card
  >
    <!-- eslint-enable vuejs-accessibility/aria-props -->
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('slides') }} ({{ selectedGroupData.length }})
        </p>
      </header>
      <div class="card-content">
        <BTable
          :data="selectedGroupData"
          paginated
          per-page="10"
          pagination-size="is-small"
        >
          <BTableColumn
            v-slot="props"
            :label="$t('name')"
            field="instanceFilename"
            sortable
            cell-class="has-text-left"
          >
            {{ props.row.instanceFilename }}
          </BTableColumn>
          <BTableColumn v-slot="props" centered>
            <RouterLink
              :to="`/project/${project.id}/image/${props.row.id}`"
              target="_blank"
            >
              <IdxBtn small full-width color="primary">
                {{ $t('view-slide') }}
              </IdxBtn>
            </RouterLink>
          </BTableColumn>
        </BTable>
      </div>
    </div>
  </BModal>
</template>

<script>
import { isNullOrUndefined } from '@/utils/string-utils.js';

export default {
  name: 'PieChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    selectedGroupData: [],
    nullValues: [],
    showModal: false,
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
    xAxis() {
      return this.$store.getters['projectVisualizations/getChartData'](
        this.rowIndex,
        this.columnIndex
      ).xAxis[0];
    },
  },
  watch: {
    xAxis() {
      this.initChart();
    },
  },
  created() {
    // configure what's displayed in the chart controls panel
    this.$store.commit('projectVisualizations/setChartConfig', {
      xAxis: true,
      xAxisGrouped: true,
      yAxis: false,
    });
  },
  mounted() {
    this.initChart();
  },
  methods: {
    getGroupingValues() {
      this.nullValues = this.data.filter(
        (data) =>
          isNullOrUndefined(data[this.xAxis]) || data[this.xAxis] === 'NA'
      );
      const valuesByDimension = this.data.map((data) => data[this.xAxis]);
      const uniqueValues = valuesByDimension.filter(
        (val, i) => valuesByDimension.indexOf(val) === i
      );
      uniqueValues.sort();
      return uniqueValues;
    },
    getSeriesData() {
      const series = {
        name: this.xAxis,
        data: [],
      };
      const groupValues = this.getGroupingValues();
      const totalDataLength = this.data.length - this.nullValues.length;
      for (const [index, groupVal] of groupValues.entries()) {
        const groupData = this.data.filter((a) => a[this.xAxis] === groupVal);
        if (groupData.length > 0) {
          const size = (groupData.length / totalDataLength) * 100;
          const roundedSize = Math.round(size * 100) / 100;
          series.data.push({
            name: groupVal,
            y: roundedSize,
            custom: {
              count: groupData.length,
            },
          });
        }
      }
      return series;
    },
    initChart() {
      const seriesData = this.getSeriesData();
      // @ts-ignore
      const chartOptions = {
        chart: {
          type: 'pie',
        },
        title: {
          text: this.xAxis,
        },
        tooltip: {
          pointFormat:
            '{series.name}: <b>{point.percentage:.1f}%</b><br />Count: <b>{point.custom.count}</b>',
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%'],
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
          series: {
            cursor: 'pointer',
            size: '75%',
            point: {
              events: {
                click: (event) => {
                  this.selectedGroupData = this.data.filter(
                    (a) => a[this.xAxis] === event.point.name
                  );
                  this.showModal = true;
                },
              },
            },
          },
        },
        series: [seriesData],
      };

      this.$emit('buildChart', {
        chartOptions,
        nullValues: this.nullValues,
      });
    },
  },
};
</script>

<style scoped></style>
