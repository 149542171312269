<template>
  <div>
    <vl-interaction-select
      v-if="activeTool === 'select'"
      ref="interactionSelect"
      :ident="`select-target-${index}`"
      :filter="filterFunction"
      :features.sync="selectedFeatures"
      :toggle-condition="never"
      :remove-condition="shiftKeyOnly"
      :multi="true"
    >
      <vl-style-func :factory="styleFunctionFactory" />
    </vl-interaction-select>

    <vl-layer-vector :update-while-interacting="false">
      <vl-source-vector ref="olSourceDrawTarget" :ident="drawSourceName" />
    </vl-layer-vector>

    <vl-interaction-draw
      v-if="activeTool === 'multi-select'"
      ref="olDrawInteraction"
      :source="drawSourceName"
      :type="'Polygon'"
      :freehand="true"
      :freehand-condition="undefined"
      @drawend="drawEndHandler"
    />
  </div>
</template>

<script>
import WKT from 'ol/format/WKT';
import { never, shiftKeyOnly } from 'ol/events/condition';
import { AnnotationCollection } from 'cytomine-client';
import { isCluster } from '@/utils/style-utils.js';

export default {
  name: 'SelectInteraction',
  props: {
    index: String,
  },
  data() {
    return {
      ctrlPressed: false,
    };
  },
  computed: {
    currentViewerPath() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    visibleTermIds() {
      return this.imageWrapper.style.terms
        .filter((a) => a.visible)
        .map((a) => a.id);
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    activeTool() {
      return this.imageWrapper.draw.activeTool;
    },
    drawSourceName() {
      return `draw-target-${this.index}`;
    },
    visibleLayerIds() {
      const layers = this.imageWrapper.layers.selectedLayers || [];
      return layers.filter((a) => a.visible).map((b) => b.id);
    },
    selectedFeatures: {
      get() {
        return this.imageWrapper.selectedFeatures.selectedFeatures;
      },
      set(value) {
        // used when selecting a vertex of a feature
        const notAnnotations = value.filter(
          (x) => !Object.keys(x).includes('id') && x.properties === null
        );
        if (notAnnotations.length == 1) {
          value = notAnnotations;
          this.$store.commit(this.imageModule + 'setSelectedFeatures', value);
          return;
        }

        value.sort(function(a, b) {
          if (a.properties.annot.area > b.properties.annot.area) return 1;
          else return -1;
        });

        const previousTargets = this.imageWrapper.selectedFeatures
          .selectionTargetedFeatures;
        const previousSelectedFeature = this.imageWrapper.selectedFeatures
          .selectedFeatures[0];

        this.$store.commit(
          this.imageModule + 'setSelectionTargetedFeatures',
          value
        );

        // see https://github.com/cytomine/Cytomine-Web-UI/issues/13 for more details of this algorithm
        if (!this.ctrlPressed) {
          if (this.imageWrapper.selectedFeatures.selectedFeatures.length == 0) {
            if (value.length >= 1) value = [value[0]];
          } else {
            if (value.length > 1) {
              // if previous selection is in the new target, we will take the first element not yet visited
              if (value.map((x) => x.id).includes(previousSelectedFeature.id)) {
                var index = previousTargets.findIndex(
                  (x) => x.id === previousSelectedFeature.id
                );
                const visitedFeatures = previousTargets.slice(0, index + 1);
                index = 0;
                for (var i = 0; i < value.length; i++) {
                  if (!visitedFeatures.map((x) => x.id).includes(value[i].id)) {
                    index = i;
                    break;
                  }
                }
                value = [value[index]];
              } else {
                value = [value[0]];
              }
            }
          }
        }

        if (value.length >= 1 && value[0].properties !== null) {
          const annot = value[0].properties.annot;
          annot.recordAction();
        }

        if (this.ctrlPressed) {
          const selectedFeatures = this.imageWrapper.selectedFeatures
            .selectedFeatures;
          const selectedAnnotIds = selectedFeatures.map((a) => a.id);
          if (selectedAnnotIds.includes(value[0].id)) {
            // need to remove
            value = selectedFeatures.filter((a) => a.id !== value[0].id);
          } else {
            value = selectedFeatures.concat(value[0]);
          }
        }

        this.$store.commit(this.imageModule + 'setSelectedFeatures', value);
      },
    },
    terms() {
      return this.imageWrapper.style.terms || [];
    },
    styleFunctionFactory() {
      this.imageWrapper.selectedFeatures.selectedFeatures;
      this.imageWrapper.style.layersOpacity;
      this.terms.forEach((term) => {
        term.visible;
        term.opacity;
      });
      this.imageWrapper.style.displayNoTerm;
      this.imageWrapper.style.noTermOpacity;
      this.imageWrapper.draw.activeEditTool; // style is different in edit mode (vertices displayed)
      this.imageWrapper.properties.selectedPropertyValues;
      this.imageWrapper.properties.selectedPropertyColor;
      this.imageWrapper.review.reviewMode;

      return () => {
        return this.$store.getters[this.imageModule + 'genStyleFunction'];
      };
    },
    filterFunction() {
      return (feature) => !isCluster(feature);
    },
    never() {
      return never;
    },
    shiftKeyOnly() {
      return shiftKeyOnly;
    },
  },
  watch: {
    async styleFunctionFactory() {
      // HACK: style function is not called again when redefined => force the update of style for selected features
      if (
        this.$refs.interactionSelect &&
        this.$refs.interactionSelect.$interaction
      ) {
        await this.$refs.interactionSelect.$interaction
          .getFeatures()
          .forEach((ft) => ft.changed());
      }
    },
  },
  created() {
    window.addEventListener('keydown', (e) => {
      this.ctrlPressed = e.ctrlKey || e.metaKey;
    });
    window.addEventListener('keyup', (e) => {
      this.ctrlPressed = e.ctrlKey || e.metaKey;
    });
  },
  methods: {
    setIsLoading(value) {
      this.$store.commit(this.currentViewerPath + 'setIsLoading', value);
    },
    async drawEndHandler({ feature }) {
      try {
        this.setIsLoading(true);
        var wktLocation = new WKT().writeGeometry(feature.getGeometry());
        const annots = await new AnnotationCollection({
          image: this.image.id,
          bbox: wktLocation, //geometry.extent_.join(),
          showWKT: true,
          showTerm: true,
          showGIS: true,
          kmeans: true,
        }).fetchAll();

        const selectedFeatures = [];
        annots._data.forEach((annot) => {
          if (
            this.visibleLayerIds.includes(annot.user) &&
            (!annot.term ||
              annot.term.length === 0 ||
              annot.term.filter((a) => this.visibleTermIds.includes(a)).length >
                0)
          ) {
            const newFeature = new WKT().readFeature(annot.location);
            const featureToAdd = {
              geometry: {
                coordinates: newFeature.getGeometry().getCoordinates(),
                type: 'Polygon',
              },
              id: annot.id,
              userId: annot.user,
              properties: {
                annot: annot,
                type: 'Feature',
              },
            };

            selectedFeatures.push(featureToAdd);
          }
        });

        // save selected features
        this.$store.commit(
          this.imageModule + 'setSelectedFeatures',
          selectedFeatures
        );

        // show info modal
        if (selectedFeatures && selectedFeatures.length > 0) {
          this.$store.commit(this.imageModule + 'setDisplayAnnotDetails', true);
        }

        this.$refs.olSourceDrawTarget.clear(true);
        this.setIsLoading(false);
      } catch (error) {
        console.log(error);
        this.setIsLoading(false);
      }
    },
  },
};
</script>
