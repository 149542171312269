<template>
  <BDatepicker
    :class="styles"
    :value="value"
    :placeholder="placeholder || $t('select-date')"
    :min-date="minDate"
    :max-date="maxDate"
    :month-names="dayjs.months()"
    :day-names="dayjs.weekdaysMin()"
    :date-formatter="(date) => dayjs(date).format('ll')"
    size="is-small"
    @input="$emit('input', $event)"
  >
    <div v-if="resetButton" class="has-text-centered">
      <button
        :disabled="!value"
        class="button is-small is-link"
        @click="$emit('input', null)"
      >
        {{ $t('button-reset') }}
      </button>
    </div>
  </BDatepicker>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'CytomineDatePicker',
  props: {
    value: Date,
    resetButton: { type: Boolean, default: true },
    maxDate: { type: Date, default: undefined },
    minDate: { type: Date, default: undefined },
    placeholder: {
      type: String,
      default: '',
    },
    styles: {
      type: Array,
      default: () => [],
    }, // accept "multiselect"
  },
  computed: {
    dayjs: () => dayjs,
  },
};
</script>

<style lang="scss">
.datepicker.multiselect .input {
  min-height: 40px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  font-size: 1rem;
  font-family: inherit;

  &::placeholder {
    color: #adadad;
    opacity: 1;
  }
}
</style>
