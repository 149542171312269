<template>
  <div>
    <div v-if="!configUI['project-annotations-tab']" class="box error">
      <h2 class="mb-3">
        {{ $t('access-denied') }}
      </h2>
      <p>{{ $t('insufficient-permission') }}</p>
    </div>
    <div v-else-if="error" class="box error">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('error-load-annotations-filters') }}</p>
    </div>
    <div v-else class="content-wrapper">
      <BLoading :is-full-page="false" :active="loading" />
      <div v-if="!loading">
        <div class="box">
          <h2 class="mb-3">
            {{ $t('display') }}
            <span class="ml-3" style="font-size: 12px; vertical-align: middle">
              (
              <IdxBtn :link="true" @click="resetDisplayValues">
                {{ $t('button-reset-to-default') }}
              </IdxBtn>
              )
            </span>
          </h2>
          <div class="radius-8 py-3 px-4 bg-gray-1">
            <div class="grid columns-3@768 gap-16">
              <div>
                <div class="mb-1">
                  {{ $t('preview-size') }}
                </div>
                <CytomineMultiselect
                  v-model="selectedSize"
                  :options="allowedSizes"
                  :allow-empty="false"
                  :searchable="false"
                  label="label"
                  track-by="size"
                />
              </div>
              <div>
                <div class="mb-1">
                  {{ $t('number-per-page') }}
                </div>
                <CytomineMultiselect
                  v-model="perPage"
                  :options="[10, 25, 50, 100]"
                  :allow-empty="false"
                  :searchable="false"
                />
              </div>
              <div>
                <div class="mb-1">
                  {{ $t('outline-color') }}
                </div>
                <CytomineMultiselect
                  v-model="selectedColor"
                  :options="colors"
                  :allow-empty="false"
                  :searchable="false"
                  label="label"
                  track-by="name"
                />
              </div>
            </div>
          </div>
        </div>

        <h2 class="mb-3">
          {{ $t('filters') }}
          <span class="ml-3" style="font-size: 12px; vertical-align: middle">
            (
            <IdxBtn :link="true" @click="resetFilters">
              {{ $t('button-reset-to-default') }}
            </IdxBtn>
            )
          </span>
        </h2>
        <div class="radius-8 py-3 px-4 bg-gray-1">
          <div class="grid columns-4@768 gap-16 mb-3">
            <div>
              <div class="mb-1">
                {{ $t('annotation-type') }}
              </div>
              <CytomineMultiselect
                v-model="selectedAnnotationType"
                :options="annotationTypes"
                :allow-empty="false"
                :searchable="false"
              />
            </div>

            <div>
              <div class="mb-1">
                {{ $t('images') }}
              </div>
              <CytomineMultiselect
                v-model="selectedImages"
                :options="images"
                :label="blindMode ? 'blindedName' : 'instanceFilename'"
                :all-placeholder="$t('all-images')"
                track-by="id"
                multiple
              />
            </div>

            <div v-if="ontology">
              <div class="mb-1">
                {{ $t('terms') }}
              </div>
              <OntologyTreeMultiselect
                v-model="selectedTermsIds"
                :ontology="ontology"
                :additional-nodes="additionalNodes"
              />
            </div>

            <div v-if="selectedAnnotationType === jobAnnotationOption">
              <div class="mb-1">
                {{ $t('analyses') }}
              </div>
              <CytomineMultiselect
                v-model="selectedUserJobs"
                :options="userJobs"
                :all-placeholder="$t('all-analyses')"
                label="fullName"
                track-by="id"
                multiple
              />
            </div>

            <div v-else-if="selectedAnnotationType === userAnnotationOption">
              <div class="mb-1">
                {{ $t('members') }}
              </div>
              <CytomineMultiselect
                v-model="selectedMembers"
                :options="filteredMembers"
                label="fullName"
                track-by="id"
                multiple
              />
            </div>

            <div v-else>
              <div class="mb-1">
                {{ $t('reviewers') }}
              </div>
              <CytomineMultiselect
                v-model="selectedReviewers"
                :options="members"
                label="fullName"
                track-by="id"
                multiple
              />
            </div>
          </div>

          <div class="grid columns-4@768 gap-16 mb-3">
            <!-- Tags hidden until scoped access per user per project -->
            <!-- <div>
              <div class="mb-1">
                {{ $t('tags') }}
              </div>
              <CytomineMultiselect
                v-model="selectedTags"
                :options="availableTags"
                multiple
                :all-placeholder="$t('all')"
                label="name"
                track-by="id"
              />
            </div>
            <div /> -->

            <div>
              <div class="mb-1">
                {{ $t('from') }}
              </div>
              <CytomineDatepicker
                v-model="fromDate"
                :styles="['multiselect']"
                :max-date="toDate || new Date()"
              />
            </div>

            <div>
              <div class="mb-1">
                {{ $t('to') }}
              </div>
              <CytomineDatepicker
                v-model="toDate"
                :styles="['multiselect']"
                :min-date="fromDate"
              />
            </div>
          </div>
        </div>
      </div>

      <ListAnnotationsByTerm
        v-for="term in termsOptions"
        v-show="selectedTermsIds.includes(term.id)"
        :key="term.id"
        :size="selectedSize.size"
        :color="selectedColor.hexaCode"
        :nb-per-page="perPage"
        :all-terms="terms"
        :all-users="allUsers"
        :all-images="images"
        :term="term"
        :multiple-terms="term.id === multipleTermsOption.id"
        :no-term="term.id === noTermOption.id"
        :images-ids="selectedImagesIds"
        :users-ids="selectedUsersIds"
        :tags-ids="tagsIdsNotNull"
        :no-tag="noTag"
        :reviewed="reviewed"
        :review-users-ids="reviewUsersIds"
        :after-than="afterThan"
        :before-than="beforeThan"
        :revision="revision"
        @addTerm="addTerm"
        @update="revision++"
      />

      <div v-if="currentUser.admin" class="box">
        <h2 class="mb-3 text-center">
          {{ $t('download-results') }}
        </h2>
        <div class="flex gap-8 justify-center flex-wrap">
          <IdxBtn :href="downloadURL('pdf')" color="primary">
            {{ $t('download-PDF') }}
          </IdxBtn>
          <IdxBtn :href="downloadURL('csv')" color="primary">
            {{ $t('download-CSV') }}
          </IdxBtn>
          <IdxBtn :href="downloadURL('xls')" color="primary">
            {{ $t('download-excel') }}
          </IdxBtn>
          <IdxBtn :href="downloadURL('properties')" color="primary">
            {{ $t('download-properties') }}
          </IdxBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ImageInstanceCollection,
  UserJobCollection,
  AnnotationCollection,
  TagCollection,
} from 'cytomine-client';

import ListAnnotationsByTerm from './ListAnnotationsByTerm.vue';
import constants from '@/utils/constants.js';
import { sync, syncMultiselectFilter } from '@/utils/store-helpers.js';

import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';
import CytomineDatepicker from '@/components/form/CytomineDatepicker.vue';
import OntologyTreeMultiselect from '@/components/ontology/OntologyTreeMultiselect.vue';

import { fullName } from '@/utils/user-utils.js';
import { defaultColors } from '@/utils/style-utils.js';

/**
 * @typedef {{ label: string, name: string, fill: { color_: string }, hexaCode: string }} Color
 */

// store options to use with store helpers to target projects/currentProject/listImages module
const storeOptions = { rootModuleProp: 'storeModule' };
// redefine helpers to use storeOptions and correct module path
const localSyncMultiselectFilter = (filterName, options) =>
  syncMultiselectFilter(null, filterName, options, storeOptions);

function annotationsWatcher() {
  // save filter values to local storage
  localStorage.setItem(
    this.project.id + '_selectedAnnotationType',
    this.selectedAnnotationType
  );
  localStorage.setItem(
    this.project.id + '_selectedMembers',
    JSON.stringify(this.selectedMembers)
  );
  localStorage.setItem(
    this.project.id + '_selectedReviewers',
    JSON.stringify(this.selectedReviewers)
  );
  localStorage.setItem(
    this.project.id + '_selectedUserJobs',
    JSON.stringify(this.selectedUserJobs)
  );
  localStorage.setItem(
    this.project.id + '_selectedImages',
    JSON.stringify(this.selectedImages)
  );
  localStorage.setItem(
    this.project.id + '_selectedTags',
    JSON.stringify(this.selectedTags)
  );
  localStorage.setItem(
    this.project.id + '_selectedTermsIds',
    JSON.stringify(this.selectedTermsIds)
  );
  localStorage.setItem(
    this.project.id + '_fromDate',
    this.fromDate ? this.fromDate.toString() : null
  );
  localStorage.setItem(
    this.project.id + '_toDate',
    this.toDate ? this.toDate.toString() : null
  );
  localStorage.setItem(
    this.project.id + '_perPage',
    JSON.stringify(this.perPage)
  );
  localStorage.setItem(
    this.project.id + '_selectedSize',
    JSON.stringify(this.selectedSize)
  );
  localStorage.setItem(
    this.project.id + '_selectedColor',
    JSON.stringify(this.selectedColor)
  );

  if (this.selectedImages.length == this.images.length) {
    this.selectedImages = this.images;
    localStorage.removeItem(this.project.id + '_selectedImages');
  }
  if (this.selectedMembers.length == this.filteredMembers.length) {
    this.selectedMembers = this.filteredMembers;
    localStorage.removeItem(this.project.id + '_selectedMembers');
  }
  if (this.selectedReviewers.length == this.members.length) {
    this.selectedReviewers = this.members;
    localStorage.removeItem(this.project.id + '_selectedReviewers');
  }
}

export default {
  name: 'ListAnnotations',
  components: {
    CytomineMultiselect,
    CytomineDatepicker,
    OntologyTreeMultiselect,
    ListAnnotationsByTerm,
  },
  props: {
    idProject: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      revision: 0,

      users: [],
      userJobs: [],

      allowedSizes: [
        {
          label: this.$t('small'),
          size: 85,
        },
        {
          label: this.$t('medium'),
          size: 125,
        },
        {
          label: this.$t('large'),
          size: 200,
        },
        {
          label: this.$t('huge'),
          size: 400,
        },
      ],

      userAnnotationOption: this.$t('user-annotations'),
      jobAnnotationOption: this.$t('analysis-annotations'),
      reviewedAnnotationOption: this.$t('reviewed-annotations'),
      annotationTypes: [],

      images: [],
      availableTags: [],

      noTermOption: {
        id: 0,
        name: this.$t('no-term'),
      },
      multipleTermsOption: {
        id: -1,
        name: this.$t('multiple-terms'),
      },
      selectedAnnotationType: null, // gets set in created()
      selectedMembers:
        JSON.parse(localStorage.getItem(this.idProject + '_selectedMembers')) ||
        [],
      selectedReviewers:
        JSON.parse(
          localStorage.getItem(this.idProject + '_selectedReviewers')
        ) || [],
      selectedUserJobs:
        JSON.parse(
          localStorage.getItem(this.idProject + '_selectedUserJobs')
        ) || [],
      selectedImages:
        JSON.parse(localStorage.getItem(this.idProject + '_selectedImages')) ||
        [],
      selectedTags:
        JSON.parse(localStorage.getItem(this.idProject + '_selectedTags')) ||
        [],
      selectedTermsIds:
        JSON.parse(
          localStorage.getItem(this.idProject + '_selectedTermsIds')
        ) || [],
      fromDate: Date.parse(localStorage.getItem(this.idProject + '_fromDate'))
        ? new Date(localStorage.getItem(this.idProject + '_fromDate'))
        : null,
      toDate: Date.parse(localStorage.getItem(this.idProject + '_toDate'))
        ? new Date(localStorage.getItem(this.idProject + '_toDate'))
        : null,
      selectedSize: null, // set in created()
      perPage:
        JSON.parse(localStorage.getItem(this.idProject + '_perPage')) || 25,
      selectedColor: null, // set in created()
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {boolean} */
    blindMode() {
      return this.project.blindMode;
    },
    /** @returns {boolean} */
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    /** @returns {object} */
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    /** @returns {Record<string, boolean>} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },

    /** @returns {string} */
    storeModule() {
      // path to the vuex module in which state of this component is stored (projects/currentProject/listAnnotations)
      return (
        this.$store.getters['currentProject/currentProjectModule'] +
        'listAnnotations'
      );
    },

    /** @returns {Array<Color>} */
    colors() {
      const colors = defaultColors.map((color) => ({
        label: this.$t(color.name),
        ...color,
      }));
      colors.push({ label: this.$t('no-outline') });
      return colors;
    },
    /** @returns {string} */
    targetAnnotationType() {
      switch (this.$route.query.type) {
        case 'user':
          return this.userAnnotationOption;
        case 'algo':
          return this.jobAnnotationOption;
        case 'reviewed':
          return this.reviewedAnnotationOption;
        default:
          return '';
      }
    },

    /** @returns {object[]} */
    allUsers() {
      return this.users.concat(this.userJobs);
    },
    /** @returns {object[]} */
    members() {
      return this.$store.state.currentProject.members;
    },
    /** @returns {object[]} */
    managers() {
      return this.$store.state.currentProject.managers;
    },
    /** @returns {object[]} */
    filteredMembers() {
      // filter the members so as to return only those whose annotations can be seen by current user
      if (
        this.canManageProject ||
        (!this.project.hideUsersLayers && !this.project.hideAdminsLayers)
      ) {
        return this.members;
      }

      const idManagers = this.managers.map((m) => m.id);
      return this.members.filter((member) => {
        if (this.currentUser.id === member.id) {
          return true;
        }
        const isManager = idManagers.includes(member.id);
        return isManager
          ? !this.project.hideAdminsLayers
          : !this.project.hideUsersLayers;
      });
    },

    /** @returns {object[]} */
    terms() {
      return this.$store.getters['currentProject/terms'] || [];
    },
    /** @returns {object[]} */
    additionalNodes() {
      const additionalNodes = [this.noTermOption];
      if (this.terms.length > 1) {
        additionalNodes.push(this.multipleTermsOption);
      }
      return additionalNodes;
    },
    /** @returns {object[]} */
    termsOptions() {
      return this.terms.concat(this.additionalNodes);
    },
    /** @returns {number[]} */
    termOptionsIds() {
      return this.termsOptions.map((option) => option.id);
    },
    afterThan() {
      return this.fromDate ? this.fromDate.getTime() : null;
    },
    beforeThan() {
      return this.toDate ? this.toDate.setHours(23, 59, 59, 999) : null;
    },

    reviewed() {
      return this.selectedAnnotationType === this.reviewedAnnotationOption;
    },
    /** @returns {number[]} */
    selectedUsersIds() {
      if (this.reviewed) {
        return null;
      }
      const users =
        this.selectedAnnotationType === this.jobAnnotationOption
          ? this.selectedUserJobs
          : this.selectedMembers;
      return users.map((user) => user.id);
    },
    /** @returns {number[]} */
    reviewUsersIds() {
      return this.reviewed ? this.selectedReviewers.map((u) => u.id) : null;
    },

    /** @returns {number[]} */
    selectedImagesIds() {
      return this.selectedImages.map((img) => img.id);
    },
    /** @returns {number[]} */
    selectedTagsIds() {
      return this.selectedTags.map((t) => t.id);
    },
    /** @returns {number[]} */
    tagsIdsNotNull() {
      if (this.selectedTagsIds.indexOf('null') >= 0) {
        const x = this.selectedTagsIds.slice();
        x.splice(x.indexOf('null'), 1);
        return x;
      }
      return this.selectedTagsIds;
    },
    /** @returns {boolean} */
    noTag() {
      return this.selectedTagsIds.indexOf('null') >= 0;
    },
    /** @returns {object} */
    collection() {
      const collection = new AnnotationCollection({
        project: this.project.id,
        terms: this.selectedTermsIds,
        images: this.selectedImagesIds,
        users: this.selectedUsersIds,
        reviewed: this.reviewed,
        reviewUsers: this.reviewUsersIds,
        noTerm: this.selectedTermsIds.includes(this.noTermOption.id),
        multipleTerms: this.selectedTermsIds.includes(
          this.multipleTermsOption.id
        ),
        afterThan: this.afterThan,
        beforeThan: this.beforeThan,
      });

      if (
        this.selectedTagsIds.length > 0 &&
        this.selectedTagsIds.length < this.availableTags.length
      ) {
        collection['tags'] = this.selectedTagsIds;
        collection['noTag'] = this.noTag;
      }

      return collection;
    },
  },
  watch: {
    selectedAnnotationType: annotationsWatcher,
    selectedMembers: annotationsWatcher,
    selectedReviewers: annotationsWatcher,
    selectedUserJobs: annotationsWatcher,
    selectedImages: annotationsWatcher,
    selectedTags: annotationsWatcher,
    selectedTermsIds: annotationsWatcher,
    fromDate: annotationsWatcher,
    toDate: annotationsWatcher,
    selectedColor: annotationsWatcher,
    perPage: annotationsWatcher,
    selectedSize: annotationsWatcher,
  },
  async created() {
    this.annotationTypes = [
      this.userAnnotationOption,
      this.jobAnnotationOption,
      this.reviewedAnnotationOption,
    ];

    this.selectedSize =
      JSON.parse(localStorage.getItem(this.idProject + '_selectedSize')) ||
      this.allowedSizes[0];
    this.selectedColor =
      JSON.parse(localStorage.getItem(this.idProject + '_selectedColor')) ||
      this.colors[0];
    this.selectedAnnotationType =
      localStorage.getItem(this.project.id + '_selectedAnnotationType') ||
      this.userAnnotationOption;
    try {
      await Promise.all([
        this.fetchImages(),
        this.fetchUsers(),
        this.fetchUserJobs(),
        this.fetchTags(),
      ]);
    } catch (error) {
      console.log(error);
      this.error = true;
      return;
    }

    const localImages = JSON.parse(
      localStorage.getItem(this.project.id + '_selectedImages')
    );
    this.selectedImages =
      localImages && localImages.length > 0 ? localImages : this.images;

    const localMembers = JSON.parse(
      localStorage.getItem(this.project.id + '_selectedMembers')
    );
    this.selectedMembers =
      localMembers && localMembers.length > 0
        ? localMembers
        : this.filteredMembers;

    const localReviewers = JSON.parse(
      localStorage.getItem(this.project.id + '_selectedReviewers')
    );
    this.selectedReviewers =
      localReviewers && localReviewers.length > 0
        ? localReviewers
        : this.members;

    const localUserJobs = JSON.parse(
      localStorage.getItem(this.project.id + '_selectedUserJobs')
    );
    this.selectedUserJobs =
      localUserJobs && localUserJobs.length > 0 ? localUserJobs : this.userJobs;

    const localTags = JSON.parse(
      localStorage.getItem(this.project.id + '_selectedTags')
    );
    this.selectedTags =
      localTags && localTags.length > 0 ? localTags : this.availableTags;

    const localTermIds = JSON.parse(
      localStorage.getItem(this.project.id + '_selectedTermsIds')
    );
    this.selectedTermsIds =
      localTermIds && localTermIds.length > 0
        ? localTermIds
        : this.termOptionsIds;

    if (this.targetAnnotationType) {
      this.resetPagesAndFilters(); // we want all annotations of this type => reset state
      this.selectedAnnotationType = this.targetAnnotationType;
    }

    if (this.$route.query.image) {
      const queriedImage = this.images.find(
        (image) => image.id === Number(this.$route.query.image)
      );
      if (queriedImage) {
        this.resetPagesAndFilters(); // we want all annotations of the image => reset state
        this.selectedImages = [queriedImage];
      }
    }

    if (this.$route.query.userJob) {
      const queriedUserJob = this.userJobs.find(
        (uj) => uj.id === Number(this.$route.query.userJob)
      );
      if (queriedUserJob) {
        this.resetPagesAndFilters(); // we want all annotations of the job => reset state
        this.selectedAnnotationType = this.jobAnnotationOption;
        this.selectedUserJobs = [queriedUserJob];
      }
    }

    this.loading = false;
  },
  methods: {
    async fetchImages() {
      const results = await ImageInstanceCollection.fetchAll({
        filterKey: 'project',
        filterValue: this.project.id,
        light: true,
      });
      this.images = results.array;
    },
    async fetchUsers() {
      const results = await this.project.fetchUsers();
      this.users = results.array;
      this.users.forEach((user) => {
        user.fullName = fullName(user);
      });
    },
    async fetchUserJobs() {
      const results = await UserJobCollection.fetchAll({
        filterKey: 'project',
        filterValue: this.project.id,
      });
      this.userJobs = results.array;
      this.userJobs.forEach((userJob) => {
        userJob.fullName = fullName(userJob);
      });
    },
    async fetchTags() {
      const results = await TagCollection.fetchAll();
      this.availableTags = [
        {
          id: 'null',
          name: this.$t('no-tag'),
        },
        ...results.array,
      ];
    },
    downloadURL(format) {
      const {
        project,
        selectedImages,
        selectedTermsIds,
        noTermOption,
        multipleTermsOption,
        afterThan,
        beforeThan,
      } = this;
      if (format === 'properties') {
        const url = new URL(
          `${constants.CYTOMINE_CORE_HOST}/napi/project/${project.id}/property/download`
        );
        const search = new URLSearchParams({
          imageIds: selectedImages.map((image) => image.id),
          termIds: selectedTermsIds.filter((id) => id > 0),
          noTerm: selectedTermsIds.includes(noTermOption.id),
          multipleTerms: selectedTermsIds.includes(multipleTermsOption.id),
          ...(afterThan ? { after: afterThan } : {}),
          ...(beforeThan ? { before: beforeThan } : {}),
          perPage: '-1',
          // users: this.selectedUsersIds,
          // reviewed: this.reviewed,
          // reviewUsers: this.reviewUsersIds,
        });
        url.search = search.toString();
        return url.toString();
      }
      return this.collection.getDownloadURL(format);
    },
    addTerm(term) {
      this.terms.push(term);
      this.selectedTermsIds.push(term.id);
    },
    resetPagesAndFilters() {
      this.resetFilters();
      this.resetDisplayValues();
    },
    resetFilters() {
      this.selectedAnnotationType = this.userAnnotationOption;
      this.selectedMembers = this.filteredMembers;
      this.selectedReviewers = this.members;
      this.selectedUserJobs = this.userJobs;
      this.selectedImages = this.images;
      this.selectedTermsIds = this.termOptionsIds;
      this.selectedTags = this.availableTags;
      this.fromDate = null;
      this.toDate = null;

      localStorage.removeItem(this.project.id + '_selectedAnnotationType');
      localStorage.removeItem(this.project.id + '_selectedMembers');
      localStorage.removeItem(this.project.id + '_selectedReviewers');
      localStorage.removeItem(this.project.id + '_selectedUserJobs');
      localStorage.removeItem(this.project.id + '_selectedImages');
      localStorage.removeItem(this.project.id + '_selectedTermsIds');
      localStorage.removeItem(this.project.id + '_selectedTags');
      localStorage.removeItem(this.project.id + '_fromDate');
      localStorage.removeItem(this.project.id + '_toDate');
    },
    resetDisplayValues() {
      this.selectedColor = this.colors[0];
      this.selectedSize = this.allowedSizes[0];
      this.perPage = 25;

      localStorage.removeItem(this.project.id + '_perPage');
      localStorage.removeItem(this.project.id + '_selectedSize');
      localStorage.removeItem(this.project.id + '_selectedColor');
    },
  },
};
</script>
