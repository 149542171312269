export default {
  state() {
    return {
      activeTool: 'select',
      activeMirroring: 'none',

      termsNewAnnots: [],

      activeEditTool: null,
      ongoingEdit: false,

      brushSize: 1,
    };
  },

  mutations: {
    activateTool(state, tool) {
      state.activeTool = tool;
    },

    setBrushSize(state, brushSize) {
      state.brushSize = parseInt(brushSize) || 1;
    },

    activateMirroring(state, mirroring) {
      state.activeMirroring = mirroring;
    },

    activateEditTool(state, tool) {
      state.activeEditTool = tool;
    },

    setOngoingEdit(state, value) {
      state.ongoingEdit = value;
    },

    setTermsNewAnnots(state, terms) {
      state.termsNewAnnots = terms;
    },

    filterTermsNewAnnots(state, terms) {
      // keep only the terms that still exist
      const idTerms = terms.map((term) => term.id);
      state.termsNewAnnots = state.termsNewAnnots.filter((id) =>
        idTerms.includes(id)
      );
    },
  },

  actions: {
    activateTool({ state, commit }, tool) {
      const previousTool = state.activeTool;
      if (previousTool === 'select' && tool === 'brush') {
        commit('activateEditTool', { tool: null });
      } else if (previousTool === 'select' && tool !== 'select') {
        commit('clearSelectedFeatures');
        commit('activateEditTool', { tool: null });
      }
      commit('activateTool', tool);
    },

    refreshData({ commit, rootGetters }) {
      commit('filterTermsNewAnnots', rootGetters['currentProject/terms'] || []);
    },

    setBrushSize({ commit }, brushSize) {
      commit('setBrushSize', brushSize);
    },
  },
};
