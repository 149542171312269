<template>
  <div class="project-image-filters-wrapper">
    <BLoading :is-full-page="false" :active="loading" />
    <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>
    <template v-else-if="!loading">
      <BInput
        v-model="searchString"
        :placeholder="$t('search')"
        class="search-field"
        type="search"
        icon="search"
      />

      <BTable
        :data="filteredImageFilters"
        paginated
        :per-page="perPage"
        default-sort="selected"
        default-sort-direction="desc"
        pagination-size="is-small"
      >
        <BTableColumn :label="$t('name')" field="name" sortable>
          <template #default="props">
            {{ props.row.name }}
          </template>
        </BTableColumn>

        <BTableColumn :label="$t('status')" field="selected" sortable>
          <template #default="props">
            <button
              :class="[
                'button',
                props.row.selected ? 'is-success' : 'is-danger',
              ]"
              @click="toggleFilter(props.row)"
            >
              {{ $t(props.row.selected ? 'enabled' : 'disabled') }}
            </button>
          </template>
        </BTableColumn>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-image-filter') }}</p>
          </div>
        </template>

        <template #bottom-left>
          <BSelect v-model="perPage" size="is-small">
            <option value="10">
              {{ $t('count-per-page', { count: 10 }) }}
            </option>
            <option value="25">
              {{ $t('count-per-page', { count: 25 }) }}
            </option>
            <option value="50">
              {{ $t('count-per-page', { count: 50 }) }}
            </option>
            <option value="100">
              {{ $t('count-per-page', { count: 100 }) }}
            </option>
          </BSelect>
        </template>
      </BTable>
    </template>
  </div>
</template>

<script>
import {
  ImageFilterCollection,
  ImageFilterProject,
  ImageFilterProjectCollection,
} from 'cytomine-client';
import { getWildcardRegexp } from '@/utils/string-utils';

export default {
  name: 'ProjectImageFilters',
  data() {
    return {
      loading: true,
      error: false,

      searchString: '',
      perPage: 10,

      imageFilters: [],
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    filteredImageFilters() {
      const regexp = getWildcardRegexp(this.searchString);
      return this.imageFilters.filter((imgFilter) =>
        regexp.test(imgFilter.name)
      );
    },
  },
  async created() {
    try {
      const promiseFilters = ImageFilterCollection.fetchAll();
      const promiseFiltersProjects = ImageFilterProjectCollection.fetchAll({
        filterKey: 'project',
        filterValue: this.project.id,
      });

      const filters = (await promiseFilters).array;
      const filtersProject = (await promiseFiltersProjects).array;

      filters.forEach((filter) => {
        filter.imageFilterProject = filtersProject.find(
          (fp) => fp.imageFilter === filter.id
        );
        filter.selected = filter.imageFilterProject !== undefined;
      });

      this.imageFilters = filters;
    } catch (error) {
      console.log(error);
      this.error = true;
    }

    this.loading = false;
  },
  methods: {
    async toggleFilter(filter) {
      try {
        if (filter.selected) {
          await filter.imageFilterProject.delete();
          filter.selected = false;
        } else {
          const filterProject = await new ImageFilterProject({
            imageFilter: filter.id,
            project: this.project.id,
          }).save();
          filter.imageFilterProject = filterProject;
          filter.selected = true;
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-change-status-image-filter-notif-error', {
            filterName: filter.name,
          }),
        });
      }
    },
  },
};
</script>

<style scoped>
.project-image-filters-wrapper {
  min-height: 40vh;
}
</style>

<style>
.project-image-filters-wrapper .search-field {
  max-width: 25em;
}

.project-image-filters-wrapper .table {
  margin-top: 1.5em;
}

.project-image-filters-wrapper .table .button {
  padding: 0 3em;
}
</style>
