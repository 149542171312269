<template>
  <div v-if="!loading" class="box">
    <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>
    <template v-else>
      <div class="columns">
        <h2 class="column is-marginless">
          {{ $t('members-activity') }}
        </h2>
        <p class="column has-text-right is-size-7 has-text-grey">
          {{
            $t('data-last-updated-on', {
              time: $options.filters.date(lastUpdate, 'LTS'),
            })
          }}
        </p>
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <BInput
            v-model="searchString"
            :placeholder="$t('search')"
            type="search"
            icon="search"
          />
        </div>

        <div class="column">
          <button class="button" @click="filtersOpened = !filtersOpened">
            <span class="icon">
              <i class="fas fa-filter" />
            </span>
            <span>{{
              filtersOpened ? $t('filters-hide') : $t('filters-show')
            }}</span>
          </button>
        </div>
      </div>

      <BCollapse :open="filtersOpened">
        <div class="filters columns">
          <div class="column filter">
            <div class="filter-label">
              {{ $t('status') }}
            </div>
            <div class="filter-body">
              <CytomineMultiselect
                v-model="selectedStatus"
                :options="availableStatus"
                :searchable="false"
                multiple
              />
            </div>
          </div>

          <div class="column filter">
            <div class="filter-label">
              {{ $t('role') }}
            </div>
            <div class="filter-body">
              <CytomineMultiselect
                v-model="selectedRoles"
                :options="availableRoles"
                :searchable="false"
                multiple
              />
            </div>
          </div>
        </div>
      </BCollapse>

      <BTable
        :data="filteredMembers"
        paginated
        :per-page="perPage"
        default-sort="username"
        pagination-size="is-small"
      >
        <BTableColumn
          :label="$t('username')"
          field="username"
          sortable
          width="100"
        >
          <template #default="props">
            <Username
              :user="props.row"
              :online="onlineIds ? onlineIds.includes(props.row.id) : null"
              :display-full-name="false"
            />
          </template>
        </BTableColumn>

        <BTableColumn :label="$t('name')" field="name" sortable width="150">
          <template #default="props">
            {{ props.row.name }}
          </template>
        </BTableColumn>

        <BTableColumn
          :label="$t('role')"
          field="role"
          centered
          sortable
          width="20"
        >
          <template #default="props">
            <IconProjectMemberRole
              :is-manager="props.row.role === managerRole"
            />
          </template>
        </BTableColumn>

        <BTableColumn
          :label="$t('last-image')"
          field="lastImageName"
          sortable
          width="100"
        >
          <template #default="props">
            <RouterLink
              v-if="props.row.lastImageId"
              :to="`/project/${project.id}/image/${props.row.lastImageId}`"
            >
              {{ props.row.lastImageName }}
            </RouterLink>
            <em v-else class="has-text-grey">{{ $t('no-record') }}</em>
          </template>
        </BTableColumn>

        <BTableColumn
          :label="$t('last-connection')"
          field="lastConnection"
          sortable
          width="100"
        >
          <template #default="props">
            <template v-if="props.row.lastConnection">
              {{ Number(props.row.lastConnection) | date('ll LT') }}
            </template>
            <em v-else class="has-text-grey">{{ $t('no-record') }}</em>
          </template>
        </BTableColumn>

        <BTableColumn
          :label="$t('number-connections')"
          field="frequency"
          sortable
          width="100"
        >
          <template #default="props">
            {{ props.row.frequency }}
          </template>
        </BTableColumn>

        <BTableColumn label="" centered width="50">
          <template #default="props">
            <RouterLink
              :to="`/project/${project.id}/activity/user/${props.row.id}`"
              class="button is-small is-link"
            >
              {{ $t('button-details') }}
            </RouterLink>
          </template>
        </BTableColumn>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-member-fitting-criteria') }}</p>
          </div>
        </template>

        <template v-if="canManageProject" #footer>
          <p class="has-text-centered">
            <RouterLink
              :to="`/project/${project.id}/configuration?tab=members`"
              class="button is-link add-member"
            >
              {{ $t('button-manage-members') }}
            </RouterLink>
          </p>
        </template>

        <template #bottom-left>
          <BSelect v-model="perPage" size="is-small">
            <option value="10">
              {{ $t('count-per-page', { count: 10 }) }}
            </option>
            <option value="25">
              {{ $t('count-per-page', { count: 25 }) }}
            </option>
            <option value="50">
              {{ $t('count-per-page', { count: 50 }) }}
            </option>
            <option value="100">
              {{ $t('count-per-page', { count: 100 }) }}
            </option>
          </BSelect>
        </template>
      </BTable>

      <div class="radius-12 p-4 bg-gray-1">
        <h2 class="mb-3">
          {{ $t('legend') }}
        </h2>
        <p><IconProjectMemberRole /> : {{ $t('project-contributor') }}</p>
        <p>
          <IconProjectMemberRole is-manager /> :
          {{ $t('project-manager') }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { get } from '@/utils/store-helpers';

import constants from '@/utils/constants.js';
import { getWildcardRegexp } from '@/utils/string-utils';

import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';
import Username from '@/components/user/Username.vue';
import IconProjectMemberRole from '@/components/icons/IconProjectMemberRole.vue';

export default {
  name: 'MembersActivity',
  components: {
    IconProjectMemberRole,
    CytomineMultiselect,
    Username,
  },
  data() {
    return {
      loading: true,
      error: false,

      searchString: '',
      filtersOpened: false,
      perPage: 25,
      members: [],
      onlineIds: null,

      contributorRole: this.$t('contributor'),
      managerRole: this.$t('manager'),
      availableRoles: [],
      selectedRoles: [],

      onlineStatus: this.$t('online'),
      offlineStatus: this.$t('offline'),
      availableStatus: [],
      selectedStatus: [],

      lastUpdate: null,
      timeout: null,
    };
  },
  computed: {
    /** @returns {object} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {boolean} */
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    /** @returns {number[]} */
    idManagers() {
      return this.$store.state.currentProject.managers.map(
        (manager) => manager.id
      );
    },
    regexp() {
      return getWildcardRegexp(this.searchString);
    },
    /** @returns {object[]} */
    filteredMembers() {
      const statusKnown = Boolean(this.onlineIds); // whether or not the info regarding online users could be fetched
      const includeOnline = this.selectedStatus.includes(this.onlineStatus);
      const includeOffline = this.selectedStatus.includes(this.offlineStatus);
      return this.members.filter((member) => {
        const online = statusKnown ? this.onlineIds.includes(member.id) : null;
        return (
          (this.regexp.test(member.name) ||
            this.regexp.test(member.username)) &&
          (!statusKnown ||
            (includeOnline && online) ||
            (includeOffline && !online)) &&
          this.selectedRoles.includes(member.role)
        );
      });
    },
  },
  async created() {
    this.availableRoles = [this.contributorRole, this.managerRole];
    this.selectedRoles = this.availableRoles;

    this.availableStatus = [this.onlineStatus, this.offlineStatus];
    this.selectedStatus = this.availableStatus;
  },
  async activated() {
    await this.fetchData();
    this.loading = false;
  },
  deactivated() {
    clearTimeout(this.timeout);
  },
  methods: {
    async fetchData() {
      try {
        await Promise.all([this.fetchMembers(), this.fetchOnlines()]);
      } catch (error) {
        console.log(error);
        this.error = true;
        return;
      }

      this.lastUpdate = new Date();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        this.fetchData,
        constants.MEMBERS_ACTIVITY_REFRESH_INTERVAL
      );
    },
    async fetchMembers() {
      const members = (await this.project.fetchUsersActivity()).array;
      members.forEach((member) => {
        member.name = `${member.firstname} ${member.lastname}`;
        member.role = this.idManagers.includes(member.id)
          ? this.managerRole
          : this.contributorRole;
      });
      this.members = members;
    },
    async fetchOnlines() {
      try {
        const onlines = await this.project.fetchConnectedUsers();
        this.onlineIds = onlines.map((o) => o.id);
      } catch (error) {
        console.log(error);
        this.onlineIds = null;
      }
    },
  },
};
</script>
