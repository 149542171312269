export default {
  state() {
    return {
      selectedLayers: null,
    };
  },

  mutations: {
    addLayer(state, layer) {
      if (!state.selectedLayers) {
        state.selectedLayers = [];
      }
      state.selectedLayers.push({ ...layer });
    },

    removeLayer(state, indexLayer) {
      state.selectedLayers.splice(indexLayer, 1);
    },

    toggleLayerVisibility(state, payload) {
      const [indexLayer, value] = payload;
      const layer = state.selectedLayers[indexLayer];
      // if boolean value exists, use value. Otherwise set it to opposite of visible
      layer.visible = value ?? !layer.visible;
    },

    toggleLayerDrawOn(state, indexLayer) {
      const layer = state.selectedLayers[indexLayer];
      layer.drawOn = !layer.drawOn;
    },

    filterSelectedLayers(state, idLayers) {
      if (!state.selectedLayers) {
        return;
      }
      state.selectedLayers = state.selectedLayers.filter((layer) =>
        idLayers.includes(layer.id)
      );
    },
  },

  actions: {
    async addLayer({ commit }, layer) {
      commit('addLayer', layer);
    },

    toggleLayerVisibility({ state, commit }, [indexLayer, value]) {
      commit('toggleLayerVisibility', [indexLayer, value]);
      const layer = state.selectedLayers[indexLayer];
      if (!layer.visible) {
        commit('removeLayerFromSelectedFeatures', { layer });
      }
    },
    removeLayer({ state, commit }, indexLayer) {
      const layer = state.selectedLayers[indexLayer];
      commit('removeLayer', indexLayer);
      commit('removeLayerFromSelectedFeatures', { layer });
    },
  },

  getters: {
    selectedLayers: (state) => state.selectedLayers, // expose in getter function because properties module need access to this value
  },
};
