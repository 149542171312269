<template>
  <div class="color-manipulation">
    <h2 class="text-center">
      {{ $t('colors') }}
    </h2>
    <table>
      <tr v-if="filters && filters.length > 0" class="border-b text-right">
        <td class="py-1 px-2">
          {{ $t('filter') }}
        </td>
        <td class="w-256 py-1 px-2">
          <BSelect v-model="selectedFilter" size="is-small">
            <option :value="null">
              {{ $t('original-no-filter') }}
            </option>
            <option
              v-for="filter in filters"
              :key="filter.id"
              :value="filter.prefix"
            >
              {{ filter.name }}
            </option>
          </BSelect>
        </td>
      </tr>
      <tr class="text-right">
        <td class="py-1 px-2">
          {{ $t('brightness') }}
        </td>
        <td class="w-256 py-1 px-2">
          <CytomineSlider v-model="brightness" :min="-255" :max="255" />
        </td>
      </tr>
      <tr class="text-right">
        <td class="py-1 px-2">
          {{ $t('contrast') }}
        </td>
        <td class="w-256 py-1 px-2">
          <CytomineSlider v-model="contrast" :min="-255" :max="255" />
        </td>
      </tr>
      <tr class="text-right">
        <td class="py-1 px-2">
          {{ $t('saturation') }}
        </td>
        <td class="w-256 py-1 px-2">
          <CytomineSlider v-model="saturation" :min="-100" :max="100" />
        </td>
      </tr>
      <tr class="text-right">
        <td class="py-1 px-2">
          {{ $t('hue') }}
        </td>
        <td class="w-256 py-1 px-2">
          <CytomineSlider v-model="hue" :min="-180" :max="180" />
        </td>
      </tr>
    </table>

    <div class="mt-4 text-right">
      <button class="button is-small" @click="reset">
        {{ $t('button-reset') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ImageFilterProjectCollection } from 'cytomine-client';
import CytomineSlider from '@/components/form/CytomineSlider';

export default {
  name: 'ColorManipulation',
  components: { CytomineSlider },
  props: {
    index: String,
  },
  data() {
    return {
      filters: null,
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },

    selectedFilter: {
      get() {
        return this.imageWrapper.colors.filter;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setFilter', value);
      },
    },

    brightness: {
      get() {
        return this.imageWrapper.colors.brightness;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setBrightness', value);
      },
    },
    contrast: {
      get() {
        return this.imageWrapper.colors.contrast;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setContrast', value);
      },
    },
    hue: {
      get() {
        return this.imageWrapper.colors.hue;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setHue', value);
      },
    },
    saturation: {
      get() {
        return this.imageWrapper.colors.saturation;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setSaturation', value);
      },
    },
  },
  async created() {
    try {
      const filters = (
        await ImageFilterProjectCollection.fetchAll({
          filterKey: 'project',
          filterValue: this.project.id,
        })
      ).array;
      filters.forEach(
        (filter) => (filter.prefix = filter.processingServer + filter.baseUrl)
      );
      const prefixes = filters.map((filter) => filter.prefix);
      if (this.selectedFilter && !prefixes.includes(this.selectedFilter)) {
        this.selectedFilter = null; // if selected filter no longer present in collection, unselect it
      }
      this.filters = filters;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    reset() {
      this.$store.commit(this.imageModule + 'resetColorManipulation');
    },
  },
};
</script>

<style scoped>
td,
tr {
  vertical-align: middle !important;
}

>>> .vue-slider {
  margin-left: 0.4em;
  margin-right: 4em;
}
</style>
