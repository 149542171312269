<template>
  <span
    :style="
      'display:inline-block;width:' +
        size +
        'px;height:' +
        size +
        'px;border-radius:' +
        size +
        'px;background-color:rgb(' +
        color.r +
        ',' +
        color.g +
        ',' +
        color.b +
        ')'
    "
  />
</template>

<script>
export default {
  name: 'ColorCircle',
  props: {
    color: Object,
    size: Number,
  },
};
</script>
